import { createAnnouncementsApi } from '../api/announcements.api';
import { auth } from '@core/utils/firebase/firebase';
import { useQuery } from '@tanstack/react-query';
import { Auth } from '@angular/fire/auth';

const announcementsApi = createAnnouncementsApi(auth as Auth);

export function useAnnouncementByIdQuery(id: number) {
  const { data, isLoading, error } = useQuery({
    queryKey: ['announcement', id],
    queryFn: () => announcementsApi.getAnnouncementById(id),
    enabled: !!id,
  });

  return {
    announcement: data,
    isLoading,
    error,
  };
}

export function useAnnouncementsQuery({
  siteId,
  page,
  rowsPerPage,
  sortField,
  sortOrder,
  search,
  status,
}: {
  siteId: number;
  page: number;
  rowsPerPage: number;
  sortField: string;
  sortOrder: 'asc' | 'desc';
  search?: string;
  status?: 'all' | 'active' | 'past' | 'future';
}) {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [
      'announcements',
      siteId,
      page,
      rowsPerPage,
      sortField,
      sortOrder,
      search,
      status,
    ],
    queryFn: () =>
      announcementsApi.getAnnouncements(siteId, {
        page,
        pageSize: rowsPerPage,
        sortField,
        sortOrder,
        search,
        status,
      }),
    enabled: !!siteId,
  });

  return {
    data,
    isLoading,
    error,
    refetch,
  };
}
