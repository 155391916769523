import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const defaultTheme = {
  palette: {
    secondary: {
      main: '#f0f0f0', // $goodman-grey[500]
      light: '#fafafa', // $goodman-grey[100]
      dark: '#d4d4d4', // $goodman-grey[700]
      contrastText: '#585858',
    },
    primary: {
      main: '#0a0a0a', // $goodman-black[500]
      light: '#585858', // $goodman-black[50]
      dark: '#3d3d3d', // $goodman-black[700]
      contrastText: '#ffffff',
    },
    error: {
      main: '#d66853', // From variables.css danger color
    },
    background: {
      default: '#fafafa',
      paper: '#ffffff',
    },
    text: {
      primary: '#0a0a0a',
      secondary: '#585858',
    },
  },
};
const theme = createTheme({
  ...defaultTheme,
  typography: {
    fontFamily: 'Gotham Book, Arial, sans-serif',
    h1: {
      fontFamily: 'Gotham Black, serif',
      textTransform: 'uppercase',
      fontSize: '5.6vmin',
      lineHeight: '6.5vmin',
    },
    h2: {
      fontFamily: 'Libre Baskerville, serif',
      fontSize: '3.4vmin',
      lineHeight: '4.5vmin',
    },
    button: {
      fontFamily: 'Gotham Book, Arial, sans-serif',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#f0f0f0',
          color: '#0a0a0a',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '16px',
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        disableEscapeKeyDown: true,
        disablePortal: true,
      },
      styleOverrides: {
        paper: {
          backgroundColor: '#f0f0f0',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '8px',
          maxWidth: '300px',
          minWidth: '0px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          color: defaultTheme.palette.primary.light,
          padding: '16px',
          '&.MuiTableCell-body': {
            backgroundColor: defaultTheme.palette.secondary.main,
          },
        },
        head: {
          backgroundColor: defaultTheme.palette.secondary.main,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          color: defaultTheme.palette.primary.light,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: '3px solid',
          backgroundColor: defaultTheme.palette.secondary.main,
          '&:hover': {
            '& .MuiTableCell-body': {
              backgroundColor: defaultTheme.palette.secondary.main,
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#0a0a0a', // Ensure form labels are visible
          '&.Mui-focused': {
            color: '#0a0a0a',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: '#0a0a0a', // Ensure radio button labels are visible
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: defaultTheme.palette.secondary.main,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: defaultTheme.palette.primary.light,
          '&.Mui-selected': {
            backgroundColor: defaultTheme.palette.primary.main,
            color: defaultTheme.palette.primary.contrastText,
            '&:hover': {
              backgroundColor: defaultTheme.palette.primary.dark,
            },
          },
          '&:hover': {
            backgroundColor: defaultTheme.palette.secondary.dark,
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: defaultTheme.palette.primary.light,
        },
        selectLabel: {
          color: defaultTheme.palette.primary.light,
        },
        displayedRows: {
          color: defaultTheme.palette.primary.light,
        },
        select: {
          color: defaultTheme.palette.primary.light,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: defaultTheme.palette.secondary.main,
        },
        list: {
          color: defaultTheme.palette.primary.light,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          color: defaultTheme.palette.primary.light,
          '&:hover': {
            backgroundColor: defaultTheme.palette.secondary.dark,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: defaultTheme.palette.secondary.main,
        },
        option: {
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: defaultTheme.palette.secondary.dark,
          },
          '&[aria-selected="true"]': {
            backgroundColor: 'white',
          },
        },
        groupLabel: {
          fontSize: '16px',
        },
      },
    },
  },
});

const queryClient = new QueryClient();

export function ReactProvider({ children }: { children: React.ReactNode }) {
  // add other providers here as needed
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <QueryClientProvider client={queryClient}>
          {children}
        </QueryClientProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
