import clsx from 'clsx';
import React from 'react';
import { CommonSlideProps } from '../slide-preview';

interface GifCentricSlideProps extends CommonSlideProps {
  image: string;
}

export function GifCentricSlide({
  title,
  content,
  image,
  preview = false,
}: GifCentricSlideProps) {
  return (
    <div className="absolute inset-0">
      <div className="w-full h-full flex items-center justify-center flex-col">
        <div
          className={clsx(
            'bg-black/60 m-0 absolute top-0 left-0 w-[25%]',
            preview ? 'p-[1.5vmin]' : 'p-[3vmin]',
          )}
        >
          <h1
            className={clsx(
              'font-black text-white leading-tight uppercase',
              preview ? 'text-[2vmin]' : 'text-[5.6vmin]',
            )}
          >
            {title}
          </h1>
          <p
            className={clsx(
              'text-white font-serif',
              preview ? 'text-[1.5vmin]' : 'text-[3.9vmin]',
            )}
          >
            {content}
          </p>
        </div>
        {image && <img src={image} />}
      </div>
    </div>
  );
}
