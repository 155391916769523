import { Component, OnInit, Inject } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { generateCsv, download, mkConfig } from 'export-to-csv';
import { BehaviorSubject } from 'rxjs';
import { ActivityService } from 'src/app/core/services/activity.service';
import { AlertService } from 'src/app/core/services/alert.service';

import { PdfService } from 'src/app/core/services/pdf.service';
import { EventType } from '../../../../../../core/enums/event-type';
import { Site } from '@core/interfaces/api';
import { endOfDay, startOfDay } from 'date-fns';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

/**
 * Modal component to download a property's events in a given range
 * as either a PDF or CSV file.
 */

@Component({
  selector: 'app-event-download-modal',
  templateUrl: './event-download-modal.component.html',
  styleUrls: ['./event-download-modal.component.scss'],
})
export class EventDownloadModalComponent implements OnInit {
  public form: UntypedFormGroup;
  public csvLoading = new BehaviorSubject(false);
  public pdfLoading = new BehaviorSubject(false);

  constructor(
    private pdfService: PdfService,
    private activityService: ActivityService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<EventDownloadModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      selectedMonthIndex: number;
      selectedYear: number;
      timezone: string;
      site: Site;
    },
  ) {
    this.downloadCSV = this.downloadCSV.bind(this);
    this.downloadPdf = this.downloadPdf.bind(this);
  }

  ngOnInit() {
    if (this.data) {
      // initialize date range as active month/year, timezone irrelevant
      const startOfMonth = new Date(
        this.data.selectedYear,
        this.data.selectedMonthIndex,
        1,
      );
      const endOfMonth = new Date(
        this.data.selectedYear,
        this.data.selectedMonthIndex + 1,
        0,
      );

      this.form = new UntypedFormGroup({
        startDate: new UntypedFormControl(startOfMonth, Validators.required),
        endDate: new UntypedFormControl(endOfMonth, Validators.required),
      });
    } else {
      this.form = new UntypedFormGroup({
        startDate: new UntypedFormControl(null, Validators.required),
        endDate: new UntypedFormControl(null, Validators.required),
      });
    }
  }

  public async downloadPdf(): Promise<void> {
    const startDay = this.form.get('startDate').value;
    const endDay = this.form.get('endDate').value;

    const startDate = startOfDay(startDay);
    const endDate = endOfDay(endDay);

    this.pdfLoading.next(true);
    try {
      const dataResponse = await this.activityService.getTimeRangeEvents(
        this.data.site.id,
        zonedTimeToUtc(startDate, this.data.timezone),
        zonedTimeToUtc(endDate, this.data.timezone),
      );

      await this.pdfService.downloadPdfEventList(
        dataResponse,
        startDate,
        endDate,
        this.data.site,
      );

      this.pdfLoading.next(false);
      this.dialogRef.close();
    } catch (error) {
      this.pdfLoading.next(false);
      this.alertService.error('There was a problem downloading the PDF.');
    }
  }

  public downloadCSV(): void {
    const startDay = this.form.get('startDate').value;
    const endDay = this.form.get('endDate').value;
    const startDate = startOfDay(startDay);
    const endDate = endOfDay(endDay);

    this.csvLoading.next(true);
    this.activityService
      .getTimeRangeEvents(
        this.data.site.id,
        zonedTimeToUtc(startDate, this.data.timezone),
        zonedTimeToUtc(endDate, this.data.timezone),
      )
      .then((dataResponse) => {
        if (dataResponse.length === 0) {
          this.alertService.error('No events for the selected timeframe.');
          this.csvLoading.next(false);
          this.dialogRef.close();
          return;
        }
        const startString = format(startDate, 'MMM_d_yyyy');
        const endString = format(endDate, 'MMM_d_yyyy');
        const options = mkConfig({
          filename: `GiGi_Event_Export_${startString.replace(
            /\s/g,
            '',
          )}-${endString.replace(/\s/g, '')}.csv`,
          useBom: true,
          columnHeaders: [
            { key: 'date', displayLabel: 'Date' },
            { key: 'event', displayLabel: 'Event' },
            { key: 'location', displayLabel: 'Location' },
            { key: 'startTime', displayLabel: 'StartTime' },
            { key: 'endTime', displayLabel: 'EndTime' },
            { key: 'keywords', displayLabel: 'Keywords' },
            { key: 'type', displayLabel: 'Type' },
            { key: 'category', displayLabel: 'Category' },
            { key: 'subcategory', displayLabel: 'Subcategory' },
          ],
        });

        const dataList = [];

        for (const data of dataResponse) {
          const eventStartInZone = zonedTimeToUtc(
            new Date(data.eventStart),
            this.data.timezone,
          );
          const eventEndInZone = zonedTimeToUtc(
            new Date(data.eventEnd),
            this.data.timezone,
          );

          const date = format(eventStartInZone, 'M/d/yyyy');
          const startTime = format(eventStartInZone, 'h:mm a');
          const endTime = format(eventEndInZone, 'h:mm a');
          let location = '';

          if (data.room?.name) {
            location = data.room.name;
          }

          if (data.room?.shortName) {
            location = location
              ? `${location}, ${data.room.shortName}`
              : data.room.shortName;
          }

          if (data.location) {
            location = location
              ? `${location}, ${data.location}`
              : data.location;
          }
          const formattedData = {
            date,
            event: data.title,
            location: location,
            startTime,
            endTime,
            keywords: `${data.content ? `${data.content}` : ''}`,
            type: EventType[data.eventType],
            category: data.category?.name,
            subcategory: data.subcategory?.name,
          };

          dataList.push(formattedData);
        }
        const csv = generateCsv(options)(dataList);
        download(options)(csv);

        this.csvLoading.next(false);
        this.dialogRef.close();
      })
      .catch((error) => {
        console.error('error downloading the CSV', error);
        this.csvLoading.next(false);
        this.alertService.error('There was a problem downloading the CSV.');
      });
  }
}
