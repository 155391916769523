export function getMaxTitleChars(styleId: number): number {
  switch (styleId) {
    case 7:
      // Name in Lights
      return 100;
    case 12:
      // Image-Centric
      return 28;
    case 13:
      // Collage
      return 50;
    default:
      return 60;
  }
}

export function getMaxDescriptionChars(styleId: number): number {
  switch (styleId) {
    case 3:
      // Event
      return 200;
    case 7:
      // Name in Lights
      return 500;
    case 12:
      // Image-Centric
      return 50;
    case 13:
      // Collage
      return 200;
    default:
      return 250;
  }
}
