import clsx from 'clsx';
import React from 'react';
import { CommonSlideProps } from '../slide-preview';
import { renderBullets } from '../../../utils/content-formatting';
interface IndividualSpotlightSlideProps extends CommonSlideProps {
  imageUrl: string;
}

export function IndividualSpotlightSlide({
  title,
  content,
  imageUrl,
  backgroundImage,
  preview = false,
}: IndividualSpotlightSlideProps) {
  return (
    <div
      className="absolute inset-0"
      style={
        backgroundImage
          ? {
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }
          : undefined
      }
    >
      <div
        className={clsx(
          'bg-black/60 absolute top-0 left-0',
          preview ? 'w-1/2 h-full p-[3%]' : 'w-1/2 h-full p-[3.5%]',
        )}
      >
        <div className="w-100">
          {imageUrl && (
            <img
              className={clsx(
                'rounded-full',
                preview
                  ? 'h-[10vmin] w-[10vmin] mb-[2.5vmin]'
                  : 'h-[30vmin] w-[30vmin] mb-[5vmin]',
              )}
              src={imageUrl}
            />
          )}
          <h1
            className={clsx(
              'font-black font-sans text-white text-left mb-[1vmin] leading-tight uppercase',
              preview ? 'text-[3vmin]' : 'text-[5.6vmin]',
            )}
          >
            {title}
          </h1>
          <ul
            className={clsx(
              'font-book font-serif text-white list-disc ml-8',
              preview ? 'text-[2vmin]' : 'text-[3.8vmin]',
            )}
          >
            {renderBullets(content)}
          </ul>
        </div>
      </div>
    </div>
  );
}
