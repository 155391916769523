import React, { useState, useEffect } from 'react';
import { CircularProgress, Pagination } from '@mui/material';
import { useBackgroundImages } from '@features/background-images/queries/use-background-images.queries';

interface Image {
  id: number;
  downloadUrl: string;
}

interface BackgroundImageGridProps {
  onImageSelect: (image: Image) => void;
  siteId: number;
  selectedImageId?: number;
}

export function BackgroundImageGrid({
  onImageSelect,
  siteId,
  selectedImageId,
}: BackgroundImageGridProps) {
  const [images, setImages] = useState<Image[]>([]);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 25;

  const { data, isLoading } = useBackgroundImages({
    siteId,
    page,
    pageSize,
  });

  useEffect(() => {
    if (data) {
      setImages(data.data);
      setTotalPages(Math.ceil(data.totalCount / pageSize));

      // If we have a selectedImageId, find and select that image
      if (selectedImageId) {
        const selectedImg = data.data.find((img) => img.id === selectedImageId);
        if (selectedImg) {
          setSelectedImage(selectedImg);
          // Also trigger the onImageSelect callback to ensure the form is updated
          onImageSelect(selectedImg);
        }
      }
    }
  }, [data, selectedImageId, onImageSelect]);

  const handleImageSelect = (image: Image) => {
    setSelectedImage(image);
    onImageSelect(image);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center p-4">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <div
        className="flex flex-wrap h-[180px] overflow-y-scroll"
        style={{ scrollbarWidth: 'thin' }}
      >
        {images.map((image) => (
          <div
            key={image.id}
            className={`
              bg-black 
              inline-block 
              rounded 
              h-[120px] 
              w-[160px] 
              m-2 
              overflow-hidden 
              cursor-pointer
              ${selectedImage?.id === image.id ? 'ring-2 ring-black' : ''}
            `}
            onClick={() => handleImageSelect(image)}
          >
            <img
              src={image.downloadUrl}
              alt="Background option"
              className="w-full h-auto rounded object-cover"
              loading="lazy"
            />
          </div>
        ))}
      </div>

      <div className="flex justify-center mt-4">
        <Pagination
          count={totalPages}
          page={page}
          onChange={(_, value) => setPage(value)}
          color="primary"
          showFirstButton
          showLastButton
        />
      </div>
    </div>
  );
}
