import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField, IconButton, FormLabel, Tooltip } from '@mui/material';
import { ImageUploadArea } from '@features/image-upload/components/image-upload-area';
import { useImageUpload } from '@features/image-upload/queries/use-image-upload.mutations';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpIcon from '@mui/icons-material/Help';

interface IndividualSpotlightDescriptionProps {
  siteId: number;
}

export function IndividualSpotlightDescription({
  siteId,
}: IndividualSpotlightDescriptionProps) {
  const { control, setValue, watch } = useFormContext();
  const { mutateAsync: uploadImage, isPending: isUploading } =
    useImageUpload(siteId);
  const facts = watch('description.facts') || [];
  const imageUrl = watch('description.image');

  const handleImageUpload = async (image: File) => {
    const imageUrl = await uploadImage(image);
    setValue('description.image', imageUrl);
  };

  const handleDeleteImage = () => {
    setValue('description.image', '');
  };

  const addFact = () => {
    setValue('description.facts', [...facts, '']);
  };

  const removeFact = (index: number) => {
    const newFacts = [...facts];
    newFacts.splice(index, 1);
    setValue('description.facts', newFacts);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-start gap-2">
        <FormLabel
          component="legend"
          sx={{ color: 'text.primary', fontSize: '24px' }}
        >
          Name
        </FormLabel>
        <Tooltip title="Who is this spotlight for? Limit 50 characters.">
          <HelpIcon sx={{ fontSize: '16px', color: 'text.secondary' }} />
        </Tooltip>
      </div>

      <Controller
        name="description.title"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            placeholder="Enter their name"
          />
        )}
      />

      <div className="flex items-start gap-2">
        <FormLabel
          component="legend"
          sx={{ color: 'text.primary', fontSize: '24px' }}
        >
          Personal Facts
        </FormLabel>
        <Tooltip title="What are some interesting facts about this person? Limit 30 characters per fact.">
          <HelpIcon sx={{ fontSize: '16px', color: 'text.secondary' }} />
        </Tooltip>
      </div>

      {facts.map((_, index) => (
        <div key={index} className="flex gap-2 items-start">
          <Controller
            name={`description.facts.${index}`}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                placeholder={`Fact #${index + 1}`}
                multiline
                fullWidth
              />
            )}
          />
          <IconButton
            onClick={() => removeFact(index)}
            size="small"
            className="mt-2"
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ))}

      {facts.length < 5 && (
        <IconButton onClick={addFact} size="small" className="self-start">
          <AddIcon /> Add Fact
        </IconButton>
      )}

      <div className="flex items-start gap-2 justify-between">
        <div className="flex items-start gap-2">
          <FormLabel
            component="legend"
            sx={{ color: 'text.primary', fontSize: '24px' }}
          >
            Square Image
          </FormLabel>
          <Tooltip title="Add a photo of this person. It will be converted to a circular image; uploading square photos produces the best results.">
            <HelpIcon sx={{ fontSize: '16px', color: 'text.secondary' }} />
          </Tooltip>
        </div>
        {imageUrl && (
          <button
            onClick={handleDeleteImage}
            className="text-red-600 hover:text-red-700 font-medium"
          >
            Delete
          </button>
        )}
      </div>

      {imageUrl ? (
        <div className="flex flex-col gap-2">
          <img
            src={imageUrl.downloadUrl}
            alt="Uploaded image"
            className="w-48 h-48 object-cover rounded-lg"
          />
        </div>
      ) : (
        <ImageUploadArea
          onUpload={handleImageUpload}
          isUploading={isUploading}
          progress={0}
        />
      )}
    </div>
  );
}
