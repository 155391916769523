import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

// import { MbscEventcalendarOptions } from '@mobiscroll/angular';
import { ActivityService } from 'src/app/core/services/activity.service';
import { EventType } from '../../../../../../../../core/enums/event-type';
import { takeUntil } from 'rxjs/operators';
import { RRuleSet } from 'rrule';
import { UtilityService } from 'src/app/core/services/utility.service';
import { Recurrence } from '@models/recurrence';
import { SiteService } from 'src/app/core/services/site.service';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-event-preview-calendar',
  templateUrl: './event-preview-calendar.component.html',
  styleUrls: ['./event-preview-calendar.component.scss'],
})
export class EventPreviewCalendarComponent implements OnInit, OnDestroy {
  destroyed$: Subject<boolean> = new Subject();
  public eventModel: BehaviorSubject<{
    start: string;
    end: string;
    date: string;
    title: string;
    allDay: boolean;
    color: string;
    recurring?: RRuleSet;
    recurringException?: Array<string>;
    recurrenceRecipe?: object;
  }> = new BehaviorSubject(null);
  public events: BehaviorSubject<
    Array<{
      start: string;
      end: string;
      date: string;
      title: string;
      allDay: boolean;
      color: string;
      recurring?: RRuleSet;
      recurringException?: Array<string>;
      recurrenceRecipe?: object;
    }>
  > = new BehaviorSubject([]);
  @Output() changeMonth = new EventEmitter();
  @Output() changeSelectedDay = new EventEmitter();
  @Input() months: Array<Date> = [];
  _eventDetails;
  @Input() get eventDetails() {
    return this._eventDetails;
  }
  @Input() activeDay;
  @Input() activeMonthIndex;

  @Input() set eventPreview(value) {
    this.eventModel.next(value);
  }

  public lastRecurrenceMessage = new BehaviorSubject('');

  @Input() timezone;
  eventTypes = EventType;
  // options: MbscEventcalendarOptions = {
  //   view: {
  //     calendar: { type: 'month', labels: true },
  //   },
  //   theme: 'goodman',
  //   dateFormatLong: 'DDDD, MMM D, YYYY',
  //   noEventsText: 'No scheduled events',
  // };
  isManager = false;
  monthIndex = 12;
  constructor(
    public activityService: ActivityService,
    public utilityService: UtilityService,
    public siteService: SiteService,
  ) {
    this.events.next([
      {
        date: new Date().toISOString(),
        start: new Date().toISOString(),
        end: new Date().toISOString(),
        title: this._eventDetails?.title || '(unnamed event)',
        allDay: this._eventDetails?.allDay,
        color: this._eventDetails?.color,
      },
    ]);
  }

  ngOnInit(): void {
    this.eventModel.pipe(takeUntil(this.destroyed$)).subscribe((event) => {
      if (!event.recurrenceRecipe) {
        this.events.next([event]);
        this.lastRecurrenceMessage.next('');
        return;
      }

      const recurring = new Recurrence({
        ...event.recurrenceRecipe,
        tzid: this.siteService.currentSite.timezone,
      });

      const eventDates = recurring.all;
      if (eventDates.length === 0) {
        return;
      }

      const events = [];

      recurring.stringFormattedDates.forEach((date) => {
        const jsDate = parseISO(date);
        const newEvent = {
          start: jsDate,
          end: jsDate,
          allDay: false,
          color: event.color,
          title: event.title,
        };

        events.push(newEvent);
      });
      this.events.next(events);
      this.lastRecurrenceMessage.next(recurring.stringLastTimezoneRecurrence);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  public selectMonth(monthChange): void {
    if (this.activeMonthIndex === 0 && monthChange < 0) {
      return;
    }
    if (this.activeMonthIndex === 24 && monthChange > 0) {
      return;
    }
    this.changeMonth.emit(monthChange);
  }
}
