import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormLabel,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';
import { useImageUpload } from '@features/image-upload/queries/use-image-upload.mutations';
import { ImageUploadArea } from '@features/image-upload/components/image-upload-area';
import { Image } from '@core/interfaces/api';

interface CollageDescriptionProps {
  siteId: number;
}

export function CollageDescription({ siteId }: CollageDescriptionProps) {
  const { watch, setValue } = useFormContext();
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const imageUpload = useImageUpload(Number(siteId));

  const numberOfImages = watch('description.numberOfImages');
  const numberOfImagesOptions = [1, 2, 3, 4];

  const handleNumberOfImagesChange = (event) => {
    const newValue = event.target.value;
    setValue('description.numberOfImages', newValue);

    // Clear any images beyond the new number
    const currentImages = watch('description.secondaryImages') || [];
    if (currentImages.length > newValue) {
      setValue('description.secondaryImages', currentImages.slice(0, newValue));
    }
  };

  const handleImageUpload = async (file: File) => {
    try {
      const currentImages = watch('description.secondaryImages') || [];
      if (currentImages.length >= numberOfImages) {
        // Could add a notification here about max images
        return;
      }

      const uploadedImage = await imageUpload.mutateAsync(file);
      const position = currentImages.length + 1;

      setValue('description.secondaryImages', [
        ...currentImages,
        { ...uploadedImage, position },
      ]);
    } catch (error) {
      console.error('Failed to upload image:', error);
    }
  };

  const handleImageRemove = (indexToRemove: number) => {
    const images = watch('description.secondaryImages');
    const updatedImages = images
      .filter((_, index) => index !== indexToRemove)
      .map((image: Image, index) => ({
        ...image,
        position: index + 1,
      }));

    setValue('description.secondaryImages', updatedImages);
  };

  return (
    <>
      <FormControl fullWidth>
        <div className="flex items-start gap-2 mb-2">
          <FormLabel
            component="legend"
            sx={{ mb: 2, color: 'text.primary', fontSize: '24px' }}
          >
            Number of Images
          </FormLabel>
          <Tooltip title="You can add 1 to 4 images">
            <HelpIcon sx={{ fontSize: '16px', color: 'text.secondary' }} />
          </Tooltip>
        </div>
        <Select value={numberOfImages} onChange={handleNumberOfImagesChange}>
          {numberOfImagesOptions.map((number) => (
            <MenuItem key={number} value={number}>
              {number}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <div className="flex items-start gap-2 mb-2">
          <FormLabel
            component="legend"
            sx={{ mb: 2, color: 'text.primary', fontSize: '24px' }}
          >
            Images
          </FormLabel>
          <Tooltip title="Upload 1-4 images for your collage">
            <HelpIcon sx={{ fontSize: '16px', color: 'text.secondary' }} />
          </Tooltip>
        </div>

        {(watch('description.secondaryImages')?.length || 0) <
          numberOfImages && (
          <ImageUploadArea
            onUpload={handleImageUpload}
            isUploading={imageUpload.isPending}
            progress={uploadProgress}
            maxFiles={numberOfImages}
            currentFiles={watch('description.secondaryImages')?.length || 0}
          />
        )}

        <div className="grid grid-cols-2 gap-4 mt-4">
          {watch('description.secondaryImages')?.map((image, index) => (
            <div key={image.id} className="relative">
              <img
                src={image.downloadUrl}
                alt=""
                className="w-full h-40 object-cover rounded"
              />
              <IconButton
                className="absolute top-2 right-2"
                size="small"
                onClick={() => handleImageRemove(index)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          ))}
        </div>
      </FormControl>
    </>
  );
}
