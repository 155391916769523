import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ImageUploadArea } from '@features/image-upload/components/image-upload-area';
import { useImageUpload } from '@features/image-upload/queries/use-image-upload.mutations';

interface GifDescriptionProps {
  siteId: number;
}

export function GifDescription({ siteId }: GifDescriptionProps) {
  const { control, setValue } = useFormContext();
  const imageUpload = useImageUpload(Number(siteId));

  const handleImageUpload = async (image: File) => {
    const imageUrl = await imageUpload.mutateAsync(image);
    setValue('description.image', imageUrl);
  };

  return (
    <div className="flex flex-col gap-4">
      <ImageUploadArea
        onUpload={handleImageUpload}
        isUploading={imageUpload.isPending}
        progress={0}
      />
    </div>
  );
}
