import { Component, OnInit, Inject } from '@angular/core';
import { AlertService } from 'src/app/core/services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Image } from '@models/image';
import { ImageFormComponent } from 'src/app/shared/forms/image-form/image-form.component';
import { ImageService, ImageUpdate } from 'src/app/core/services/image.service';
import {
  SubcategoryService,
  SubcategoryUpdate,
} from 'src/app/core/services/subcategory.service';
import isEqual from 'lodash/isEqual';

@Component({
  selector: 'app-edit-image',
  templateUrl: './edit-image.component.html',
  styleUrls: ['./edit-image.component.scss'],
})
export class EditImageComponent implements OnInit {
  public imageForm = ImageFormComponent.formModel();
  public submitting = false;

  public uploading = false;
  public data: { image?: any; editMode: boolean } = { editMode: true };

  private initialValue = {
    data: {},
    formValue: {
      image: '',
      category: null,
      siteIds: null,
      name: '',
    },
  };

  constructor(
    private alertService: AlertService,
    private imageService: ImageService,
    private subcategoryService: SubcategoryService,
    private dialogRef: MatDialogRef<EditImageComponent>,
    @Inject(MAT_DIALOG_DATA) public image: Image,
  ) {}

  ngOnInit() {
    this.data.image = this.image.downloadUrl;
    this.initialValue = ImageFormComponent.deserialize(this.image);
    // If no sites provided, initialize edit view with '0' siteId so the UI can select 'All Sites'
    let initialData = {
      ...this.initialValue.formValue,
      siteIds: !this.initialValue.formValue?.siteIds
        ? 0
        : this.initialValue.formValue?.siteIds,
    };
    this.imageForm.setValue(initialData);
  }

  public async onSave(): Promise<void> {
    const initialData = this.initialValue.formValue;
    const newFormData = this.imageForm.value;
    if (!isEqual(initialData, newFormData)) {
      const subcategoryUpdate: Partial<SubcategoryUpdate> = {};
      this.submitting = true;
      let siteUpdate;
      let addNewSubcategory = false;

      if (initialData.name !== newFormData.name) {
        if (!initialData.name) {
          // creating new subcategory before update
          addNewSubcategory = true;
        }
        // update subcategory
        subcategoryUpdate.name = newFormData.name;
      }

      if (initialData.category !== newFormData.category) {
        // update subcategory with new parent (cateogry)
        subcategoryUpdate.parent = newFormData.category;
      }

      if (initialData.siteIds !== newFormData.siteIds) {
        // update site
        siteUpdate = newFormData.siteIds;
      }

      // EITHER we create or update a new category
      this.handleSubcategoryChange(addNewSubcategory, subcategoryUpdate)
        .then((response) => {
          let newSubcategory = null;
          /* we only need to include subcategory if it's new. We don't allow for changing the id
        of the associated subcategory */
          if (addNewSubcategory && response) {
            newSubcategory = response.id;
          }
          // update image with new category, subcategory and/or site ids
          return this.updateImage(
            subcategoryUpdate.parent,
            newSubcategory,
            siteUpdate,
          );
        })
        .then(async () => {
          await this.imageService.refreshAllBackgrounds();
          this.alertService.success('Image updated.');
          this.dialogRef.close({ success: true });
        })
        .catch((error) => {
          console.error(error);
          this.alertService.error('Error updating image. Please try again.');
        })
        .finally(() => {
          this.submitting = false;
        });
    } else {
      // no change
      this.alertService.error('No new changes to save!');
    }
  }

  private handleSubcategoryChange(
    createNew: boolean,
    update: Partial<SubcategoryUpdate>,
  ): Promise<any> {
    if (createNew) {
      return this.createNewSubcategory();
    } else if (Object.keys(update).length === 0 || update.name === '') {
      // if no subcategory update, or subcategory associate will be removed
      return Promise.resolve();
    } else {
      return this.updateSubcategory(this.image.subcategory.id, update);
    }
  }

  private createNewSubcategory() {
    return this.subcategoryService.saveSubcategory({
      name: this.imageForm.value.name,
      parent: this.imageForm.value.category,
    });
  }

  private updateSubcategory(
    id: number,
    update: Partial<SubcategoryUpdate>,
  ): Promise<String> {
    return this.subcategoryService.updateSubcategory(id, update);
  }

  private updateImage(
    categoryUpdate: number,
    subcategoryUpdate: number,
    siteUpdates: number,
  ): Promise<Image> {
    const imageUpdates: Partial<ImageUpdate> = {};

    if (categoryUpdate || this.imageForm.value.category === '') {
      if (this.imageForm.value.category === '') {
        imageUpdates.category = null; // remove categroy association
      } else {
        imageUpdates.category = this.imageForm.value.category; // id
      }
    }

    if (subcategoryUpdate || this.imageForm.value.name === '') {
      if (this.imageForm.value.name === '') {
        imageUpdates.subcategory = null; // remove subcategory association
      } else {
        imageUpdates.subcategory = subcategoryUpdate; // id
      }
    }

    if (siteUpdates || siteUpdates === null) {
      imageUpdates.site = siteUpdates;
    }

    if (Object.keys(imageUpdates).length === 0) {
      // if no image-table-relevant changes are made, skip image update
      return;
    }

    return this.imageService.updateBackgroundImage(this.image.id, imageUpdates);
  }

  public onCancel(): void {
    this.dialogRef.close();
  }
}
