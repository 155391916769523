import clsx from 'clsx';
import React from 'react';
import { CommonSlideProps } from '../slide-preview';

interface NameInLightsSlideProps extends CommonSlideProps {
  title: string;
  content: string;
  preview?: boolean;
}

export function NameInLightsSlide({
  title,
  content,
  backgroundImage,
  preview = false,
}: NameInLightsSlideProps) {
  return (
    <div
      className="absolute inset-0 bg-black"
      style={
        backgroundImage
          ? {
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }
          : undefined
      }
    >
      <div
        className={clsx(
          'bg-black/60 w-100 h-3/4 m-0 absolute left-0 antialiased justify-center',
          preview ? 'p-[12vmin] top-[6vh]' : 'p-[24vmin] top-[12vh]',
        )}
      >
        <h1
          className={clsx(
            'font-black font-sans text-white text-center mb-[1vmin] leading-tight uppercase',
            preview ? 'text-[2.8vmin]' : 'text-[5.6vmin]',
          )}
        >
          {title}
        </h1>
        <h2
          className={clsx(
            'text-white text-center font-serif leading-tight',
            preview ? 'text-[1.95vmin]' : 'text-[3.9vmin]',
          )}
        >
          {content}
        </h2>
      </div>
    </div>
  );
}
