import { formatInTimeZone } from 'date-fns-tz';
import clsx from 'clsx';
import React from 'react';

interface ActivitiesAndEventsSlideProps {
  title: string;
  content: string | null;
  allDay: boolean;
  eventStart: string | null;
  eventEnd: string | null;
  roomName: string | null;
  location: string | null;
  iconName: string | null;
  timezone: string;
  backgroundImage?: string;
  preview?: boolean;
}

export function ActivitiesAndEventsSlide({
  title,
  content,
  allDay,
  eventStart,
  eventEnd,
  roomName,
  location,
  iconName,
  timezone,
  backgroundImage,
  preview = false,
}: ActivitiesAndEventsSlideProps) {
  const iconUrl = `/assets/event-icons/${iconName}-white.svg`;
  const date = eventStart
    ? formatInTimeZone(new Date(eventStart), timezone, 'cccc, MMM d, y')
    : null;
  const startTime = eventStart
    ? formatInTimeZone(new Date(eventStart), timezone, 'h:mm aa')
    : null;
  const endTime = eventEnd
    ? formatInTimeZone(new Date(eventEnd), timezone, 'h:mm aa')
    : null;
  const timeDisplay = allDay ? 'All Day Event' : `${startTime} - ${endTime}`;

  return (
    <div
      className="absolute inset-0"
      style={
        backgroundImage
          ? {
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }
          : undefined
      }
    >
      <div
        className={clsx(
          'bg-black/60 absolute top-0 left-0',
          preview ? 'w-1/2 h-full p-[3%]' : 'w-1/2 h-full p-[3.5%]',
        )}
      >
        <div className="w-100">
          {iconName && (
            <img
              className={clsx(
                preview ? 'w-[7vmin] mb-[1.5vmin]' : 'w-[14vmin] mb-[3vmin]',
              )}
              src={iconUrl}
              alt=""
            />
          )}
          <h1
            className={clsx(
              'font-black font-sans text-white text-left leading-tight uppercase',
              preview ? 'text-[2.8vmin]' : 'text-[5.6vmin]',
            )}
          >
            {title}
          </h1>
          <h2
            className={clsx(
              'text-white text-left font-serif leading-tight break-words',
              preview
                ? 'text-[1.75vmin] my-[2vmin] mb-[2vmin]'
                : 'text-[3.5vmin] my-[4vmin] mb-[4vmin]',
            )}
          >
            {content}
          </h2>

          <ul
            className={clsx(
              'font-bold text-white leading-tight uppercase list-none',
              preview ? 'text-[1.5vmin]' : 'text-[3vmin]',
            )}
          >
            {date && <li>{date}</li>}
            <li>{timeDisplay}</li>
            {(location || roomName) && <li>{location || roomName}</li>}
          </ul>
        </div>
      </div>
    </div>
  );
}
