import React, { useEffect, useState } from 'react';
import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Switch,
  Typography,
  CircularProgress,
  Button,
  Tooltip,
  Autocomplete,
  TextField,
} from '@mui/material';
import { useStyles } from '../../../../styles/queries/use-styles.queries';
import { useCategories } from '@features/categories/queries/use-categories.queries';
import { BackgroundImageGrid } from '@features/background-images/components/background-image-grid';
import { useFormContext, Controller } from 'react-hook-form';
import { useSetBackgroundForSubcategory } from '@features/background-images/queries/use-background-images.mutations';
import { ImageUploadArea } from '@features/image-upload/components/image-upload-area';
import { useImageUpload } from '@features/image-upload/queries/use-image-upload.mutations';
import { FormLabel } from './components/form-label';
import {
  filterStylesBySiteType,
  SiteType,
} from '../../../utils/slide-type-filters';

interface FormatStepProps {
  onNext: () => void;
  disabled: boolean;
  siteId?: number;
  organizationId: number;
  siteType?: string;
}

export function FormatStep({
  onNext,
  disabled,
  siteId,
  organizationId,
  siteType = '',
}: FormatStepProps) {
  const { control, watch, setValue, getValues, trigger } = useFormContext();
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  const { mutateAsync: uploadImage, isPending: isUploading } =
    useImageUpload(siteId);

  const { data: categories = [], isLoading: categoriesLoading } =
    useCategories(organizationId);

  const { data: styles = [], isLoading: stylesLoading } = useStyles(
    siteId,
    siteType,
  );
  const { mutateAsync: getBackgroundForSubcategory } =
    useSetBackgroundForSubcategory();

  // Site type specific flags
  const isSeniorLiving = [
    'Senior Living',
    'Senior Living/Residential',
  ].includes(siteType);
  const isResidential = siteType === 'Residential';
  const showUploadTool =
    siteType === 'Corporate' || siteType === 'Senior Living/Residential';
  const showCategories = siteType !== 'Corporate' && siteType !== '';
  const unallowedStyles = ['GIF Centric', 'Name in Lights'];
  const residentialUnallowedStyles = [
    'GIF Centric',
    'Name in Lights',
    'Collage',
  ];

  // Watch form values
  const currentStyleId = watch('format.styleId');
  const useDefaultBackground = watch('format.useDefaultBackground', true);
  const selectedCategory = watch('format.category');
  const selectedStyle = currentStyleId
    ? styles.find((style) => style.id === currentStyleId)
    : null;
  const showPositionImageAboveWeatherBar =
    selectedStyle?.name === 'Image Centric';

  // Ensure subcategories are properly loaded for the Autocomplete component
  const allSubcategories = React.useMemo(() => {
    return categories.flatMap((category) => category.subcategories || []);
  }, [categories]);

  // Find the current subcategory object from the form value
  const currentSubcategory = React.useMemo(() => {
    const subcategoryId = watch('format.subcategory.id');
    const subcategoryValue = watch('format.subcategory');

    // If we have a direct subcategory object with an id, use it
    if (
      subcategoryValue &&
      typeof subcategoryValue === 'object' &&
      'id' in subcategoryValue
    ) {
      // Update the local state to match the form value
      if (selectedSubcategory?.id !== subcategoryValue.id) {
        setSelectedSubcategory(subcategoryValue);
      }
      return subcategoryValue;
    }

    // Otherwise try to find it in allSubcategories
    if (!subcategoryId) return null;

    const foundSubcategory =
      allSubcategories.find((sub) => sub.id === subcategoryId) || null;

    // Update the local state if we found a subcategory
    if (foundSubcategory && selectedSubcategory?.id !== foundSubcategory.id) {
      setSelectedSubcategory(foundSubcategory);
    }

    return foundSubcategory;
  }, [allSubcategories, watch, selectedSubcategory]);

  // Filter styles based on site type using our centralized utility function
  const filteredStyles = React.useMemo(() => {
    return filterStylesBySiteType(styles, siteType as SiteType);
  }, [styles, siteType]);

  // Set default values based on site type
  useEffect(() => {
    if (!siteType) {
      return;
    }

    if (siteType === 'Corporate') {
      // For corporate sites, set default category and subcategory
      // Find the category and subcategory objects
      const defaultCategory = categories.find((cat) => cat.id === 13);
      const defaultSubcategory = defaultCategory?.subcategories?.find(
        (sub) => sub.id === 31,
      );

      if (defaultCategory) {
        setValue('format.category', defaultCategory);
      } else {
        setValue('format.category', { id: 13 });
      }

      if (defaultSubcategory) {
        setValue('format.subcategory', defaultSubcategory);
      } else {
        setValue('format.subcategory', { id: 31 });
      }

      setValue('format.useDefaultBackground', false);

      // For corporate sites, clear any default background
      setValue('format.background', null);
    } else if (
      siteType === 'Senior Living' ||
      siteType === 'Senior Living/Residential'
    ) {
      setValue('format.useDefaultBackground', true);
    }
  }, [siteType, setValue, categories]);

  // Separate useEffect for handling default style
  useEffect(() => {
    if (filteredStyles.length > 0 && !currentStyleId) {
      handleStyleChange(filteredStyles[0].id);
    }
  }, [filteredStyles, currentStyleId]);

  // Handle style change
  const handleStyleChange = (styleId: number) => {
    const selectedStyle = styles.find((style) => style.id === styleId);
    setValue('format.styleId', styleId);
    setValue('format.style', selectedStyle);

    // Handle Image Centric style
    setValue(
      'format.positionImageAboveWeatherBar',
      selectedStyle?.name === 'Image Centric',
    );

    // For corporate sites, don't automatically set a background image
    if (siteType === 'Corporate') {
      return;
    }

    // For other site types, get the default background if using default background
    const currentSubcategoryValue =
      selectedSubcategory || watch('format.subcategory');
    const subcategoryId = currentSubcategoryValue?.id;
    const useDefaultBackground = watch('format.useDefaultBackground');

    if (subcategoryId && useDefaultBackground) {
      getBackgroundForSubcategory(subcategoryId).then((defaultBackground) => {
        setValue('format.background', defaultBackground || null, {
          shouldValidate: true,
        });
      });
    }
  };

  const handleSubcategoryChange = async (subcategoryId: number) => {
    if (!subcategoryId) return;

    const category = categories.find((cat) =>
      cat.subcategories.some((sub) => sub.id === subcategoryId),
    );
    const subcategory = category?.subcategories.find(
      (sub) => sub.id === subcategoryId,
    );

    if (!category || !subcategory) {
      console.error(
        'Could not find category or subcategory for id:',
        subcategoryId,
      );
      return;
    }

    // Create deep copies of the objects to ensure React detects the change
    const categoryToSet = { ...category };
    const subcategoryToSet = { ...subcategory };

    // Update the local state first for immediate UI feedback
    setSelectedSubcategory(subcategoryToSet);

    // Set both category and subcategory objects
    setValue('format.category', categoryToSet, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('format.subcategory', subcategoryToSet, {
      shouldValidate: true,
      shouldDirty: true,
    });

    // Force form validation to update
    await trigger('format.category');
    await trigger('format.subcategory');

    // For corporate sites, don't automatically set a background image
    if (siteType === 'Corporate') {
      return;
    }

    // Only update background if currently using default background
    const useDefaultBackground = watch('format.useDefaultBackground');
    if (useDefaultBackground) {
      try {
        const defaultBackground = await getBackgroundForSubcategory(
          subcategoryId,
        );
        setValue('format.background', defaultBackground || null, {
          shouldValidate: true,
        });
      } catch (error) {
        console.error('Error fetching default background:', error);
      }
    }
  };

  const handleDefaultBackgroundToggle = async (checked: boolean) => {
    setValue('format.useDefaultBackground', checked);

    // For corporate sites, always clear the background when toggling
    if (siteType === 'Corporate') {
      setValue('format.background', null);
      return;
    }

    // Get the current subcategory from state or form
    const currentSubcategoryValue =
      selectedSubcategory || watch('format.subcategory');
    const subcategoryId = currentSubcategoryValue?.id;

    if (checked && subcategoryId) {
      // If toggling to true and we have a subcategory selected,
      // fetch and set the default background
      try {
        const defaultBackground = await getBackgroundForSubcategory(
          subcategoryId,
        );
        setValue('format.background', defaultBackground || null, {
          shouldValidate: true,
        });
      } catch (error) {
        console.error('Error fetching default background:', error);
      }
    }
  };

  // Add a specific useEffect for handling background image initialization
  useEffect(() => {
    const background = watch('format.background');
    const useDefaultBackground = watch('format.useDefaultBackground');

    // For corporate sites, ensure background is properly initialized
    if (siteType === 'Corporate' && background?.id) {
      // If we have a background image, make sure useDefaultBackground is false
      if (useDefaultBackground === true) {
        setValue('format.useDefaultBackground', false);
      }

      // Force a re-render of the form to ensure validation picks up the background
      setValue('format.background', { ...background });
    }
  }, [watch, setValue, siteType]);

  return (
    <form>
      <FormControl component="fieldset" fullWidth>
        <FormLabel>Style</FormLabel>
        {stylesLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Controller
            name="format.styleId"
            control={control}
            render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(e) => handleStyleChange(Number(e.target.value))}
                sx={{
                  '& .MuiFormControlLabel-root': { marginBottom: 1 },
                  '& .MuiRadio-root': { color: 'primary.main' },
                }}
              >
                {filteredStyles.map((style) => (
                  <FormControlLabel
                    key={style.id}
                    value={style.id}
                    control={<Radio />}
                    label={
                      <Tooltip
                        title={style.description}
                        arrow
                        placement="right"
                      >
                        <Typography variant="body1" color="text.primary">
                          {style.name}
                        </Typography>
                      </Tooltip>
                    }
                    data-qa="slide-format-radio-option-style"
                  />
                ))}
              </RadioGroup>
            )}
          />
        )}

        {/* Categories section */}
        {showCategories && (
          <Controller
            name="format.subcategory"
            control={control}
            render={({ field }) => (
              <>
                <FormLabel>Content Category</FormLabel>
                {categoriesLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <Autocomplete
                    {...field}
                    disableClearable
                    value={selectedSubcategory || currentSubcategory || null}
                    onChange={(_, newValue) => {
                      if (
                        newValue &&
                        typeof newValue === 'object' &&
                        'id' in newValue
                      ) {
                        // Update the local state for immediate UI feedback
                        setSelectedSubcategory(newValue);

                        // Update the field value
                        field.onChange(newValue);

                        // Call our handler to update both category and subcategory
                        handleSubcategoryChange(newValue.id);
                      }
                    }}
                    options={allSubcategories}
                    groupBy={(option) => {
                      const category = categories.find((cat) =>
                        cat.subcategories.some((sub) => sub.id === option.id),
                      );
                      return category ? category.name : '';
                    }}
                    getOptionLabel={(option) => option?.name || ''}
                    isOptionEqualToValue={(option, value) => {
                      return option?.id === value?.id;
                    }}
                    filterOptions={(options, { inputValue }) => {
                      const searchTerm = inputValue.toLowerCase();
                      // First, find categories that match the search term
                      const matchingCategories = categories.filter((cat) =>
                        cat.name.toLowerCase().includes(searchTerm),
                      );
                      // Get all subcategories from matching categories
                      const subcategoriesFromMatchingCategories =
                        matchingCategories.flatMap((cat) => cat.subcategories);
                      // Find subcategories that directly match the search term
                      const matchingSubcategories = options.filter((option) =>
                        option.name.toLowerCase().includes(searchTerm),
                      );
                      // Combine and deduplicate results
                      return Array.from(
                        new Set([
                          ...subcategoriesFromMatchingCategories,
                          ...matchingSubcategories,
                        ]),
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        placeholder="Category/Subcategory*"
                        onBlur={() => {
                          // On blur, check if the values are still correct
                          const currentCategory = getValues('format.category');
                          const currentSubcategory =
                            getValues('format.subcategory');
                        }}
                      />
                    )}
                  />
                )}
              </>
            )}
          />
        )}

        {/* Background options */}
        {showCategories && (
          <Controller
            name="format.useDefaultBackground"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(e) =>
                      handleDefaultBackgroundToggle(e.target.checked)
                    }
                  />
                }
                label="Use Default Background Image"
                sx={{ mt: 2 }}
              />
            )}
          />
        )}

        {/* Weather bar position option */}
        {showPositionImageAboveWeatherBar && (
          <Controller
            name="format.positionImageAboveWeatherBar"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Switch {...field} checked={field.value} />}
                label="Position Image Above Weather Bar"
                sx={{ my: 2 }}
              />
            )}
          />
        )}

        {/* Background image selection */}
        {!useDefaultBackground && (
          <>
            {showUploadTool && (
              <>
                <FormLabel>Upload New Image</FormLabel>
                <ImageUploadArea
                  onUpload={async (image) => {
                    const imageUrl = await uploadImage(image);
                    setValue('format.background', imageUrl);
                  }}
                  isUploading={isUploading}
                  progress={0}
                />
              </>
            )}

            <FormLabel sx={{ mt: 2 }}>Select Background Image</FormLabel>
            <BackgroundImageGrid
              onImageSelect={(image) => {
                setValue('format.background', image);
              }}
              siteId={siteId}
              selectedImageId={watch('format.background.id')}
            />
          </>
        )}

        <div className="flex justify-center mt-4">
          <Button
            variant="contained"
            onClick={onNext}
            disabled={disabled}
            data-qa="slide-format-next-button"
          >
            Next
          </Button>
        </div>
      </FormControl>
    </form>
  );
}
