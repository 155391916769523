// import { MbscEventcalendarModule } from '@mobiscroll/angular';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  NgModule,
  Injectable,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  init,
  createErrorHandler,
  instrumentAngularRouting,
  BrowserTracing,
  TraceService,
} from '@sentry/angular-ivy';
import { Router } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { DashboardComponent } from './layout/dashboard/dashboard.component';
import { NavDrawerComponent } from './layout/nav-drawer/nav-drawer.component';
import { ManageModule } from './manage/manage.module';
import { SharedModule } from './shared/shared.module';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';

import packageJson from 'package.json';
// import { provideNativeDateAdapter } from '@angular/material/core';
const { version } = packageJson;

init({
  dsn: environment.cms
    ? 'https://b4a6eb169f7f495c8c6e3955ebb08f15@sentry.io/5182284'
    : 'https://43b1602ceffb4a689bfc59f46abe2bde@sentry.io/5171021',
  integrations: [
    new BrowserTracing({
      tracingOrigins: ['localhost', 'https://yourserver.io/api'],
      routingInstrumentation: instrumentAngularRouting,
    }),
  ],
  environment: environment.environmentName,
  release: `goodman-${environment.cms ? 'cms' : 'display'}@` + version,
  tracesSampleRate: environment.production ? 0.1 : 1.0,
});

@Injectable()
@NgModule({
  declarations: [AppComponent, DashboardComponent, NavDrawerComponent],
  imports: [
    // MbscEventcalendarModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    ManageModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
    DatePipe,
    importProvidersFrom(HttpClientModule),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
