export const descriptionToFacts = (description: string): string[] => {
  const bullets = description.split('- ');
  // Remove empty first element if description starts with bullet
  if (bullets[0] === '') {
    bullets.shift();
  }
  return bullets.map((fact) => fact.replace('\n', '').trim());
};

export const factsToDescription = (facts: string[]): string => {
  return (
    facts
      .filter((fact) => fact)
      .map((fact) => `- ${fact}`)
      .join('\n') + '\n'
  );
};
