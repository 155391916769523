import { useQuery } from '@tanstack/react-query';
import { createCategoriesApi } from '../api/categories.api';
import { auth } from '@core/utils/firebase/firebase';
import { Auth } from '@angular/fire/auth';

const categoriesApi = createCategoriesApi(auth as Auth);

export const useCategories = (orgId: number) => {
  return useQuery({
    queryKey: ['categories', orgId],
    queryFn: () => categoriesApi.getCategoriesByOrganization(orgId),
    enabled: !!orgId,
    staleTime: 0,
  });
};
