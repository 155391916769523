import { PlayArrow, Apps, TableRows } from '@mui/icons-material';
import {
  Box,
  Tabs,
  Tab,
  Tooltip,
  Button,
  Menu,
  TextField,
  MenuItem,
} from '@mui/material';
import React, { useState } from 'react';
import { EventSlideModal } from '../slide-table/components/event-slide-modal';
import { MenuSlideModal } from '../menu-slide/menu-slide-modal';
import { Search as SearchIcon } from '@mui/icons-material';
interface DigitalSignageHeaderProps {
  status: 'all' | 'active' | 'past' | 'future';
  handleStatusChange: (
    event: React.SyntheticEvent,
    newValue: 'all' | 'active' | 'past' | 'future',
  ) => void;
  data: {
    totalCount: number;
  };
  onAddSlide: () => void;
  search: string;
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  siteId: number;
  toggleTable: boolean;
  setToggleTable: (toggleTable: boolean) => void;
}

export function DigitalSignageHeader({
  status = 'active',
  handleStatusChange,
  data,
  onAddSlide,
  search,
  handleSearch,
  siteId,
  toggleTable,
  setToggleTable,
}: DigitalSignageHeaderProps) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [menuSlideModalOpen, setMenuSlideModalOpen] = useState(false);
  const [eventSlideModalOpen, setEventSlideModalOpen] = useState(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleEventSlideClick = () => {
    handleMenuClose();
    setEventSlideModalOpen(true);
  };

  const handleManageSlideClick = () => {
    handleMenuClose();
    setMenuSlideModalOpen(true);
  };

  return (
    <Box className="flex items-center justify-between gap-4 mb-4 px-4 pt-4">
      <h2 className="text-2xl font-bold">
        {status === 'all' && 'All Slides'}
        {status === 'active' && 'Active Slides'}
        {status === 'past' && 'Past Slides'}
        {status === 'future' && 'Future Slides'} ({data?.totalCount || 0})
      </h2>
      <div className="flex items-center gap-4">
        <Tabs
          value={status}
          onChange={handleStatusChange}
          className="min-h-[40px]"
          TabIndicatorProps={{
            className: 'bg-black',
          }}
        >
          <Tab label="ALL TIMES" value="all" className="text-sm font-medium" />
          <Tab label="ACTIVE" value="active" className="text-sm font-medium" />
          <Tab label="PAST" value="past" className="text-sm font-medium" />
          <Tab label="FUTURE" value="future" className="text-sm font-medium" />
        </Tabs>
        <div className="flex items-center gap-2">
          <Tooltip title="Add Slide">
            <Button
              variant="contained"
              className="bg-black hover:bg-gray-800 normal-case"
              onClick={onAddSlide}
            >
              Add Slide
            </Button>
          </Tooltip>
          <Tooltip title="Manage Site Slides">
            <Button
              variant="contained"
              className="bg-black hover:bg-gray-800 normal-case"
              onClick={handleMenuOpen}
            >
              Manage Site Slides
            </Button>
          </Tooltip>
          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleEventSlideClick}>Event slide</MenuItem>
            <MenuItem onClick={handleManageSlideClick}>Menu slide</MenuItem>
          </Menu>
          <Tooltip title="View Live Slideshow">
            <Button
              variant="contained"
              className="bg-black hover:bg-gray-800 min-w-0 px-3"
              href={`/display/${siteId}`}
              target="_blank"
            >
              <PlayArrow />
            </Button>
          </Tooltip>
          <Tooltip
            title={
              toggleTable
                ? 'View Content in Card Format'
                : 'View Content in Table Format'
            }
          >
            <Button
              variant="contained"
              className="bg-black hover:bg-gray-800 min-w-0 px-3"
              onClick={() => setToggleTable(!toggleTable)}
            >
              {toggleTable ? <Apps /> : <TableRows />}
            </Button>
          </Tooltip>
        </div>
      </div>
      <TextField
        placeholder="Search"
        size="small"
        value={search}
        onChange={handleSearch}
        className="flex-1 max-w-md min-w-sm bg-white"
        slotProps={{
          input: {
            endAdornment: <SearchIcon className="text-gray-400 mr-2" />,
          },
        }}
      />
      <EventSlideModal
        open={eventSlideModalOpen}
        onClose={() => setEventSlideModalOpen(false)}
        siteId={siteId}
      />
      <MenuSlideModal
        open={menuSlideModalOpen}
        onClose={() => setMenuSlideModalOpen(false)}
        siteId={siteId}
      />
    </Box>
  );
}
