import { Component, OnInit, Input, forwardRef, OnDestroy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  ControlValueAccessor,
} from '@angular/forms';
import { format, isAfter, endOfMonth } from 'date-fns';

import { CategoryService } from 'src/app/core/services/category.service';
import { ImageService } from 'src/app/core/services/image.service';
import { SiteService } from 'src/app/core/services/site.service';
import { activityTimeOffset } from '../../../activity-time-offset';
import { SubcategoryService } from 'src/app/core/services/subcategory.service';

import { Announcement } from '@models/announcement';
import { Recurrence } from '@models/recurrence';
import { takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { UtilityService } from 'src/app/core/services/utility.service';
import { RecurrenceService } from 'src/app/core/services/recurrence.service';
import { provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { enUS } from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';

export interface EventTimingFormValues {
  date: Date;
  eventStart: Date;
  eventEnd: Date;
  recurrence: boolean;
  repeating: string;
  recurrenceRecipe: Recurrence;
  allDay: boolean;
}

@Component({
  selector: 'app-event-timing',
  templateUrl: './event-timing.component.html',
  styleUrls: ['./event-timing.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EventTimingComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EventTimingComponent),
      multi: true,
    },
    provideDateFnsAdapter({
      parse: {
        dateInput: ['yyyy-MM-dd', 'MM/dd/yyyy'],
      },
      display: {
        dateInput: 'yyyy-MM-dd',
        monthYearLabel: 'MMM yyyy',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM yyyy',
      },
    }),
    {
      provide: MAT_DATE_LOCALE,
      useValue: enUS,
    },
  ],
})
export class EventTimingComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  public eventTimingForm: UntypedFormGroup = this.formBuilder.group({
    date: [], // 'YYYY-MM-DD'
    eventStart: [], // 'HH:mm'
    eventEnd: [], // 'HH:mm'
    repeating: [], // show hide boolean
    recurrenceRecipe: [],
    allDay: false,
  });

  @Input() public editing: boolean;
  @Input() public isLoading: boolean;
  @Input() public eventToEdit: Announcement;
  @Input() public months = [];
  @Input() public selectedMonth: Date;
  @Input() set eventPreview(value) {
    // combine incoming update from detail form with existing timing form values
    this.eventPreview$.next({ ...this.eventPreview$.value, ...value });
  }
  public activeMonthIndex = 12;
  private savedStartTime: string;
  private destroyed$ = new Subject();
  public event = null;
  public eventPreview$ = new BehaviorSubject({});
  public activeDay$ = new BehaviorSubject({});
  public monthRestriction;
  public prettyRecurrence;
  public siteTimezone: string;
  public localTime: string;
  public siteTime: string;
  private timeUpdateInterval: any;

  get value(): EventTimingFormValues {
    return this.eventTimingForm.value;
  }

  set value(value: EventTimingFormValues) {
    this.eventTimingForm.setValue({
      ...value,
      date:
        typeof value.date === 'string'
          ? value.date
          : format(value.date, 'yyyy-MM-dd'),
    });
    this.onChange(value);
    this.onTouched();
  }

  constructor(
    public categoryService: CategoryService,
    public subcategoryService: SubcategoryService,
    public imageService: ImageService,
    public siteService: SiteService,
    public utilityService: UtilityService,
    private formBuilder: UntypedFormBuilder,
    private recurrenceService: RecurrenceService,
  ) {}

  ngOnInit() {
    this.siteTimezone = this.siteService.currentSite.timezone;
    this.updateTimes();

    // Update times every minute
    this.timeUpdateInterval = setInterval(() => {
      this.updateTimes();
    }, 60000);

    if (this.selectedMonth) {
      this.activeMonthIndex = this.months.indexOf(this.selectedMonth);
      this.monthRestriction = {
        min: format(this.selectedMonth, 'yyyy-MM-dd'),
        max: format(endOfMonth(this.selectedMonth), 'yyyy-MM-dd'),
      };
    }
    if (!this.editing) {
    } else {
      if (this.eventToEdit.recurrence) {
        const recurrence = new Recurrence({
          ...this.eventToEdit.recurrence,
          tzid: this.eventToEdit.site.timezone,
        });
        this.eventTimingForm.controls['recurrenceRecipe'].setValue(
          recurrence.recipeForm,
        );

        this.prettyRecurrence =
          this.recurrenceService.prettyPrintRecurrenceRecipe({
            ...this.eventToEdit.recurrence,
          });
      }
    }

    // Need to disable repeat options until start time is established at least once
    this.eventTimingForm.controls['eventStart'].valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (!value) {
          this.eventTimingForm.controls['repeating'].disable();
        } else {
          this.eventTimingForm.controls['repeating'].enable();
        }
      });

    this.eventTimingForm.controls['repeating'].valueChanges
      .pipe(
        tap((value) => {
          if (!value) {
            this.eventTimingForm.controls['recurrenceRecipe'].disable();
          } else {
            this.eventTimingForm.controls['recurrenceRecipe'].enable();
          }
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();

    this.eventTimingForm.controls['allDay'].valueChanges
      .pipe(
        tap((value) => {
          if (!value) {
            this.eventTimingForm.controls['eventStart'].enable();
            this.eventTimingForm.controls['eventEnd'].enable();
          } else {
            this.eventTimingForm.controls['eventStart'].setValue('00:00');
            this.eventTimingForm.controls['eventStart'].disable();
            this.eventTimingForm.controls['eventEnd'].disable();
          }
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();

    this.eventTimingForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.onChange(value);
        this.onTouched();
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    if (this.timeUpdateInterval) {
      clearInterval(this.timeUpdateInterval);
    }
  }

  private updateTimes() {
    const now = new Date();
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.localTime = formatInTimeZone(now, browserTimezone, 'h:mm a');
    this.siteTime = formatInTimeZone(now, this.siteTimezone, 'h:mm a');
  }

  public startTimeBlur() {
    const newEndTime = activityTimeOffset(
      this.eventTimingForm.controls.eventStart.value,
      this.eventTimingForm.controls.eventEnd.value,
      this.savedStartTime,
    );

    this.eventTimingForm.controls.eventEnd.setValue(newEndTime);
  }

  public startTimeFocus() {
    this.savedStartTime = this.eventTimingForm.controls.eventStart.value;
  }

  updateEventTiming(date: Date) {
    const newDate = format(date, 'yyyy-MM-dd');
    const until = this.eventTimingForm?.value?.recurrenceRecipe?.until || null;
    this.eventTimingForm.controls['date'].setValue(newDate);
    // if selected date is after until date, update
    if (isAfter(date, until)) {
      const newTiming = {
        ...this.eventTimingForm.controls['recurrenceRecipe'].value,
        until: date,
      };
      this.eventTimingForm.controls['recurrenceRecipe'].setValue(newTiming);
    }
    this.activeDay$.next(newDate);
  }

  public jumpToMonth(index: number) {
    this.selectedMonth = this.months[index];
    this.activeMonthIndex = index;
  }

  public handleMonthStep(change: number) {
    const currentMonthIndex = this.months.indexOf(this.selectedMonth);
    const newMonthIndex = currentMonthIndex + change;
    this.selectedMonth = this.months[newMonthIndex];
    this.activeMonthIndex = newMonthIndex;
  }

  /**
   * Control Value Accessor Interface
   */

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.eventTimingForm.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: UntypedFormControl) {
    return this.eventTimingForm.valid ? null : { timing: { valid: false } };
  }
}
