import React from 'react';
import classNames from 'classnames';

interface SlideWrapperProps {
  children: React.ReactNode;
  backgroundImage?: string;
  bgContain?: boolean;
  slideKey?: string | number;
  testId?: string;
  opacity?: boolean;
  positionImageAboveWeatherBar?: boolean;
  preview?: boolean;
}

export function SlideWrapper({
  children,
  bgContain = false,
  backgroundImage,
  slideKey,
  testId,
  opacity,
  positionImageAboveWeatherBar = false,
  preview = false,
}: SlideWrapperProps) {
  return (
    <div
      className={classNames(
        'relative',
        preview ? 'h-full w-full' : 'h-screen w-screen',
      )}
      key={slideKey}
      data-testid={testId}
    >
      <div
        className={classNames(
          'absolute top-0 left-0',
          preview ? 'h-full w-full' : 'h-screen w-screen',
        )}
      >
        <div
          className={classNames('w-full bg-no-repeat', {
            'bg-contain bg-center': bgContain,
            'bg-cover bg-top': !bgContain,
            'opacity-40': opacity,
            'h-[85%]': positionImageAboveWeatherBar,
            'h-full': !positionImageAboveWeatherBar,
          })}
          style={{
            backgroundImage: backgroundImage
              ? `url(${backgroundImage})`
              : undefined,
          }}
          data-testid="slide-background"
        />
        <div className="absolute inset-0">{children}</div>
      </div>
    </div>
  );
}
