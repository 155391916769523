import { useQuery } from '@tanstack/react-query';
import type { Image } from '@core/interfaces/api';
import { HttpParams } from '@angular/common/http';
import { createBackgroundImagesApi } from '@features/background-images/api/background-images.api';
import { Auth } from '@angular/fire/auth';
import { auth } from '@core/utils/firebase/firebase';

interface UseBackgroundImagesOptions {
  siteId: number;
  page?: number;
  pageSize?: number;
  search?: string;
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
}

const backgroundImagesApi = createBackgroundImagesApi(auth as Auth);

export function useBackgroundImages({
  siteId,
  page = 1,
  pageSize = 25,
  search = '',
  sortField = 'updatedOn',
  sortOrder = 'desc',
}: UseBackgroundImagesOptions) {
  return useQuery<{ data: Image[]; totalCount: number }>({
    queryKey: [
      'backgroundImages',
      siteId,
      page,
      pageSize,
      search,
      sortField,
      sortOrder,
    ],
    queryFn: () => {
      const pagination = {
        filters: {
          search,
          styleId: '4', // Background image style
        },
        sort: {
          active: sortField,
          direction: sortOrder,
        },
        pageIndex: page - 1,
        pageSize,
      };

      return backgroundImagesApi.getBackgroundImages(
        siteId,
        new HttpParams(),
        pagination,
      );
    },
  });
}

export function useOrgBackgroundImages(orgId: number) {
  return useQuery<Image[]>({
    queryKey: ['orgBackgroundImages', orgId],
    queryFn: () => backgroundImagesApi.getOrgBackgrounds(orgId),
  });
}
