import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  EventType,
  defaultEventKey,
} from '../../../../../core/enums/event-type';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-event-type-select',
  templateUrl: './event-type-select.component.html',
  styleUrls: ['./event-type-select.component.scss'],
})
export class EventTypeSelectComponent implements OnInit, OnDestroy {
  @Input() eventTypeControl: UntypedFormControl;
  public eventTypes = EventType;
  public eventTypeKeys = Object.keys(EventType);
  public selectedType: string = defaultEventKey;
  private destroy$ = new Subject<void>();

  constructor() {}

  ngOnInit() {
    // Set initial value
    this.updateSelectedType(this.eventTypeControl.value);

    // Subscribe to value changes
    this.eventTypeControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.updateSelectedType(value);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updateSelectedType(value: any) {
    if (!value) {
      this.selectedType = defaultEventKey;
      return;
    }

    // If value is already a valid key, use it directly
    if (this.eventTypeKeys.includes(value)) {
      this.selectedType = value;
      return;
    }

    // Try to find a key that matches the value
    const matchingKey = this.eventTypeKeys.find(
      (key) =>
        EventType[key] === value || String(EventType[key]) === String(value),
    );

    if (matchingKey) {
      this.selectedType = matchingKey;
      return;
    }

    // Default to the first key if no match found
    this.selectedType = defaultEventKey;
  }

  public eventTypeChanged(event: any) {
    const selectedValue = event.value;

    // Update the form control with the selected key
    this.eventTypeControl.setValue(selectedValue);
    this.selectedType = selectedValue;
  }
}
