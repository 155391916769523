import React from 'react';
import { Tooltip } from '@mui/material';
import {
  CalendarToday,
  ChatBubble,
  PersonPin,
  Announcement as AnnouncementIcon,
  InsertPhoto,
  GifBox,
  Highlight,
  AutoAwesomeMosaic,
} from '@mui/icons-material';
import { Announcement } from '@core/interfaces/api';
import {
  isEvent,
  isAnnouncement,
  isIndividualSpotlight,
  isSpecialAnnouncement,
  isTitleSlide,
  isGif,
  isNameInLights,
  isCollage,
  getStyleType,
} from '@features/announcements/utils';

interface SlideTypeIconProps {
  announcement: Announcement;
}

export const SlideTypeIcon: React.FC<SlideTypeIconProps> = ({
  announcement,
}) => {
  return (
    <Tooltip title={getStyleType(announcement)}>
      <div>
        {isEvent(announcement) && <CalendarToday />}
        {isAnnouncement(announcement) && <ChatBubble />}
        {isIndividualSpotlight(announcement) && <PersonPin />}
        {isSpecialAnnouncement(announcement) && <AnnouncementIcon />}
        {isTitleSlide(announcement) && <InsertPhoto />}
        {isGif(announcement) && <GifBox />}
        {isNameInLights(announcement) && <Highlight />}
        {isCollage(announcement) && <AutoAwesomeMosaic />}
      </div>
    </Tooltip>
  );
};
