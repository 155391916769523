import { Component, OnInit, Inject } from '@angular/core';
import { SiteFormComponent } from 'src/app/shared/forms/site-form/site-form.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SiteService } from 'src/app/core/services/site.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { environment } from '../../../environments/environment';
import { ImageService } from 'src/app/core/services/image.service';
import {
  ImageUploadComponent,
  UploadResponse,
} from 'src/app/shared/image-upload/image-upload.component';
import { Site } from '@core/interfaces/api';
import {
  getSiteLogoUrl,
  getSitePrintLogoUrl,
  getSiteImageUrl,
} from '@features/sites/utils/site.utils';
@Component({
  providers: [ImageUploadComponent],
  selector: 'app-edit-site',
  templateUrl: './edit-site.component.html',
  styleUrls: ['./edit-site.component.scss'],
})
export class EditSiteComponent implements OnInit {
  public siteForm = SiteFormComponent.formModel();
  public submitting = false;
  public env = environment;
  public logoUrl: string;
  public printLogoUrl: string;
  public logoSrc: string;
  public printLogoSrc: string;
  public imageUrl: string;
  public imageSrc: string;

  constructor(
    private alertService: AlertService,
    public imageService: ImageService,
    private imageUploadComponent: ImageUploadComponent,
    private siteService: SiteService,
    private dialogRef: MatDialogRef<EditSiteComponent>,
    @Inject(MAT_DIALOG_DATA) public site: Site,
  ) {}

  ngOnInit() {
    this.siteForm.setValue(SiteFormComponent.deserialize(this.site));
    this.getImageUrls();
  }

  public onSaveSiteDetails(): void {
    this.submitting = true;
    const updatedSite = SiteFormComponent.serialize(
      this.siteForm.value,
      this.site,
    );
    this.siteService
      .updateSite(updatedSite.id, updatedSite as unknown as Site)
      .then(() => {
        this.siteService.refreshSites();

        // If editing active site, refresh site data
        if (this.site.id == this.siteService.currentSiteId) {
          this.siteService.setSiteDetails(this.site.id);
        }

        this.alertService.success('Property saved.');
        this.closeDialog();
      })
      .catch((error) => {
        this.alertService.error('Unable to save property. Please try again.');
        console.error(error);
      })
      .finally(() => (this.submitting = false));
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public editLogo(): void {
    const imageUploadDialogRef = this.imageUploadComponent.editSiteLogo(
      this.site.id,
    );
    imageUploadDialogRef.afterClosed().subscribe((result: UploadResponse) => {
      if (result.success) {
        this.logoSrc = `${this.logoUrl}&timestamp=${new Date().getTime()}`;
      }
    });
  }

  public editPrintLogo(): void {
    const printImageUploadDialogRef =
      this.imageUploadComponent.editSitePrintLogo(this.site.id);
    printImageUploadDialogRef
      .afterClosed()
      .subscribe((result: UploadResponse) => {
        if (result.success) {
          this.printLogoSrc = `${
            this.printLogoUrl
          }&timestamp=${new Date().getTime()}`;
        }
      });
  }

  public editImage(): void {
    const imageUploadDialogRef = this.imageUploadComponent.editImage(
      this.site.id,
    );
    imageUploadDialogRef.afterClosed().subscribe((result: UploadResponse) => {
      if (result.success) {
        this.imageSrc = `${this.imageUrl}&timestamp=${new Date().getTime()}`;
      }
    });
  }

  private getImageUrls(): void {
    this.logoUrl = getSiteLogoUrl(this.env.storageUrl, this.site.id);
    this.printLogoUrl = getSitePrintLogoUrl(this.env.storageUrl, this.site.id);
    this.imageUrl = getSiteImageUrl(this.env.storageUrl, this.site.id);

    // initialize image srcs
    this.logoSrc = `${this.logoUrl}&timestamp=${new Date().getTime()}`;
    this.printLogoSrc = `${
      this.printLogoUrl
    }&timestamp=${new Date().getTime()}`;
    this.imageSrc = `${this.imageUrl}&timestamp=${new Date().getTime()}`;
  }

  removeLogo(type: 'print' | 'gigi' | 'portrait'): void {
    const url =
      type === 'print'
        ? this.printLogoUrl
        : type === 'portrait'
        ? this.imageUrl
        : this.logoUrl;
    const src =
      type === 'print'
        ? this.printLogoSrc
        : type === 'portrait'
        ? this.imageSrc
        : this.logoUrl;
    const name =
      type === 'print'
        ? 'print logo'
        : type === 'portrait'
        ? 'portrait image'
        : 'GiGi logo';

    this.imageService
      .deleteFromFirestore(url)
      .then(() => {
        this.alertService.success(`Successfully removed ${name}.`);
        if (src === this.printLogoSrc) {
          this.printLogoSrc = null;
        } else if (src === this.imageSrc) {
          this.imageSrc = null;
        } else {
          this.logoSrc = null;
        }
      })
      .catch(() => {
        this.alertService.error(`Unable to delete ${name}.`);
      });
  }
}
