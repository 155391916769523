import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { ActivityService } from 'src/app/core/services/activity.service';
import { EventType } from '../../../../../core/enums/event-type';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { RRule } from 'rrule';
import { requiredIfValidator } from 'src/app/core/validators/requiredIf.validator';
import { dateAfterValidator } from 'src/app/core/validators/dateAfter.validator';
import { dateEqualOrBeforeValidator } from 'src/app/core/validators/dateEqualOrBefore.validator';
import { Recurrence } from '@models/recurrence';
import {
  parseISO,
  format,
  addYears,
  endOfMonth,
  isBefore,
  isSameDay,
} from 'date-fns';
import { provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { enUS } from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';

export interface RecurrenceFormValues {
  timeFrame: number;
  repeatDays: number;
  repeatWeeks: number;
  daysOfTheWeek: any;
  repeatMonths: number;
  dayOfTheMonth: any;
  weekdayOfTheMonth: number;
  stopCondition: 'date' | 'count';
  stopDate: Date;
  numberOfOccurrences: number;
  exceptions: Array<any>;

  interval: number;
  count: number;
}

@Component({
  selector: 'app-calendar-recurrence',
  templateUrl: './recurrence.component.html',
  styleUrls: ['./recurrence.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarRecurrenceComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CalendarRecurrenceComponent),
      multi: true,
    },
    provideDateFnsAdapter({
      parse: {
        dateInput: ['yyyy-MM-dd', 'MM/dd/yyyy'],
      },
      display: {
        dateInput: 'yyyy-MM-dd',
        monthYearLabel: 'MMM yyyy',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM yyyy',
      },
    }),
    {
      provide: MAT_DATE_LOCALE,
      useValue: enUS,
    },
  ],
})
export class CalendarRecurrenceComponent implements OnInit, OnDestroy {
  public events: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public minDate;
  public maxDate;
  @Output() changeMonth = new EventEmitter();
  @Output() recurrence = new EventEmitter();
  @Input() activeMonth: Date;
  @Input() timezone;

  @Input() set start(start: { date: Date; time: string }) {
    if (!start && !start.date && !start.time) {
      return;
    }
    const startDate =
      typeof start.date === 'string' ? parseISO(start.date) : start.date;
    this.minDate = format(startDate, 'yyyy-MM-dd');

    this.maxDate = format(addYears(endOfMonth(startDate), 1), 'yyyy-MM-dd');
  }
  public deactivated = false;
  @Input() set deactivate(value) {
    if (value) {
      this.form.controls['freq'].setValue(null);
      this.form.controls['freq'].disable();
      this.form.controls['stopCondition'].setValue(null);
      this.form.controls['stopCondition'].disable();
      this.form.controls['until'].setValue(null);
      this.form.controls['until'].disable();
    } else {
      this.form.controls['freq'].enable();
      this.form.controls['stopCondition'].enable();
      this.form.controls['stopCondition'].setValue('never');
      this.form.controls['until'].disable();
    }
  }
  @Input() recurrenceValue: RecurrenceFormValues;
  @Input() editing = false;
  private originalValue: Recurrence;

  private destroyed$ = new Subject();

  get value(): Recurrence {
    return this.form.value;
  }

  set value(value: Recurrence) {
    this.originalValue = value;
    if (value?.byweekday?.length > 0) {
      // Byweekday values are objects, which means we must bind to our local reference to populate initial values
      //@ts-ignore
      value.byweekday = value.byweekday.map((weekday) => {
        const foundayIndex = this.dayOptions.findIndex((day) => {
          if (day.value.weekday === weekday.weekday) {
            return day.value;
          }
        });
        return this.dayOptions[foundayIndex].value;
      });
    }

    (value.until as any) = value.until
      ? typeof value.until === 'string'
        ? value.until
        : format(value.until, 'yyyy-MM-dd')
      : null;
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  eventTypes = EventType;
  public freqOptions = {
    Daily: RRule.DAILY,
    Weekly: RRule.WEEKLY,
    Monthly: RRule.MONTHLY,
  };

  public dayOptions = [
    { label: 'Mondays', value: RRule.MO },
    { label: 'Tuesdays', value: RRule.TU },
    { label: 'Wednesdays', value: RRule.WE },
    { label: 'Thursdays', value: RRule.TH },
    { label: 'Fridays', value: RRule.FR },
    { label: 'Saturdays', value: RRule.SA },
    { label: 'Sundays', value: RRule.SU },
  ];

  public dayOfMonthOptions = [
    { value: 1, label: 'First' },
    { value: 2, label: 'Second' },
    { value: 3, label: 'Third' },
    { value: 4, label: 'Fourth' },
    { value: -1, label: 'Last' },
    { value: -2, label: 'Second to last' },
    { value: -3, label: 'Third to last' },
    { value: -4, label: 'Fourth to last' },
  ];

  public stopConditionOptions = [
    { value: 'date', label: 'Specific date' },
    { value: 'never', label: 'Does not end' },
    // { value: 'count', label: 'Number of occurrences' },
  ];
  public form: UntypedFormGroup = this.formBuilder.group({
    freq: [null, Validators.required],
    interval: [
      1,
      [
        Validators.min(1),
        requiredIfValidator(() => {
          return this.form.get('freq')?.value === this.freqOptions.Weekly;
        }),
      ],
    ],
    byweekday: [
      null,
      requiredIfValidator(() => {
        return this.form.get('freq')?.value === this.freqOptions.Weekly;
      }),
    ],
    nbyweekday: [
      null,
      requiredIfValidator(() => {
        return this.form.get('freq')?.value === this.freqOptions.Monthly;
      }),
    ],
    stopCondition: [null, Validators.required],
    until: [
      format(addYears(new Date(), 1), 'yyyy-MM-dd'),
      [
        requiredIfValidator(() => {
          return this.form?.get('stopCondition')?.value === 'date';
        }),
        dateAfterValidator(() => {
          const until = this.form?.get('until')?.value;
          if (!this?.minDate || !until) {
            return false;
          }
          if (this?.minDate && until) {
            if (isBefore(new Date(this.minDate), new Date(until))) {
              return false;
            }
          }
          return this?.minDate;
        }),
        dateEqualOrBeforeValidator(() => {
          const until = this.form?.get('until')?.value;
          if (!this?.maxDate || !this.form?.get('until')?.value) {
            return false;
          }
          if (this?.maxDate && until) {
            if (
              isBefore(new Date(until), new Date(this.maxDate)) ||
              isSameDay(new Date(until), new Date(this.maxDate))
            ) {
              return false;
            }
          }
          return this?.maxDate;
        }),
      ],
    ],
    count: [
      7,
      [
        Validators.min(2),
        Validators.max(52),
        requiredIfValidator(() => {
          return this.form.get('stopCondition')?.value === 'count';
        }),
      ],
    ],
  });

  constructor(
    public activityService: ActivityService,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.form.controls['freq'].valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          const update = {};
          if (
            value !== this.freqOptions.Weekly &&
            value !== this.freqOptions.Monthly
          ) {
            update['byweekday'] = null;
          }
          if (value !== this.freqOptions.Monthly) {
            update['nbyweekday'] = null;
          }
          if (!this.form.controls['interval'].value) {
            update['interval'] = 1;
          }
          this.form.patchValue(update);
        }
      });
    // Must clear data on change to prevent buggy recurrence
    this.form.controls['stopCondition'].valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          const update = {};
          if (value === 'never') {
            update['until'] = null;
            update['count'] = null;
            this.form.controls['until'].disable();
          }
          if (value === 'date') {
            if (this.originalValue?.until) {
              update['until'] = this.originalValue.until;
            } else {
              update['until'] = null;
            }
            update['count'] = null;
            this.form.controls['until'].enable();
          }

          this.form.patchValue(update);
        }
      });
    this.form.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.onChange(value);
        this.onTouched();
        if (!value) {
          return;
        }
      });
    // Restrict end date one year in the future
    if (this.activeMonth) {
      this.minDate = formatInTimeZone(this.activeMonth, 'UTC', 'yyyy-MM-dd');
      this.maxDate = formatInTimeZone(
        addYears(endOfMonth(this.activeMonth), 1),
        'UTC',
        'yyyy-MM-dd',
      );
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  /**
   * Control Value Accessor Interface
   */

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { recurrence: { valid: false } };
  }
}
