import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { Observable, lastValueFrom } from 'rxjs';

import { MealPlan } from '@interfaces/mealPlan';
import { SiteMealPlanResponse, SiteMenuDto } from '@interfaces/siteMenu';
import { environment } from 'src/environments/environment';
import {
  MealPlanPayload,
  MenuInput,
  MenuPayload,
  SiteMenuPayload,
} from '../../../../../core/interfaces/menu';
import {
  createMenuItem,
  createMenuItemCollection,
} from '../../../../../core/models/menuItem';
import { PdfService } from './pdf.service';
import { CALENDAR_COLORS } from '../constants/calendar-colors';
import { Menu } from '../interfaces/api';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(
    private http: HttpClient,
    private csvParser: NgxCsvParser,
    private pdfService: PdfService,
  ) {}

  public createMealPlan(payload: MealPlanPayload): Promise<MealPlan> {
    const url = `${environment.apiv3Url}/menu/meal-plan`;
    return <Promise<MealPlan>>lastValueFrom(
      this.http.post(url, JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
      }),
    );
  }

  public getSiteMenu(siteId: number, date: Date): Observable<SiteMenuDto> {
    const queryParams = new HttpParams({
      fromObject: {
        date: date.toISOString(),
      },
    });
    const url = `${environment.apiv3Url}/menu/${siteId}`;
    return this.http.get<SiteMenuDto>(url, { params: queryParams });
  }

  public getMenuBySiteId(siteId: number, date: Date): Observable<Menu> {
    const queryParams = new HttpParams({
      fromObject: {
        date: date.toISOString(),
      },
    });
    const url = `${environment.apiv3Url}/menu/${siteId}`;
    return this.http.get<Menu>(url, { params: queryParams });
  }

  public deleteSiteMenus(siteId: number) {
    const url = `${environment.apiv3Url}/menu/site-menus/${siteId}`;
    return this.http.delete(url);
  }

  public async parseCsv(file: File) {
    const parsed: MenuInput[] | NgxCSVParserError = await lastValueFrom(
      this.csvParser.parse(file, { header: true }),
    );

    if (!Array.isArray(parsed)) {
      throw new Error('Unable to parse CSV.');
    } else if (parsed.length === 0) {
      throw new Error('CSV file was empty.');
    } else {
      const mealPlanMenus: MenuPayload[] = parsed.map((menu) => {
        const dayNum = Number(menu['DayNum']) - 1;
        return {
          day: dayNum % 7,
          week: Math.floor(dayNum / 7),

          soup: createMenuItem(menu, 'Daily Soup'),
          bread: createMenuItem(menu, 'Daily Bread'),

          lunchEntree: createMenuItem(menu, 'Lunch Entree'),
          lunchAlt: createMenuItem(menu, 'Lunch Alt'),
          lunchTossedSalad: createMenuItem(menu, 'Lunch TossedSalad'),
          lunchDressing: createMenuItem(menu, 'Lunch Dressing'),
          lunchDailySalad: createMenuItem(menu, 'Lunch DailySalad'),
          lunchSides: createMenuItemCollection(menu, [
            'Lunch Side1',
            'Lunch Side2',
            'Lunch Side3',
            'Lunch Side4',
            'Lunch Side5',
          ]),
          lunchFruit: createMenuItem(menu, 'Lunch Fruit'),
          lunchDessert: createMenuItem(menu, 'Lunch Dessert'),
          dinnerOpt1: createMenuItem(menu, 'Dinner Opt1'),
          dinnerOpt1Sides: createMenuItemCollection(menu, [
            'Dinner Opt1Side1',
            'Dinner Opt1Side2',
            'Dinner Opt1Side3',
          ]),
          dinnerOpt2: createMenuItem(menu, 'Dinner Opt2'),
          dinnerOpt2Sides: createMenuItemCollection(menu, [
            'Dinner Opt2Side1',
            'Dinner Opt2Side2',
          ]),
          dinnerAlt: createMenuItem(menu, 'Dinner Alt'),
          dinnerSalad: createMenuItem(menu, 'Dinner Salad'),
          dinnerDressing: createMenuItem(menu, 'Dinner Dressing'),
          dinnerDessert: createMenuItem(menu, 'Dinner Dessert'),
          dinnerFruit: createMenuItem(menu, 'Dinner Fruit'),
        };
      });

      return mealPlanMenus;
    }
  }

  // 0 = current week, 1 = next week
  // This will presumably change siginificantly in the
  // near future, as the client wants more functionality
  // in their ability to choose menu printing options
  public async downloadWagMenu(site: SiteMealPlanResponse, weekIndex: number) {
    // Get the current date and set it to the start of the day
    const startDate: Date = new Date();
    startDate.setHours(0, 0, 0, 0);

    // Move to the desired week
    startDate.setDate(startDate.getDate() + weekIndex * 7);

    // Move to the previous Sunday (0 = Sunday in JavaScript's getDay())
    const currentDay = startDate.getDay();
    startDate.setDate(startDate.getDate() - currentDay);

    const filename = `${site.name.replace(
      /[^0-9a-zA-Z]+/g,
      '_',
    )}_WAG_Menu_Starting_${startDate.toLocaleString().replace(/\//g, '_')}`;
    const title = `Weekly Menu`;
    const menus = await this.getWagMenuData(startDate, site.siteId);
    const content = this.getWagMenuContent(startDate, menus);
    const logo = await this.pdfService.getLogo(site.siteId); // TODO set this function back to private if not needed here
    this.pdfService.downloadPdf(
      filename,
      title,
      content,
      'LETTER',
      'landscape',
      this.pdfService.menuWagStyles,
    );
  }

  private async getWagMenuData(
    startDate: Date,
    siteId: number,
  ): Promise<SiteMenuPayload[]> {
    const endDate: Date = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);
    endDate.setHours(23, 59, 59, 999);
    const queryParams = new HttpParams({
      fromObject: {
        date: startDate.toISOString(), // "startDate"
        endDate: endDate.toISOString(),
      },
    });
    const url = `${environment.apiv3Url}/menu/${siteId}`;
    return lastValueFrom(
      this.http.get<SiteMenuPayload[]>(url, { params: queryParams }),
    );
  }

  private getWagMenuContent(startDate: Date, menuData: SiteMenuPayload[]) {
    const colWidth = 98;
    const labelRowHeight = 30;
    const contentRowHeight = 200;
    // TODO add subheader with data and week number
    // TODO add site logo
    const content = [
      {
        text: `Week At A Glance Menu`,
        style: 'pageHeader',
      },
      {
        table: {
          heights: [
            labelRowHeight,
            labelRowHeight,
            contentRowHeight,
            labelRowHeight,
            contentRowHeight,
          ],
          widths: [
            colWidth,
            colWidth,
            colWidth,
            colWidth,
            colWidth,
            colWidth,
            colWidth,
          ],
          body: [
            [
              {
                text: 'SUNDAY',
                style: 'daysOfWeek',
                fillColor: CALENDAR_COLORS.blue,
              },
              {
                text: 'MONDAY',
                style: 'daysOfWeek',
                fillColor: CALENDAR_COLORS.blue,
              },
              {
                text: 'TUESDAY',
                style: 'daysOfWeek',
                fillColor: CALENDAR_COLORS.blue,
              },
              {
                text: 'WEDNESDAY',
                style: 'daysOfWeek',
                fillColor: CALENDAR_COLORS.blue,
              },
              {
                text: 'THURSDAY',
                style: 'daysOfWeek',
                fillColor: CALENDAR_COLORS.blue,
              },
              {
                text: 'FRIDAY',
                style: 'daysOfWeek',
                fillColor: CALENDAR_COLORS.blue,
              },
              {
                text: 'SATURDAY',
                style: 'daysOfWeek',
                fillColor: CALENDAR_COLORS.blue,
              },
            ],
            [
              {
                text: 'Lunch',
                style: 'mealHeader',
                colSpan: 7,
                fillColor: CALENDAR_COLORS.blue,
              },
            ],
            this.getLunchMenuArray(menuData),
            [
              {
                text: 'Dinner',
                style: 'mealHeader',
                colSpan: 7,
                fillColor: CALENDAR_COLORS.blue,
              },
            ],
            this.getDinnerMenuArray(menuData),
          ],
        },
        style: 'mainTable',
        layout: {
          hLineColor: CALENDAR_COLORS.black90,
          vLineColor: CALENDAR_COLORS.black90,
        },
      },
    ];
    return content;
  }

  // Takes the given array of menu data
  // and returns an array of objects
  // representing the lunch row in a WAG menu
  getLunchMenuArray(menuData: SiteMenuPayload[]) {
    const lunchRow = [];
    for (let i = 0; i < 7; i++) {
      const menuCellStack = [];
      const singleDayMenu = menuData[i].menu;
      // Checking this in case there isn't a full week of data available
      if (singleDayMenu) {
        const dailySoupName = singleDayMenu.soup
          ? `${singleDayMenu.soup.name} Soup`
          : 'Soup of the Day';
        menuCellStack.push({
          text: dailySoupName.toUpperCase(),
          style: 'menuItem',
        });
        menuCellStack.push({
          text: singleDayMenu.lunchEntree
            ? singleDayMenu.lunchEntree.name.toUpperCase()
            : '',
          style: 'menuItem',
        });
        for (const side of singleDayMenu.lunchSides) {
          menuCellStack.push({
            text: side.name.toUpperCase(),
            style: 'menuItem',
          });
        }
        menuCellStack.push({
          text: singleDayMenu.lunchDessert
            ? singleDayMenu.lunchDessert.name.toUpperCase()
            : '',
          style: 'menuItem',
        });
      }
      lunchRow.push({
        stack: menuCellStack,
      });
    }
    return lunchRow;
  }

  // Takes the given array of menu data
  // and returns an array of objects
  // representing the dinner row in a WAG menu
  getDinnerMenuArray(menuData: SiteMenuPayload[]) {
    const dinnerRow = [];

    for (let i = 0; i < 7; i++) {
      const menuCellStack = [];
      const singleDayMenu = menuData[i].menu;
      // Checking this in case there isn't a full week of data available
      if (singleDayMenu) {
        const dailySoupName = singleDayMenu.soup
          ? `${singleDayMenu.soup.name} Soup`
          : 'Soup of the Day';
        menuCellStack.push({
          text: dailySoupName.toUpperCase(),
          style: 'menuItem',
        });
        menuCellStack.push({
          text: singleDayMenu.dinnerOpt1.name.toUpperCase(),
          style: 'menuItem',
        });
        for (const side of singleDayMenu.dinnerOpt1Sides) {
          menuCellStack.push({
            text: side.name.toUpperCase(),
            style: 'menuItem',
          });
        }
        if (singleDayMenu.dinnerOpt2) {
          menuCellStack.push({
            text: singleDayMenu.dinnerOpt2.name.toUpperCase(),
            style: 'menuItem',
          });
        }
        for (const side of singleDayMenu.dinnerOpt2Sides) {
          menuCellStack.push({
            text: side.name.toUpperCase(),
            style: 'menuItem',
          });
        }
        menuCellStack.push({
          text: singleDayMenu.dinnerDessert.name.toUpperCase(),
          style: 'menuItem',
        });
      }
      dinnerRow.push({
        stack: menuCellStack,
      });
    }
    return dinnerRow;
  }
}
