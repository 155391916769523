import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import imageCompression from 'browser-image-compression';
import FileType from 'file-type/browser';
import { AllowedImageFileTypes } from '../../../../../../core/enums/allowed-image-file-types';

export async function isAllowedFileType(file: File): Promise<boolean> {
  const fileType = await FileType.fromBlob(file);
  return fileType
    ? Object.keys(AllowedImageFileTypes).includes(fileType.ext)
    : false;
}

export async function resizeFile(
  file: File,
  options: {
    maxSizeMB?: number;
    maxWidthOrHeight?: number;
    onProgress?: (progress: number) => void;
  } = {},
): Promise<File> {
  const defaultOptions = {
    maxWidthOrHeight: 3840,
    maxSizeMB: 4,
    ...options,
  };

  return imageCompression(file, defaultOptions);
}

export async function uploadToFirestore(
  filePath: string,
  file: File,
): Promise<string> {
  const storage = getStorage();
  const storageRef = ref(storage, filePath);

  await uploadBytes(storageRef, file, {
    cacheControl: 'public,max-age=259200',
  });

  return getDownloadURL(storageRef);
}

export async function getDownloadUrl(path: string): Promise<string> {
  const storage = getStorage();
  const storageRef = ref(storage, path);
  return getDownloadURL(storageRef);
}

export function getSiteBackgroundImageFilePath(siteId: number): string {
  return `${siteId}/${crypto.randomUUID()}`;
}
