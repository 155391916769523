import React, { useState, useEffect, Fragment, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  CircularProgress,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import clsx from 'clsx';
import { Announcement } from '@core/interfaces/api';
import { isEvent, isIndividualSpotlight } from '@features/announcements/utils';
import { format } from 'date-fns-tz';
import { SlideTypeIcon } from './components/slide-type-icon';
import { DraggableRow } from './components/draggable-row';
import { useAnnouncementsMutation } from '@features/announcements/queries/announcements.mutations';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  TouchSensor,
  useSensor,
  MouseSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
  closestCenter,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { SortableContext } from '@dnd-kit/sortable';
import { renderBullets } from '../utils/content-formatting';

const columns = [
  { id: 'type', label: 'Type' },
  { id: 'title', label: 'Title' },
  { id: 'startShowing', label: 'Start Showing' },
  { id: 'stopShowing', label: 'Stop Showing' },
  { id: 'description', label: 'Description' },
  { id: 'actions', label: 'Actions' },
];

export interface SlideTableProps {
  onEdit: (announcement: Announcement) => void;
  onDelete: (announcement: Announcement) => void;
  siteId: number;
  data: Announcement[];
  sortField: string;
  sortOrder: 'desc' | 'asc';
  setSortField: (field: string) => void;
  setSortOrder: (order: 'desc' | 'asc') => void;
  status: string;
  isLoading: boolean;
  error: Error;
}

export const SlideTable: React.FC<SlideTableProps> = ({
  onEdit,
  onDelete,
  siteId,
  data,
  sortField,
  sortOrder,
  setSortField,
  setSortOrder,
  status,
  isLoading,
  error,
}) => {
  const { updateAnnouncementOrder, isUpdatingOrder } = useAnnouncementsMutation(
    {
      siteId,
    },
  );

  // Add this new state to track the current order
  const [localData, setLocalData] = useState<Announcement[] | null>(null);
  const [activeId, setActiveId] = useState<number | null>(null);

  // Update localData whenever the fetched data changes
  useEffect(() => {
    if (data) {
      let sortedData = [...data];
      if (sortField === 'startShowing' && sortOrder === 'asc') {
        sortedData.sort((a, b) => {
          // Sort by order first
          if (a.displayOrder !== b.displayOrder) {
            return a.displayOrder - b.displayOrder;
          }
          // Then by startShowing date
          const dateA = new Date(a.startShowing || '').getTime();
          const dateB = new Date(b.startShowing || '').getTime();
          return dateA - dateB;
        });
      }
      setLocalData(sortedData);
    }
  }, [data, sortField, sortOrder]);

  const renderActionButtons = (announcement: Announcement) => {
    if (announcement.isGenerated) {
      return (
        <span className="inline-block pt-2.5 text-gray-600 italic">
          System Generated
        </span>
      );
    }

    if (isEvent(announcement)) {
      return (
        <div className="flex items-center gap-2">
          <IconButton
            disabled
            color="primary"
            title="Events can be edited in the Event Calendar"
            className="opacity-50"
          >
            <EditIcon className="text-gray-400" />
          </IconButton>
          <IconButton
            disabled
            color="primary"
            title="Events can be deleted in the Event Calendar"
            className="opacity-50"
          >
            <DeleteIcon className="text-gray-400" />
          </IconButton>
        </div>
      );
    }

    return (
      <div className="flex items-center gap-2">
        <IconButton
          onClick={() => onEdit(announcement)}
          color="primary"
          className="hover:bg-gray-100"
        >
          <EditIcon className="text-gray-700 hover:text-black" />
        </IconButton>
        <IconButton
          onClick={() => onDelete(announcement)}
          color="primary"
          className="hover:bg-gray-100"
        >
          <DeleteIcon className="text-gray-700 hover:text-black" />
        </IconButton>
      </div>
    );
  };

  const handleSort = (columnId: string) => {
    const isAsc = sortField === columnId && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortField(columnId);
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor),
  );

  async function handleDragEnd(event: DragEndEvent) {
    if (!localData) return;
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = localData.findIndex(
        (item) => item.id === Number(active.id),
      );
      const newIndex = localData.findIndex(
        (item) => item.id === Number(over?.id),
      );

      const updatedArray = arrayMove(localData, oldIndex, newIndex);
      setLocalData(updatedArray);

      try {
        const newOrder = updatedArray.map((announcement) => announcement.id);
        await updateAnnouncementOrder({
          newOrder,
        });
      } catch (error) {
        setLocalData(data || null);
      }
    }
    setActiveId(null);
  }

  function handleDragStart(event: DragStartEvent) {
    setActiveId(Number(event.active.id));
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  const renderContent = (announcement: Announcement) => {
    if (isIndividualSpotlight(announcement)) {
      try {
        return <ul>{renderBullets(announcement.content)}</ul>;
      } catch (e) {
        // Fallback to regular content if JSON parsing fails
        return announcement.content;
      }
    }
    return announcement.content;
  };

  function renderRow(announcement: Announcement) {
    return (
      <DraggableRow
        id={announcement.id.toString()}
        disabled={status !== 'active'}
        canDrag={sortField === 'startShowing' && sortOrder === 'asc'}
        aria-describedby={`draggable-${announcement.id}`}
      >
        <TableCell className="text-left whitespace-nowrap">
          <div className="flex items-center gap-2 flex-nowrap">
            <SlideTypeIcon announcement={announcement} />
          </div>
        </TableCell>
        <TableCell className="text-left font-medium">
          {announcement.title}
        </TableCell>
        <TableCell className="text-center">
          {announcement.startShowing && (
            <Tooltip
              title={format(
                new Date(announcement.startShowing),
                "MMMM d 'at' h:mma zzz",
              )}
            >
              <span>
                {format(new Date(announcement.startShowing), 'MM/dd/yyyy')}
              </span>
            </Tooltip>
          )}
        </TableCell>
        <TableCell className="text-center">
          {announcement.stopShowing && (
            <Tooltip
              title={format(
                new Date(announcement.stopShowing),
                "MMMM d 'at' h:mma zzz",
              )}
            >
              <span>
                {format(new Date(announcement.stopShowing), 'MM/dd/yyyy')}
              </span>
            </Tooltip>
          )}
        </TableCell>
        <TableCell className="text-left">
          {renderContent(announcement)}
        </TableCell>
        <TableCell className="text-right">
          {renderActionButtons(announcement)}
        </TableCell>
      </DraggableRow>
    );
  }

  const selectedRow = useMemo(() => {
    if (!activeId) return null;
    const selectedAnnouncement = localData?.find(
      (announcement) => announcement.id === activeId,
    );
    return selectedAnnouncement;
  }, [activeId, localData]);

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
    >
      <TableContainer className="shadow-none border-separate p-4">
        <Table className="min-w-full table-fixed" style={{ maxWidth: '100%' }}>
          <TableHead>
            <TableRow>
              {status === 'active' && <TableCell className="w-10" />}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  className={clsx('font-medium text-sm', {
                    'w-[100px]':
                      column.id === 'type' || column.id === 'actions',
                    'w-[200px]': column.id === 'title',
                    'w-[160px]':
                      column.id === 'startShowing' ||
                      column.id === 'stopShowing',
                    'text-left':
                      column.id === 'type' ||
                      column.id === 'title' ||
                      column.id === 'description',
                    'text-center':
                      column.id === 'startShowing' ||
                      column.id === 'stopShowing',
                    'text-right': column.id === 'actions',
                  })}
                >
                  {column.id !== 'actions' ? (
                    <TableSortLabel
                      active={sortField === column.id}
                      direction={sortField === column.id ? sortOrder : 'asc'}
                      onClick={() => handleSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  Error loading announcements
                </TableCell>
              </TableRow>
            ) : !localData?.length ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No slides available
                </TableCell>
              </TableRow>
            ) : (
              <SortableContext
                items={localData.map((announcement) => announcement.id)}
                strategy={verticalListSortingStrategy}
              >
                {localData?.map((announcement, index) => (
                  <Fragment key={announcement.id}>
                    {renderRow(announcement)}
                  </Fragment>
                ))}
              </SortableContext>
            )}
          </TableBody>
        </Table>
        <DragOverlay>
          {activeId && (
            <Table className="w-full">
              <TableBody>{renderRow(selectedRow)}</TableBody>
            </Table>
          )}
        </DragOverlay>
      </TableContainer>
    </DndContext>
  );
};
