import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { addDays, addYears, startOfDay, endOfDay } from 'date-fns';

interface PreferencesStepProps {
  onSubmit: (data: any) => void;
  disabled: boolean;
  isSubmitting: boolean;
}

export function PreferencesStep({
  onSubmit,
  disabled,
  isSubmitting,
}: PreferencesStepProps) {
  const { control, watch, setValue, handleSubmit } = useFormContext();

  // Watch form values
  const publishNow = watch('preferences.publishNow', true);
  const publishForever = watch('preferences.publishForever', false);

  // Default dates
  const defaultStopShowingDateTime = endOfDay(addDays(new Date(), 14));
  const permanentStopShowingDateTime = endOfDay(addYears(new Date(), 50));
  const defaultStartShowingDateTime = startOfDay(new Date());

  const handlePublishNowToggle = (checked: boolean) => {
    setValue('preferences.publishNow', checked);
    if (checked) {
      const currentTime = new Date();
      setValue('preferences.startShowing', currentTime);
      setValue('preferences.startShowingTime', currentTime);
    }
  };

  const handlePublishForeverToggle = (checked: boolean) => {
    setValue('preferences.publishForever', checked);
    if (checked) {
      const endDate = permanentStopShowingDateTime;
      setValue('preferences.stopShowing', endDate);
      setValue('preferences.stopShowingTime', endDate);
    } else {
      const endDate = defaultStopShowingDateTime;
      setValue('preferences.stopShowing', endDate);
      setValue('preferences.stopShowingTime', endDate);
    }
  };

  const handleFormSubmit = handleSubmit(async (data) => {
    try {
      onSubmit(data);
    } catch (error) {
      console.error('Error creating announcement:', error);
    }
  });

  return (
    <form className="flex flex-col gap-4" onSubmit={handleFormSubmit}>
      <FormControl fullWidth>
        <Typography variant="h6" gutterBottom>
          Display Duration (seconds)
        </Typography>
        <Controller
          name="preferences.displayDuration"
          control={control}
          rules={{ required: true, min: 1 }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              type="number"
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />

        <div className="mt-6">
          <Controller
            name="preferences.publishNow"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Switch
                    checked={field.value}
                    onChange={(e) => handlePublishNowToggle(e.target.checked)}
                  />
                }
                label="Make available immediately"
              />
            )}
          />
        </div>

        {!publishNow && (
          <div className="mt-4">
            <Typography variant="h6" gutterBottom>
              Publish Starting
            </Typography>
            <div className="flex gap-4">
              <Controller
                name="preferences.startShowing"
                control={control}
                rules={{ required: !publishNow }}
                render={({ field: { value, ...field } }) => (
                  <DatePicker
                    {...field}
                    value={value ? new Date(value) : null}
                    label="Start Date"
                    minDate={new Date()}
                    disabled={publishNow}
                  />
                )}
              />
              <Controller
                name="preferences.startShowingTime"
                control={control}
                rules={{ required: !publishNow }}
                render={({ field: { value, ...field } }) => (
                  <TimePicker
                    {...field}
                    value={
                      value ? new Date(value) : defaultStartShowingDateTime
                    }
                    label="Start Time"
                    disabled={publishNow}
                    slots={{
                      openPickerIcon: () => null,
                    }}
                  />
                )}
              />
            </div>
          </div>
        )}

        <div className="mt-6">
          <Typography variant="h6" gutterBottom>
            Publish Until
          </Typography>
          {!publishForever && (
            <div className="flex gap-4">
              <Controller
                name="preferences.stopShowing"
                control={control}
                rules={{ required: !publishForever }}
                render={({ field: { value, ...field } }) => (
                  <DatePicker
                    {...field}
                    value={value ? new Date(value) : null}
                    label="End Date"
                    minDate={new Date()}
                    disabled={publishForever}
                  />
                )}
              />
              <Controller
                name="preferences.stopShowingTime"
                control={control}
                rules={{ required: !publishForever }}
                render={({ field: { value, ...field } }) => (
                  <TimePicker
                    {...field}
                    value={value ? new Date(value) : defaultStopShowingDateTime}
                    label="End Time"
                    disabled={publishForever}
                    slots={{
                      openPickerIcon: () => null,
                    }}
                  />
                )}
              />
            </div>
          )}

          <div className="mt-2">
            <Controller
              name="preferences.publishForever"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={field.value}
                      onChange={(e) =>
                        handlePublishForeverToggle(e.target.checked)
                      }
                    />
                  }
                  label="Make permanent"
                />
              )}
            />
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <Button
            variant="contained"
            type="submit"
            disabled={disabled || isSubmitting}
            data-qa="slide-preferences-submit-button"
          >
            {isSubmitting ? 'Saving...' : 'Finish'}
          </Button>
        </div>
      </FormControl>
    </form>
  );
}
