import { Auth } from '@angular/fire/auth';
import { createApi } from '@core/api/base-api';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { PaginationInformation } from '@shared/pagination-server-side/datasources/genericBE.datasource';
import type { MealPlanPayload, SiteMenu } from '@core/interfaces/api';
import { SiteMealPlanResponse } from '@interfaces/siteMenu';

export const createMenusApi = (auth: Auth) => {
  const api = createApi(auth);

  return {
    getSiteMealPlans: (
      organizationId: number,
      queryParams: HttpParams,
      pagination: PaginationInformation,
      siteName?: string,
    ) => {
      const { active, direction } = pagination.sort || {};

      let params = queryParams;
      params = params.append('sortField', active || '');
      params = params.append('sortOrder', (direction || '').toLowerCase());

      if (siteName) {
        params = params.append('search', siteName);
      }

      return api.get<{
        data: SiteMealPlanResponse[];
        totalCount: number;
      }>(`/menu/organization/${organizationId}/sites`, { params });
    },

    createMealPlan: (data: MealPlanPayload) =>
      api.post<boolean>('/menu/meal-plan', data),

    getMenuForDate: (siteId: number, date: Date, endDate?: Date) => {
      const params = new HttpParams().append('date', date.toISOString());

      if (endDate) {
        params.append('endDate', endDate.toISOString());
      }

      return api.get<SiteMenu>(`/menu/${siteId}`, { params });
    },

    deleteSiteMenus: (siteId: number) =>
      api.delete<void>(`/menu/site-menus/${siteId}`),
  };
};

// Angular compatibility layer (temporary)
@Injectable({ providedIn: 'root' })
export class MenusApi {
  private readonly api = createMenusApi(this.auth);

  constructor(private auth: Auth) {}

  getSiteMealPlans = this.api.getSiteMealPlans;
  createMealPlan = this.api.createMealPlan;
  getMenuForDate = this.api.getMenuForDate;
  deleteSiteMenus = this.api.deleteSiteMenus;
}
