import { Injectable } from '@angular/core';
import {
  ReplaySubject,
  BehaviorSubject,
  Observable,
  Subscription,
  of,
  from,
} from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { map, take } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Site } from '@core/interfaces/api';
import { CreateSitePayload, Style } from '@core/interfaces/api';
import { OrgService } from './org.service';
import { UtilityService } from './utility.service';
import { PaginationInformation } from '@shared/pagination-server-side/datasources/genericBE.datasource';
import { SitesApi } from '@features/sites/api/sites.api';
import {
  isSiteActivitiesEnabled,
  isSiteNotificationsEnabled,
} from '@features/sites/utils/site.utils';
import { createStyle } from '@features/styles/utils/style.utils';
import { StylesApi } from '@features/styles/api/styles.api';
import { MenusApi } from '@features/menus/api/menus.api';
import { SiteMealPlanResponse } from '@interfaces/siteMenu';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  private _sites = new ReplaySubject<Site[]>();
  public readonly sites = this._sites.asObservable();

  private _site = new BehaviorSubject<Site>(null); // active site
  public readonly site = this._site.asObservable();

  private _siteId = new BehaviorSubject<number>(null);
  public readonly siteId = this._siteId.asObservable(); // active site id

  // Info is available on this.site
  // TODO: Refactor to remove this variable
  // Only used in slide.format.component
  private _siteType = new BehaviorSubject<string>(null);
  public readonly siteType = this._siteType.asObservable(); // active site type

  private _styles = new ReplaySubject<Style[]>();
  public readonly styles = this._styles.asObservable();

  private _announcementStyle$: Subscription;

  constructor(
    private sitesApi: SitesApi,
    private stylesApi: StylesApi,
    private authService: AuthService,
    private orgService: OrgService,
    private utilityService: UtilityService,
    private menusApi: MenusApi,
  ) {
    this.listenForOrgUpdates();
    this.listenForUserUpdates();
  }

  public get currentSite(): Site {
    return this._site.getValue();
  }

  public get currentSiteId(): number {
    return this._siteId.getValue();
  }

  public get currentSiteType(): string {
    return this._siteType.getValue();
  }

  /**
   * Return array of all siteIds
   * Made it a Promise since it was setup as a ReplaySubject and only BehaviorSubjects have .getValue(),
   * and I didn't want to accidentally break downstream functionality by switching to BehaviorSubject.
   */
  public get siteIds(): Promise<number[]> {
    return new Promise((resolve) => {
      this.sites
        .pipe(
          take(1),
          map((sites) => sites.map((site) => site.id)),
        )
        .subscribe(resolve);
    });
  }

  private listenForOrgUpdates(): void {
    this.orgService.org.subscribe(() => {
      this.refreshSites();
    });
  }

  private listenForUserUpdates(): void {
    this.authService.user.subscribe(() => {
      this.refreshSites();
    });
  }

  public setActiveSite(siteId: number): void {
    this._siteId.next(Number(siteId));
    this.setSiteDetails(siteId);

    if (this._announcementStyle$) {
      this._announcementStyle$.unsubscribe();
    }

    this._announcementStyle$ = from(
      this.getAnnouncementStyles(siteId),
    ).subscribe((styles) => this._styles.next(styles));

    this.utilityService.setSentrySiteId(siteId);
  }

  public async refreshSites(): Promise<void> {
    if (!this.authService.currentOrgId) return;

    try {
      const sites = await this.sitesApi.getSitesByOrgId(
        String(this.authService.currentOrgId),
      );
      this._sites.next(sites);
    } catch (error) {
      console.error(error);
    }
  }

  public async updateSite(id: number, site: Partial<Site>): Promise<Site> {
    return this.sitesApi.updateSite(id, site);
  }

  public async createSite(site: CreateSitePayload): Promise<Site> {
    return this.sitesApi.createSite(site);
  }

  public deleteSite(id: string): Promise<null> {
    try {
      return this.sitesApi.deleteSite(id);
    } catch (err) {
      console.error('ERROR', err);
    }
  }

  public getSitesByOrgId(id: string): Promise<Site[]> {
    try {
      return this.sitesApi.getSitesByOrgId(id);
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public getSite(id: number): Observable<Site> {
    try {
      return from(this.sitesApi.getSite(id));
    } catch (err) {
      console.error('ERROR', err);
    }
  }

  public async getAnnouncementStyles(siteId: number): Promise<Style[]> {
    try {
      const styles = await this.stylesApi.getStylesForSite(siteId);
      return styles.map((style) => createStyle(style));
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public async setSiteDetails(id: number): Promise<void> {
    try {
      const site = await this.sitesApi.getSite(id);
      if (site) {
        this._siteType.next(site.siteType.description);
        this._site.next(site);
      } else {
        this._siteType.next(null);
        this._site.next(null);
      }
    } catch (error) {
      console.error(error);
    }
  }

  public setSite(id: number) {
    this.getSite(id).subscribe((site) => {
      if (site) {
        this._site.next(site);
      } else {
        this._site.next(null);
      }
    });
  }

  public getSitesPaginated(
    queryParams: HttpParams,
    pagination?: PaginationInformation,
  ): Promise<{
    data: Site[];
    totalCount: number;
  }> {
    if (!this.currentSite?.organization?.id) {
      return Promise.resolve({ data: [], totalCount: 0 });
    }

    return this.sitesApi.getSitesPaginated(
      this.currentSite.organization.id,
      queryParams,
      pagination,
    );
  }

  public getSiteMealPlans(
    queryParams: HttpParams,
    pagination?: PaginationInformation,
    siteName?: string,
  ): Observable<{
    data: SiteMealPlanResponse[];
    totalCount: number;
  }> {
    if (!this.currentSite?.organization?.id) {
      return of({ data: [], totalCount: 0 });
    }

    return from(
      this.menusApi.getSiteMealPlans(
        this.currentSite.organization.id,
        queryParams,
        pagination,
        siteName,
      ),
    );
  }

  public isActivitiesEnabled(site: Site): boolean {
    return isSiteActivitiesEnabled(site);
  }

  public isNotificationsEnabled(site: Site): boolean {
    return isSiteNotificationsEnabled(site);
  }
}
