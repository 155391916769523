import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '@mui/material';
import { CollageDescription } from './components/collage-description';
import { IndividualSpotlightDescription } from './components/individual-spotlight-description';
import { GifDescription } from './components/gif-description';
import { DefaultDescription } from './components/default-description';

interface DescriptionStepProps {
  onNext: () => void;
  disabled: boolean;
  siteId: number;
}

export function DescriptionStep({
  onNext,
  disabled,
  siteId,
}: DescriptionStepProps) {
  const { watch } = useFormContext();
  // Watch format style to determine which description component to show
  const format = watch('format.style');
  const slideType = format?.name?.toLowerCase();
  const styleId = format?.id;
  const isCollage = slideType === 'collage';
  const isIndividualSpotlight = slideType === 'individual spotlight';
  const isGifCentric = slideType === 'gif centric';

  return (
    <form className="flex flex-col gap-6">
      {/* Show DefaultDescription for all types except IndividualSpotlight */}
      {!isIndividualSpotlight && <DefaultDescription styleId={styleId} />}

      {/* Render appropriate description component based on slide type */}
      {isCollage && <CollageDescription siteId={siteId} />}
      {isIndividualSpotlight && (
        <IndividualSpotlightDescription siteId={siteId} />
      )}
      {isGifCentric && <GifDescription siteId={siteId} />}

      <div className="flex justify-center mt-4">
        <Button
          variant="contained"
          color="primary"
          onClick={onNext}
          disabled={disabled}
          data-qa="announcement-button-next"
        >
          Next
        </Button>
      </div>
    </form>
  );
}
