import { Auth } from '@angular/fire/auth';
import { createApi } from '@core/api/base-api';
import type { CategoryDto } from '@core/interfaces/api';
import { Injectable } from '@angular/core';

export const createCategoriesApi = (auth: Auth) => {
  const api = createApi(auth);

  return {
    getCategoriesByOrganization: (orgId: number) =>
      api
        .get<CategoryDto[]>(`/categories/organization/${orgId}/all`)
        .then((response) => {
          return response.sort((a, b) => a.name.localeCompare(b.name));
        }),
  };
};

// Angular compatibility layer (temporary)
@Injectable({ providedIn: 'root' })
export class CategoriesApi {
  private readonly api = createCategoriesApi(this.auth);

  constructor(private auth: Auth) {}

  getCategoriesByOrganization = this.api.getCategoriesByOrganization;
}
