import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  takeUntil,
} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { SiteService } from 'src/app/core/services/site.service';
import { Announcement } from '@models/announcement';
import {
  ActivityService,
  BulkUpdateResponse,
} from 'src/app/core/services/activity.service';
import { ManageService } from 'src/app/core/services/manage.service';
import { AlertService } from 'src/app/core/services/alert.service';
import {
  BatchEvents,
  BatchEventFormComponent,
} from './batch-event-form/batch-event-form.component';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { PdfService } from 'src/app/core/services/pdf.service';
import {
  ConfirmationDialogValues,
  ConfirmationDialogComponent,
} from 'src/app/shared/modals/confirmation-dialog/confirmation-dialog.component';
import {
  defaultEventKey,
  EventType,
} from '../../../../../core/enums/event-type';
import { EventDownloadModalComponent } from 'src/app/shared/modals/event-download-modal-component/event-download-modal.component';
import { EventFormModalComponent } from 'src/app/manage/activities/event-form-modal-component/event-form-modal.component';
import { CategorySelectModalComponent } from './category-select-modal/category-select-modal.component';
import { CreateAnnouncementPayload } from 'src/app/core/interfaces/api';
import {
  addDays,
  addMonths,
  endOfMonth,
  format,
  startOfMonth,
  subDays,
  subMonths,
} from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

interface ScheduleDay {
  dateHeader: Date;
  events: Announcement[];
}

@AutoUnsubscribe()
@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
})
export class ActivitiesComponent implements OnInit, OnDestroy {
  editing = false;
  months: Date[] = [];
  selectedDate: Date;
  selectedEvent: Announcement;
  selectedMonth: Date;
  showForm: Date;
  backgroundId: number;
  categoryId: number;
  displayedColumns: string[] = ['day', 'time', 'title', 'room'];
  public showBatchCreator = false;
  private destroyed = new Subject<boolean>();
  public isSaving = new BehaviorSubject<boolean>(false);
  public eventTypes;
  public selectedMonth$ = new Subject<number>();
  public isLoading = true;
  public timezone;
  public printCalendarRestrictions: {
    monthData;
    maxEventRows;
    maxChars;
    eventRowLimits;
  };
  @ViewChild(BatchEventFormComponent) batchEventFormComponent;

  constructor(
    public activityService: ActivityService,
    public siteService: SiteService,
    public manageService: ManageService,
    public calendarService: CalendarService,
    private pdfService: PdfService,
    private alert: AlertService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.eventTypes = EventType;
    this.siteService.site
      .pipe(
        filter((site) => site !== null),
        distinctUntilKeyChanged('id'),
        takeUntil(this.destroyed),
      )
      .subscribe((site) => {
        if (!site) {
          return;
        }
        this.timezone = site.timezone;
        this.months = this.generateDateOptions(site.timezone);

        this.selectedMonth = this.months[12];
        this.updatePrintCalendarRestrictions(this.selectedMonth);

        this.activityService.refreshActivities(this.selectedMonth);
      });

    this.activityService.activities
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed))
      .subscribe((activities: Announcement[]) => {
        this.isLoading = false;
      });
    this.activityService.isGettingEvents
      .pipe(takeUntil(this.destroyed))
      .subscribe((response) => {
        // listen for load start, we'll handle load end after markup resolves
        if (response && !this.isLoading) {
          this.isLoading = response;
        }
      });

    // Debounce changing selected month too quickly
    this.selectedMonth$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        takeUntil(this.destroyed),
      )
      .subscribe((month: number) => {
        this.changeSelectedMonth(month);
      });
  }

  ngOnDestroy() {
    // Adding this to manually unsubscribe from the subscriptions in ngOnInit();
    // @AutoUnsubscribe didn't appear to be working for those.
    this.destroyed.next(true);
  }

  //
  public showPrintDialog(monthDateTime: Date): void {
    // Keeping for demo purposes/changing paper or font size on the fly
    // const selectedMonthIndex = this.selectedMonth.get('month');
    // const selectedYear = this.selectedMonth.toFormat('yyyy');
    // const dialogRef = this.dialog.open(PrintModalComponent, {
    //   minWidth: '400px',
    //   data: {
    //     selectedMonthIndex: selectedMonthIndex,
    //     selectedYear: selectedYear,
    //   },
    // });
    const dialogRef = this.dialog.open(CategorySelectModalComponent, {
      minWidth: '400px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const subcategoryIds = result.includes('All') ? undefined : result;
        this.pdfService.downloadCalendarPdf(
          this.selectedMonth.getMonth(),
          this.selectedMonth.getFullYear(),
          monthDateTime,
          { subcategoryIds },
        );
      }
    });
  }

  public openCalendarPdf(): void {
    const selectedMonthIndex = this.selectedMonth.getMonth();
    const selectedYear = this.selectedMonth.getFullYear();
    this.pdfService.openActivityCalendarPdf(
      selectedMonthIndex,
      Number(selectedYear),
      this.selectedMonth,
    );
  }

  formatEventsForTable(events: Announcement[]): ScheduleDay[] {
    const schedule: ScheduleDay[] = [];
    const lastDayOfMonth: Date = endOfMonth(this.selectedMonth);
    const daysInMonth = lastDayOfMonth.getDate();

    // Iterate from 0 to daysInMonth-1 to cover all days
    for (let i = 0; i < daysInMonth; i++) {
      const currentDay: Date = addDays(startOfMonth(this.selectedMonth), i);
      const currentDayEvents = events.filter((event) => {
        return new Date(event.eventStart).getDate() === currentDay.getDate();
      });
      schedule.push({
        dateHeader: currentDay,
        events: currentDayEvents ? currentDayEvents : [],
      });
    }
    return schedule;
  }

  /**
   * Generate months array for month selector.
   * Moment objects are based on the property's timezone.
   * When used for an API call, the Angular service is responsible for converting to UTC.
   */
  private generateDateOptions(timezone: string) {
    const dateOptions: Date[] = [];

    for (let n = -12; n < 13; n++) {
      // Create date in UTC
      const baseDate = new Date();
      // Convert to the target timezone
      const zonedDate = utcToZonedTime(baseDate, timezone);
      // Get start of month and add months
      const monthStart = startOfMonth(zonedDate);
      const shiftedDate = addMonths(monthStart, n);
      // Convert back to UTC for storage
      dateOptions.push(zonedTimeToUtc(shiftedDate, timezone));
    }

    return dateOptions;
  }

  public deleteActivity(activity: Announcement): void {
    const confirmData: ConfirmationDialogValues = {
      title: `Delete "${activity.title}"?`,
      message: 'This action can not be undone.',
      yesText: 'Delete',
      onConfirm: async () => {
        await Promise.all([
          this.manageService.deleteAnnouncement(activity),
          this.activityService.refreshActivities(this.selectedMonth, false),
        ]);

        this.alert.success(`"${activity.title}" deleted.`);
      },
      onError: (error) => {
        console.error(error);
        this.alert.error(
          `Error deleting "${activity.title}". Please try again.`,
        );
      },
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
    });
  }

  public handleMonthStep(change: number) {
    this.isLoading = true;
    const currentMonthIndex = this.months.indexOf(this.selectedMonth);
    const newMonthIndex = currentMonthIndex + change;
    this.selectedMonth = this.months[newMonthIndex];
    this.selectedMonth$.next(newMonthIndex);
  }

  public handleMonthSelect(newMonthIndex: number) {
    this.isLoading = true;
    this.selectedMonth = this.months[newMonthIndex];
    this.selectedMonth$.next(newMonthIndex);
  }

  public changeSelectedMonth(month: number) {
    this.activityService.refreshActivities(this.selectedMonth, false);
    this.updatePrintCalendarRestrictions(this.selectedMonth);
  }

  public async updatePrintCalendarRestrictions(selectedMonth: Date) {
    // Determine how many rows are in this month
    const monthData = await this.calendarService.evaluateCalendarFormatForMonth(
      selectedMonth,
    );

    // how many event title rows will fit in a day
    const maxEventRows = this.pdfService.calculateRowsInCalRow(
      monthData.rowsRequired,
    );

    // given the max characters that fit in a row based on default page width...
    const maxChars = this.pdfService.getMaxNumCharacters(
      // @ts-ignore
      this.pdfService.selectedPageSize.width,
    );

    // limits are based on the 'small' font size because
    // a quantity of events over a certain threshold will always trigger the smaller font
    const eventRowLimits = maxEventRows.numLinesPerDay.small;
    this.printCalendarRestrictions = {
      monthData,
      maxEventRows,
      maxChars,
      eventRowLimits,
    };
  }

  public addSingleEvent(day: Date) {
    this.showForm = day;
    this.editing = false;
    this.selectedDate = day;
  }

  public editActivity(event: Announcement, day: Date) {
    this.selectedDate = day;
    this.selectedEvent = event;
    this.editing = true;
    this.showForm = day;
  }

  public closeForm() {
    this.isSaving.next(false);
    this.editing = false;
    this.showForm = null;
    this.selectedEvent = null;
  }

  public addEvent(): void {
    const dialogRef = this.dialog.open(EventFormModalComponent, {
      width: '80vw',
      height: '660px',
      data: {
        selectedMonth: this.selectedMonth,
        months: this.months,
      },
    });

    dialogRef.afterClosed().subscribe(async () => {
      // Refresh activities when the dialog is closed
      await this.activityService.refreshActivities(this.selectedMonth, false);
    });
  }

  private formToAnnouncement(form, date: Date): CreateAnnouncementPayload {
    const { value } = form;

    let startShowing: Date;
    const dateFormatted = format(
      utcToZonedTime(date, this.timezone),
      'yyyy-MM-dd',
    );

    // Combine date and time then convert to the correct timezone
    const eventStart = zonedTimeToUtc(
      new Date(`${dateFormatted}T${value.eventStart}`),
      this.timezone,
    );

    let eventEnd: Date;
    // Need to check if event end should be the subsequent day
    if (value.eventEnd < value.eventStart) {
      // Add a day if the end time is earlier than start time
      eventEnd = zonedTimeToUtc(
        addDays(new Date(`${dateFormatted}T${value.eventEnd}`), 1),
        this.timezone,
      );
    } else {
      eventEnd = zonedTimeToUtc(
        new Date(`${dateFormatted}T${value.eventEnd}`),
        this.timezone,
      );
    }

    const stopShowing: Date = eventEnd;
    if (EventType[value.eventType] === EventType.SpecialEvent) {
      startShowing = subDays(eventStart, 1);
    } else {
      startShowing = subDays(eventStart, 1);
    }

    return {
      ...this.selectedEvent,
      startShowing: startShowing.toISOString(),
      stopShowing: stopShowing.toISOString(),
      roomId: value.room.id ? parseInt(value.room.id) : undefined,
      location: value.location,
      eventStart: eventStart.toISOString(),
      eventEnd: eventEnd.toISOString(),
      siteId: this.siteService.currentSiteId,
      styleId: 3,
      title: value.title.trim(),
      content: value.content,
      backgroundId: value.background.id,
      eventTags: value.eventTags,
      eventType: value.eventType ? value.eventType : defaultEventKey,
      categoryId: value.category,
      subcategoryId: value.subcategory,
      videoUrl: value.videoUrl,
      recurrenceId: value.recurrenceId || null,
      allDay: value.allDay ? value.allDay : false,
      showOnPrintCalendar: value.showOnPrintCalendar,
      showOnDigitalSignage: value.showOnDigitalSignage,
      secondaryImages: value.secondaryImaged?.map((image) => image.id),
    };
  }

  public async saveBatch(batch: BatchEvents) {
    try {
      const events = [];
      this.isSaving.next(true);
      batch.dates.forEach((date: Date) => {
        events.push(this.formToAnnouncement(batch.form, date));
      });

      const result = await this.manageService.saveBulkAnnouncements(events);
      await this.activityService.refreshActivities(this.selectedMonth, false);
      this.batchEventFormComponent.clear();
      this.alert.success(`${events.length} events saved!`);
      this.toggleShowBatchEvents();
    } catch (e) {
      console.error('error saving batch events', e);
      throw new Error(e);
    }
  }

  public async saveSingle(singleForm) {
    this.isSaving.next(true);
    const eventDate = singleForm.value.date
      ? singleForm.value.date
      : this.selectedDate;
    const event = this.formToAnnouncement(singleForm, eventDate);
    const result = await this.manageService.saveAnnouncement(event);
    await this.activityService.refreshActivities(this.selectedMonth, false);
    this.closeForm();
    this.alert.success(`${event.title} saved!`);
  }

  public async copyPreviousMonthsActivities() {
    const prevMonth = subMonths(this.selectedMonth, 1);

    // moment(this.selectedMonth).subtract(1, 'month');
    const confirmData: ConfirmationDialogValues = {
      title: `Copy repeating activities into ${format(
        this.selectedMonth,
        'MMMM yyyy',
      )}?`,
      message: `All repeating activities in from ${format(
        prevMonth,
        'MMMM yyyy',
      )} will be
        copied into ${format(
          this.selectedMonth,
          'MMMM yyyy',
        )}. This will result in duplicates
        if you have already performed this operation.`,
      yesText: 'Copy',
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        // tslint:disable-next-line:max-line-length
        const insertResult: BulkUpdateResponse =
          await this.activityService.copyPreviousMonthsEvents(
            this.siteService.currentSiteId,
            this.selectedMonth,
          );
        if (insertResult && insertResult.raw) {
          if (insertResult.raw.length) {
            this.alert.success(
              `${insertResult.raw.length} events copied from ${format(
                prevMonth,
                'MMMM yyyy',
              )}.`,
            );
            this.activityService.refreshActivities(this.selectedMonth, false);
          } else {
            this.alert.success(
              `No events found in ${format(prevMonth, 'MMMM yyyy')} to copy.`,
            );
          }
        }
      }
    });
  }

  public toggleShowBatchEvents() {
    if (!this.showBatchCreator) {
      // If opening batch event form, scroll to top
      const page = document.getElementsByClassName('dash-content');
      page[0].scrollTop = 0;
    }
    this.isSaving.next(false);
    this.showBatchCreator = !this.showBatchCreator;
  }

  public deleteMonthsActivities(): void {
    const confirmData: ConfirmationDialogValues = {
      title: `Delete all activities for ${format(
        this.selectedMonth,
        'MMMM yyyy',
      )}?`,
      message: `All activities in ${format(
        this.selectedMonth,
        'MMMM yyyy',
      )} will no longer be displayed or accessible by administrators.`,
      yesText: 'Delete',
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.activityService
          .deleteMonthsEvents(
            this.siteService.currentSiteId,
            this.selectedMonth,
          )
          .then((res) => {
            this.activityService.refreshActivities(this.selectedMonth, false);
            this.alert.success(
              `All events in ${format(
                this.selectedMonth,
                'MMMM yyyy',
              )} deleted.`,
            );
          })
          .catch((err) => {
            console.error(err);
            this.alert.error(
              `Error deleting events in ${format(
                this.selectedMonth,
                'MMMM yyyy',
              )}. Please try again.`,
            );
          });
      }
    });
  }

  public trackByFn(index, item) {
    return item && item.id ? item.id : null;
  }

  public showDownloadDialog(): void {
    const selectedMonthIndex = this.selectedMonth.getMonth();
    const selectedYear = this.selectedMonth.getFullYear();
    const site = this.siteService.currentSite;

    this.dialog.open(EventDownloadModalComponent, {
      width: '500px',
      data: {
        selectedMonthIndex: selectedMonthIndex,
        selectedYear: selectedYear,
        site: site,
        timezone: site.timezone,
      },
    });
  }
}
