import { useQuery } from '@tanstack/react-query';
import { createSitesApi } from '@features/sites/api/sites.api';
import { auth } from '@core/utils/firebase/firebase';
import { Auth } from '@angular/fire/auth';

const sitesApi = createSitesApi(auth as Auth);

export function useSite(siteId: number) {
  return useQuery({
    queryKey: ['site', siteId],
    queryFn: async () => {
      try {
        const site = await sitesApi.getSite(siteId);
        return site;
      } catch (error) {
        console.error('Error fetching site:', error);
        throw error;
      }
    },
    enabled: !!siteId,
  });
}
