import React from 'react';
import { TableCell, TableRow, Tooltip } from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CSSProperties } from 'react';
import clsx from 'clsx';
import { DragIndicator as DragIcon } from '@mui/icons-material';

interface DraggableRowProps {
  children: React.ReactNode;
  id: string;
  disabled?: boolean;
  canDrag?: boolean;
}

export const DraggableRow: React.FC<DraggableRowProps> = ({
  children,
  id,
  disabled = false,
  canDrag = true,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    disabled: disabled || !canDrag,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : undefined,
    position: isDragging ? 'relative' : undefined,
    zIndex: isDragging ? 999 : undefined,
  };

  return (
    <TableRow
      ref={setNodeRef}
      style={style}
      className={clsx(isDragging && 'shadow-lg')}
    >
      {!disabled && (
        <TableCell className="w-10">
          <Tooltip
            title={
              canDrag
                ? 'Drag to reorder'
                : 'Table must be sorted by Start Showing to enable drag-and-drop'
            }
          >
            <div
              ref={setActivatorNodeRef}
              {...attributes}
              {...listeners}
              className={clsx(
                'drag-handle',
                canDrag ? 'cursor-grab' : 'cursor-default',
                isDragging && 'cursor-grabbing',
              )}
            >
              <DragIcon
                className={clsx(canDrag ? 'text-gray-400' : 'text-gray-200')}
              />
            </div>
          </Tooltip>
        </TableCell>
      )}
      {children}
    </TableRow>
  );
};
