import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Announcement } from '@models/announcement';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  skipWhile,
  takeUntil,
} from 'rxjs/operators';
import { ActivityService } from 'src/app/core/services/activity.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { ManageService } from 'src/app/core/services/manage.service';
import { CategoryService } from 'src/app/core/services/category.service';
import { SubcategoryService } from 'src/app/core/services/subcategory.service';
import { SiteService } from 'src/app/core/services/site.service';
import {
  defaultEventKey,
  EventColors,
  EventType,
} from '../../../../../../core/enums/event-type';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogValues,
} from '../../../shared/modals/confirmation-dialog/confirmation-dialog.component';
import { BatchEvents } from 'src/app/manage/activities/batch-event-form/batch-event-form.component';
import { RRule, RRuleSet } from 'rrule';
import {
  DeleteRecurrenceDialogComponent,
  EventDeleteDialogValues,
} from '../../../shared/modals/delete-recurrence-modal/delete-recurrence-dialog.component';
import {
  EditRecurrenceDialogComponent,
  EventEditDialogValues,
} from '../../../shared/modals/edit-recurrence-modal/edit-recurrence-dialog.component';
import { Recurrence } from '@models/recurrence';
import { EventDetailsFormValues } from './forms/event-details/event-details.component';
import { EventTimingFormValues } from './forms/event-timing/event-timing.component';
import { RecurrenceService } from 'src/app/core/services/recurrence.service';
import {
  addDays,
  addHours,
  endOfDay,
  format,
  parse,
  startOfDay,
  subDays,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { CreateAnnouncementPayload } from 'src/app/core/interfaces/api';
import { ImageService } from 'src/app/core/services/image.service';
export interface EventFormValues {
  details: EventDetailsFormValues;
  timing: EventTimingFormValues;
}
@Component({
  selector: 'app-event-form-modal',
  templateUrl: './event-form-modal.component.html',
  styleUrls: ['./event-form-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventFormModalComponent implements OnInit, OnDestroy {
  public eventForm: UntypedFormGroup = this.formBuilder.group({
    details: new FormControl(),
    timing: new FormControl(),
  });
  public isSaving = new BehaviorSubject(false);
  public months = [];
  public selectedMonth: Date = new Date();
  public heading;
  public eventPreview: {
    date: string;
    start: string;
    end: string;
    color: string;
    title: string;
    allDay: boolean;
    recurring: RRuleSet;
    recurringException?: any;
    recurrenceRecipe: object;
  };
  private destroyed$ = new Subject();
  public eventToEdit: Announcement;
  public startDateUpdated = false;
  public eventTimeUpdated = false;

  constructor(
    private activityService: ActivityService,
    private siteService: SiteService,
    private alertService: AlertService,
    private manageService: ManageService,
    private recurrenceService: RecurrenceService,
    private categoryService: CategoryService,
    private subcategoryService: SubcategoryService,
    private imageService: ImageService,
    public dialogRef: MatDialogRef<EventFormModalComponent>,
    public formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      event: Announcement;
      selectedMonth: Date;
      months: Array<any>;
      editing: boolean;
    },
  ) {
    this.months = this.data.months;
    const msDate = this.data.selectedMonth.getTime();
    this.selectedMonth = utcToZonedTime(
      new Date(msDate),
      this.siteService.currentSite.timezone, // Use the site's timezone instead of zoneName
    );
    this.heading = this.data.event ? `Edit Event` : `Create Event`;
  }

  async ngOnInit() {
    // Ensure categories are loaded
    await this.categoryService.refreshCategories();

    // Ensure subcategory service has loaded categories if needed
    if (this.subcategoryService.currentCategoryList.length === 0) {
      // Force a refresh of images which will trigger subcategory loading
      await this.imageService.refreshAllBackgrounds();
    }

    // populate default values for the form
    this.setEventDetailsForm();
    if (this.data.event) {
      this.eventToEdit = this.data.event;
      const event = this.data.event;
      this.setEventDetailsForm(event);
      const timezone = this.siteService.currentSite.timezone;
      const recurrence = event.recurrence
        ? new Recurrence({
            ...event.recurrence,
            tzid: this.siteService.currentSite.timezone,
          })
        : null;

      // Date values are saved in UTC but needs to be translated to site's timezone
      const startDate = utcToZonedTime(new Date(event.eventStart), timezone);
      this.eventForm.controls['timing'].setValue({
        date: startDate,
        eventStart: format(
          utcToZonedTime(new Date(event.eventStart), timezone),
          'HH:mm',
        ),
        eventEnd: format(
          utcToZonedTime(new Date(event.eventEnd), timezone),
          'HH:mm',
        ),
        recurrenceRecipe: recurrence ? recurrence.recipeForm : null,
        repeating: Boolean(event?.recurrence),
        allDay: event.allDay,
      });

      // changes to the date must be captured because changing the start date will impact the recurrence calculations
      this.eventForm.controls['timing'].valueChanges
        .pipe(
          distinctUntilKeyChanged('date'),
          skipWhile(
            (value) =>
              !this.startDateUpdated &&
              this.eventForm.controls['timing'].value.date === startDate,
          ),
          takeUntil(this.destroyed$),
        )
        .subscribe((value) => {
          this.startDateUpdated =
            this.recurrenceService.testForNewRecurrenceStart(
              this.eventToEdit?.recurrence,
              value,
            );
        });

      // Changes to start or end time may change recurrence behavior IF the user chooses to change 'this and future' or 'all events in the series'.
      // Needs to be tracked separately from start date changes so we can support changing the start/end time of a single event
      // in a series.
      this.eventForm.controls['timing'].valueChanges
        .pipe(
          distinctUntilChanged((prev, next) => {
            const start = prev.eventStart !== next.eventStart;
            const end = prev.eventEnd !== next.eventEnd;
            return [start, end].some((value) => value);
          }),
          takeUntil(this.destroyed$),
        )
        .subscribe((timing) => {
          const startTimeUpdated =
            format(
              utcToZonedTime(new Date(this.eventToEdit?.eventStart), timezone),
              'HH:mm',
            ) !== timing.eventStart;

          const endTimeUpdated =
            format(
              utcToZonedTime(new Date(this.eventToEdit?.eventEnd), timezone),
              'HH:mm',
            ) !== timing.eventEnd;

          const updates = [startTimeUpdated, endTimeUpdated];

          this.eventTimeUpdated = updates.some((value) => (value = true));
        });
    } else {
      this.eventForm.controls['timing'].setValue({
        date: this.selectedMonth,
        eventStart: format(
          utcToZonedTime(new Date(), this.siteService.currentSite.timezone),
          'HH:mm',
        ),
        eventEnd: format(
          addHours(
            utcToZonedTime(new Date(), this.siteService.currentSite.timezone),
            1,
          ),
          'HH:mm',
        ),
        recurrenceRecipe: null,
        repeating: false,
        allDay: false,
      });
    }

    this.eventForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        const detail = value?.details;
        const timing = value?.timing;
        if (!detail || !timing) {
          return;
        }

        const date = timing.date
          ? timing.date
          : format(this.selectedMonth, 'yyyy-MM-dd');
        // ONLY update the preview with recurrence if we have either a set count or end date. Otherwise lots of unnecessary dates will be generated.
        this.eventPreview = {
          ...this.eventPreview,
          date,
          title: detail?.title || '(unnamed event)',
          color:
            EventColors[detail.eventType] || EventColors[EventType.Generic],
          allDay: false,
          recurrenceRecipe:
            timing?.recurrenceRecipe?.freq &&
            (timing?.recurrenceRecipe?.count || timing?.recurrenceRecipe?.until)
              ? this.mergeRecurrenceDetails(timing)
              : null,
        };
      });
  }

  setEventDetailsForm(event?: Announcement) {
    // Handle eventType conversion
    let eventType = defaultEventKey;
    if (event && event.eventType) {
      // If it's a string that matches an enum value, find the corresponding key
      if (typeof event.eventType === 'string') {
        const keys = Object.keys(EventType);
        // First check if it's already a key
        if (keys.includes(event.eventType)) {
          eventType = event.eventType;
        } else {
          // Try to find the key by value
          const key = keys.find((k) => EventType[k] === event.eventType);
          if (key) {
            eventType = key;
          }
        }
      } else {
        // If it's not a string, use the default
        eventType = defaultEventKey;
      }
    }

    this.eventForm.controls['details'].setValue({
      title: event?.title || '',
      content: event?.content ? event.content : '',
      roomId: event?.roomId || '',
      location: event?.location || '',
      subcategory: event?.subcategory || '',
      eventType: eventType,
      background: event?.background || null,
      category: event?.category || null,
      eventTags: event?.eventTags || null,
      videoUrl: event?.videoUrl || null,
      showOnPrintCalendar: event?.showOnPrintCalendar ?? true,
      showOnDigitalSignage: event?.showOnDigitalSignage ?? true,
    });
  }

  // calculates the date start and end from date/time inputs with combined formats 'yyyy-MM-dd HH:mm'
  // pulls timezone data
  mergeRecurrenceDetails(timing) {
    let start;
    let exdates;
    if (this.eventToEdit?.recurrence) {
      if (this.eventToEdit.recurrence.dtstart instanceof Date) {
        start = startOfDay(new Date(this.eventToEdit.recurrence.dtstart));
      } else {
        if (this.startDateUpdated) {
          start = startOfDay(new Date(timing.date));
        } else {
          start = startOfDay(new Date(this.eventToEdit.recurrence.dtstart));
        }
      }

      if (this.eventToEdit.recurrence.exdates && !this.startDateUpdated) {
        if (typeof this.eventToEdit.recurrence.exdates[0] === 'string') {
          exdates = this.eventToEdit.recurrence.exdates.map((date) => {
            return new Date(date);
          });
        } else {
          exdates = this.eventToEdit.recurrence.exdates;
        }
      }
    } else {
      const jsStart = startOfDay(new Date(timing?.date));

      start = new Date(
        Date.UTC(
          jsStart.getUTCFullYear(),
          jsStart.getUTCMonth(),
          jsStart.getUTCDate(),
          jsStart.getUTCHours(),
          jsStart.getUTCMinutes(),
        ),
      );
    }

    let utcEnd;
    if (timing.recurrenceRecipe?.until) {
      if (timing.recurrenceRecipe?.until instanceof Date) {
        utcEnd = timing.recurrenceRecipe?.until;
      } else {
        const date = new Date(timing.recurrenceRecipe?.until);
        utcEnd = zonedTimeToUtc(
          endOfDay(new Date(date)),
          this.siteService.currentSite.timezone,
        );
      }
    }
    let byweekday =
      typeof timing?.recurrenceRecipe?.byweekday === 'string'
        ? [Number(timing?.recurrenceRecipe?.byweekday)]
        : timing?.recurrenceRecipe?.byweekday;

    if (
      timing.recurrenceRecipe.freq === RRule.MONTHLY &&
      byweekday?.length > 0
    ) {
      if (typeof byweekday[0] === 'string') {
        byweekday = byweekday.map((value) => {
          const weekday = this.recurrenceService.getWeekdayFromString(value);
          return weekday.nth(timing.recurrenceRecipe.nbyweekday);
        });
      } else {
        byweekday = byweekday.map((value) => {
          return value.nth(timing.recurrenceRecipe.nbyweekday);
        });
      }
    }
    return {
      ...timing.recurrenceRecipe,
      byweekday: byweekday ? byweekday : null,
      dtstart: start,
      until: format(utcEnd, 'yyyy-MM-dd'),
      tzid: this.siteService.currentSite.timezone,
      exdates: exdates ? exdates : null,
    };
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
  }

  public formToAnnouncement(value, date): CreateAnnouncementPayload {
    let startShowing: Date;
    const timezone = this.siteService.currentSite.timezone;

    // If it's an all-day event, set start and end accordingly
    const startTime = value.eventStart ? value.eventStart : '00:00';
    if (typeof date !== 'string') {
      date = format(date, 'yyyy-MM-dd');
    }

    const eventStartLocal = parse(
      `${date} ${startTime}`,
      'yyyy-MM-dd HH:mm',
      new Date(),
    );

    const eventStart = zonedTimeToUtc(eventStartLocal, timezone);

    const endTime = value.eventEnd ? value.eventEnd : '23:59';
    const eventEndLocal = parse(
      `${date} ${endTime}`,
      'yyyy-MM-dd HH:mm',
      new Date(),
    );
    let eventEnd: Date;

    // Need to check if event end should be the subsequent day (e.g. an event running from 11:30PM to 12:30AM)
    // If the eventEnd is earlier than eventStart (e.g. 12:30AM will come through as "00:30" and 11:30PM as "11:30")
    // then assume the event is set to end the following day.

    if (eventEndLocal < eventStartLocal) {
      eventEnd = zonedTimeToUtc(addDays(eventEndLocal, 1), timezone);
    } else {
      eventEnd = zonedTimeToUtc(eventEndLocal, timezone);
    }

    const stopShowing = eventEnd;
    if (EventType[value.eventType] === EventType.SpecialEvent) {
      startShowing = subDays(eventStart, 1);
    } else {
      startShowing = subDays(eventStart, 1);
    }
    return {
      displayDuration: value.displayDuration,
      isSpecialEvent: EventType[value.eventType] === EventType.SpecialEvent,
      imageId: value.image && value.image.id ? value.image.id : undefined,
      startShowing: startShowing.toISOString(),
      stopShowing: stopShowing.toISOString(),
      roomId: +value.roomId || null,
      location: value.location,
      eventStart: eventStart.toISOString(),
      eventEnd: eventEnd.toISOString(),
      siteId: this.siteService.currentSiteId,
      styleId: 3,
      title: value.title.trim(),
      content: value.content,
      backgroundId: value.background.id,
      eventTags: value.eventTags.map((tag) => tag.id),
      eventType: value.eventType ? value.eventType : defaultEventKey,
      categoryId:
        value.category && value.category.id ? value.category.id : undefined,
      subcategoryId:
        value.subcategory && value.subcategory.id
          ? value.subcategory.id
          : undefined,
      videoUrl: value.videoUrl,
      recurrenceId: value.recurrenceId || null,
      allDay: value.allDay ? value.allDay : false,
      showOnPrintCalendar: value.showOnPrintCalendar,
      showOnDigitalSignage: value.showOnDigitalSignage,
      secondaryImages: value.secondaryImages || [],
      positionImageAboveWeatherBar: value.positionImageAboveWeatherBar,
    };
  }

  public saveBatch(batch: BatchEvents) {
    const events = [];
    batch.dates.forEach((date: Date) => {
      // convert form to announcement and push to events
      events.push(this.formToAnnouncement(batch.form, date));
    });
    // save all of the events
    return this.manageService.saveBulkAnnouncements(events);
  }

  public async saveEvent($event) {
    const activity = this.data.event;
    if (activity?.recurrence) {
      const form = {
        ...this.eventForm.value.details,
        ...this.eventForm.value.timing,
        title: this.eventForm.value.details.title.trim(),
      };
      const event = this.formToAnnouncement(form, form.date);

      const formattedEventStart = zonedTimeToUtc(
        new Date(event.eventStart),
        this.data.event.timezone,
      ).toString();
      let recurrence;
      if (this.eventForm.value.timing.recurrenceRecipe) {
        recurrence = this.recurrenceService.finalizeRecurrenceDetailsToSave(
          { ...this.eventForm.value.timing },
          this.eventToEdit.recurrence.exdates,
        );
      }

      const confirmData: EventEditDialogValues = {
        title: `Save "${event.title}"?`,
        message: 'This is a recurring event.',
        yesText: 'Save',
        onError: (error) => {
          this.alertService.error(
            `Error updating "${event.title}". Please try again.`,
          );
        },
        onSuccess: async () => {
          await this.activityService.refreshActivities(
            utcToZonedTime(
              this.selectedMonth,
              this.siteService.currentSite.timezone,
            ),
            false,
          );

          this.alertService.success(`"${activity.title}" updated.`);
          // Add a small delay before closing the dialog to ensure the calendar view is updated
          setTimeout(() => {
            this.dialogRef.close();
          }, 300);
        },
        updatedActivity: event,
        existingActivity: activity,
        startDateUpdated: this.startDateUpdated,
        timingUpdated: this.eventTimeUpdated,
        saveBatch: (batch) => this.saveBatch(batch),
        form: this.eventForm.value,
        updatedRecurrence: recurrence,
      };

      const dialogRef = this.dialog.open(EditRecurrenceDialogComponent, {
        width: '415px',
        data: confirmData,
      });
    } else {
      const form = {
        ...this.eventForm.value.details,
        ...this.eventForm.value.timing,
        title: this.eventForm.value.details.title.trim(),
      };
      try {
        this.isSaving.next(true);
        const event = this.formToAnnouncement({ ...form }, form.date);
        if (this.eventForm.value.timing.repeating) {
          // if activity already exists, was not a repeating event before but is now, delete the original event and replace.
          if (activity) {
            await this.manageService.deleteAnnouncement(activity);
          }
          // gets all of the instances for the event based on start and end time
          const batch = await this.recurrenceService.getRecurringBatchFromForm({
            eventForm: this.eventForm.value,
            timezone: this.siteService.currentSite.timezone,
          });

          this.isSaving.next(true);
          // save all of the events in the batch
          await this.saveBatch(batch);
          await this.activityService.refreshActivities(
            utcToZonedTime(
              this.selectedMonth,
              this.siteService.currentSite.timezone,
            ),
            false,
          );
        } else {
          // event is not repeating, save as normal
          if (this.eventToEdit) {
            await this.manageService.updateAnnouncement(
              event,
              this.eventToEdit.id,
            );
          } else {
            await this.manageService.saveAnnouncement(event);
          }
        }

        await this.activityService.refreshActivities(
          utcToZonedTime(
            this.selectedMonth,
            this.siteService.currentSite.timezone,
          ),
          false,
        );
        this.isSaving.next(false);

        // Add a small delay before closing the dialog to ensure the calendar view is updated
        setTimeout(() => {
          this.dialogRef.close();
          this.alertService.success(`${event.title} saved!`);
        }, 300);
      } catch (error) {
        console.error('error saving event', error);
        this.alertService.error(`Not saved`);
        this.isSaving.next(false);
      }
    }
  }

  public deleteEvent(): void {
    const activity = this.data.event;

    if (!activity.recurrence) {
      const confirmData: ConfirmationDialogValues = {
        title: `Delete "${activity.title}"?`,
        message: 'This action can not be undone.',
        yesText: 'Delete',
        onConfirm: async () => {
          await this.manageService.deleteAnnouncement(activity);
          this.closeModal();
        },
        onError: (error) => {
          this.alertService.error(
            `Error deleting "${activity.title}". Please try again.`,
          );
        },
      };
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: confirmData,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.activityService.refreshActivities(
            utcToZonedTime(
              this.data.selectedMonth,
              this.siteService.currentSite.timezone,
            ),
            false,
          );
          this.alertService.success(`"${activity.title}" deleted.`);
        }
      });
    } else {
      const confirmData: EventDeleteDialogValues = {
        title: `Delete "${activity.title}"?`,
        message: 'This is a recurring event.',
        yesText: 'Delete',
        onError: (error) => {
          this.alertService.error(
            `Error deleting "${activity.title}". Please try again.`,
          );
        },
        onSuccess: async () => {
          await this.activityService.refreshActivities(
            utcToZonedTime(
              this.data.selectedMonth,
              this.siteService.currentSite.timezone,
            ),
            false,
          ),
            this.closeModal();
        },
        activity,
      };
      const dialogRef = this.dialog.open(DeleteRecurrenceDialogComponent, {
        data: confirmData,
      });
    }
  }

  public closeModal() {
    this.dialogRef.close();
  }
}
