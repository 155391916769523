import {
  Component,
  OnInit,
  Inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Announcement } from '@models/announcement';
import { BehaviorSubject } from 'rxjs';
import { ManageService } from 'src/app/core/services/manage.service';
import { RecurrenceService } from 'src/app/core/services/recurrence.service';
import { Recurrence } from '@models/recurrence';
import { EventFormValues } from '../../../manage/activities/event-form-modal-component/event-form-modal.component';
import { RecurrenceDto } from '@interfaces/recurrence';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { CreateAnnouncementPayload } from 'src/app/core/interfaces/api';

export interface EventEditDialogValues {
  message?: string;
  title?: string;
  noText?: string;
  yesText?: string;
  onError?: Function;
  onSuccess?: Function;
  existingActivity: Announcement;
  updatedActivity: any;
  startDateUpdated?: Boolean;
  timingUpdated?: Boolean;
  saveBatch: Function;
  form: EventFormValues;
  updatedRecurrence: RecurrenceDto;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-edit-recurrence-dialog',
  templateUrl: './edit-recurrence-dialog.component.html',
  styleUrls: ['./edit-recurrence-dialog.component.scss'],
})
export class EditRecurrenceDialogComponent implements OnInit {
  public isLoading = new BehaviorSubject<boolean>(false);
  public eventEditForm: UntypedFormGroup;
  public updatingRecurrence = null;
  public noRecurrence = null;

  constructor(
    public dialogRef: MatDialogRef<EditRecurrenceDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private manageService: ManageService,
    private recurrenceService: RecurrenceService,

    @Inject(MAT_DIALOG_DATA) public data: EventEditDialogValues,
  ) {
    if (!data.updatedRecurrence) {
      this.noRecurrence = true;
    }
    if (
      data.startDateUpdated ||
      !this.recurrenceService.testForRecurrenceEquality(
        data.existingActivity.recurrence,
        {
          ...data.updatedRecurrence,
          dtstart: data.existingActivity.recurrence.dtstart,
        },
      )
    ) {
      this.updatingRecurrence = true;
    }
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public onConfirmClick = async ($event) => {
    this.isLoading.next(true);
    try {
      await this.handleSave();
    } catch (error) {
      // check for callback for error handling
      if (this.data.onError) {
        this.data.onError();
        this.isLoading.next(false);
        return;
      } else {
        console.error(error);
      }
    }

    this.dialogRef.close($event);
  };

  public updateRecurrence(rule) {}

  public async handleSave() {
    if (this.eventEditForm.controls['edit'].value === 'single') {
      try {
        /**
         * Editing one instance of this announcement, not all in the series:
         * update the event recurrence (at id) with this new exception, update this announcement
         */

        const startFromISO = format(
          zonedTimeToUtc(
            new Date(this.data.existingActivity.eventStart),
            this.data.existingActivity.site.timezone,
          ),
          'yyyy-MM-dd',
        );

        let exdates: string[] = [];
        if (this.data.existingActivity.recurrence.exdates) {
          exdates.push(...this.data.existingActivity.recurrence.exdates);
          exdates.push(startFromISO);
        } else {
          exdates = [startFromISO];
        }

        // Update existing recurrence
        const recurrence = await this.recurrenceService.saveRecurrence({
          ...this.data.existingActivity.recurrence,
          exdates,
        });

        // Take the selected activity, update values, null out recurrence
        const exception: any = {
          ...this.data.existingActivity,
          ...this.data.updatedActivity,
          recurrence: null,
        };
        // since we are updating we probably don't need the whole payload, but leaving here for now
        const recurringException: CreateAnnouncementPayload = {
          title: exception.title, // Title from the exception object
          content: exception.content, // Content from the exception object
          startShowing: exception.startShowing, // Start showing date
          stopShowing: exception.stopShowing, // Stop showing date
          location: exception.location, // Location from the exception object
          displayDuration: exception.displayDuration, // Display duration
          eventStart: exception.eventStart, // Event start date
          eventEnd: exception.eventEnd, // Event end date
          isSpecialEvent: exception.isSpecialEvent, // Special event flag
          videoUrl: exception.videoUrl, // Video URL
          eventType: exception.eventType, // Event type
          allDay: exception.allDay, // All day event flag
          siteId: this.data.existingActivity.siteId, // Site ID from existing activity
          styleId: exception.styleId, // Style ID
          imageId: exception.imageId, // Image ID
          backgroundId: exception.backgroundId, // Background ID
          roomId: exception.roomId ? parseInt(exception.roomId) : null, // Room ID
          categoryId: exception.categoryId, // Category ID
          subcategoryId: exception.subcategoryId, // Subcategory ID
          showOnPrintCalendar: exception.showOnPrintCalendar, // Print calendar visibility
          showOnDigitalSignage: exception.showOnDigitalSignage, // Digital signage visibility
          eventTags: exception.eventTags, // Event tags
          recurrenceId: null,
          secondaryImages: exception.secondaryImages?.map((image) => image.id),
          positionImageAboveWeatherBar: exception.positionImageAboveWeatherBar,
        };

        const response = await this.manageService.updateAnnouncement(
          recurringException,
          this.data.existingActivity.id,
        );
        this.isLoading.next(false);
        if (this.data.onSuccess) {
          this.data.onSuccess();
        }
      } catch (error) {
        console.error('error', error);
      }
    } else if (this.eventEditForm.controls['edit'].value === 'all') {
      try {
        /**
         * Editing all instances of this recurring announcement
         */

        // get all events with this recurring ID
        const announcements =
          await this.recurrenceService.getRecurringAnnouncementsByAnnouncement(
            this.data.existingActivity,
          );
        // // update events with new details
        if (this.updatingRecurrence || this.data.timingUpdated) {
          const recurrence = await this.recurrenceService.saveRecurrence({
            ...this.data.updatedRecurrence,
            id: this.data.existingActivity.recurrence.id,
            dtstart: this.data.startDateUpdated
              ? this.data.form.timing.date
              : this.data.existingActivity.recurrence.dtstart,
            until:
              (this.data.form.timing.recurrenceRecipe as any).stopCondition ===
              'never'
                ? null
                : // if it's a date, format correctly in yyyy-MM-dd, otherwise it should be formatted correctly.
                this.data.form.timing.recurrenceRecipe.until instanceof Date
                ? format(
                    this.data.form.timing.recurrenceRecipe.until,
                    'yyyy-MM-dd',
                  )
                : this.data.form.timing.recurrenceRecipe.until,
          });
          // delete all announcements from previous recurrence behavior
          await this.manageService.deleteBulkAnnouncements(announcements);
          const recurrenceInstance = new Recurrence({
            ...this.data.updatedRecurrence,
            exdates: this.data.startDateUpdated
              ? null
              : this.data.existingActivity.recurrence.exdates,
            dtstart: this.data.startDateUpdated
              ? this.data.form.timing.date
              : this.data.existingActivity.recurrence.dtstart,
            tzid: this.data.existingActivity.site.timezone,
          });

          // generate new announcements for new recurrence behavior
          const announcementBatch =
            await this.recurrenceService.getRecurringBatchFromForm({
              eventForm: {
                timing: {
                  ...this.data.form.timing,
                  // @ts-ignore
                  date: this.data.startDateUpdated
                    ? this.data.form.timing.date
                    : this.data.existingActivity.recurrence.dtstart, // if updating all, maintain original start date
                  // @ts-ignore
                  recurrenceRecipe: recurrenceInstance.recipeForm,
                },
                details: this.data.form.details,
              },
              recurrenceId: recurrence.id,
              exdatesToMerge: this.data.startDateUpdated
                ? null
                : this.data.existingActivity.recurrence.exdates,
              timezone: this.data.existingActivity.site.timezone,
            });
          await this.data.saveBatch({ ...announcementBatch });
        } else {
          const updatedEvents: Array<
            CreateAnnouncementPayload & { id?: number }
          > = [];
          // can't update all things, many things should stay the same
          announcements.forEach((announcement: Announcement) => {
            updatedEvents.push(
              // Start Generation Here
              {
                title: this.data.updatedActivity?.title,
                content: this.data.updatedActivity?.content,
                eventType: this.data.updatedActivity?.eventType,
                location: this.data.updatedActivity?.location,
                eventTags: this.data.updatedActivity?.eventTags,
                videoUrl: this.data.updatedActivity?.videoUrl,
                startShowing: announcement.startShowing as unknown as string,
                stopShowing: announcement.stopShowing as unknown as string,
                displayDuration: announcement.displayDuration,
                eventStart: announcement.eventStart as unknown as string,
                eventEnd: announcement.eventEnd as unknown as string,
                isSpecialEvent: announcement.isSpecialEvent,
                allDay: announcement.allDay,
                siteId: announcement.siteId,
                styleId: announcement.styleId,
                imageId: announcement.imageId,
                backgroundId: announcement.backgroundId,
                roomId: +this.data.updatedActivity.roomId,
                categoryId: announcement.categoryId,
                subcategoryId: announcement.subcategoryId,
                showOnPrintCalendar:
                  this.data.updatedActivity?.showOnPrintCalendar,
                showOnDigitalSignage:
                  this.data.updatedActivity?.showOnDigitalSignage,
                recurrenceId: announcement.recurrence?.id,
                id: announcement.id,
                secondaryImages: announcement.secondaryImages?.map(
                  (image) => image.id,
                ),
                positionImageAboveWeatherBar:
                  announcement.positionImageAboveWeatherBar,
              },
            );
          });
          // // bulk update
          await this.manageService.updateBulkAnnouncements(updatedEvents);
        }
        this.isLoading.next(false);
        if (this.data.onSuccess) {
          this.data.onSuccess();
        }
      } catch (error) {
        console.error('error', error);
      }
    } else if (this.eventEditForm.controls['edit'].value === 'future') {
      try {
        // get all events with this recurring ID
        const originalRecurringAnnouncements =
          await this.recurrenceService.getRecurringAnnouncementsByAnnouncement(
            this.data.existingActivity,
          );

        // update original recurrence with new cut-off date
        await this.recurrenceService.updateUntilFromActivity(
          this.data.existingActivity,
        );

        await this.recurrenceService.deleteThisAndFutureRecurringAnnouncements(
          this.data.existingActivity,
        );

        // add new recurrence for this and future events
        const newRecurrence =
          await this.recurrenceService.saveNewRecurrenceFromActivity(
            {
              ...this.data.updatedActivity,
              recurrence: this.data.updatedRecurrence,
            },
            this.data.existingActivity.recurrence.exdates,
          );
        const recurrenceInstance = new Recurrence({
          ...newRecurrence,
          tzid: this.data.existingActivity.site.timezone,
        });
        const announcementBatch =
          await this.recurrenceService.getRecurringBatchFromForm({
            eventForm: {
              timing: {
                ...this.data.form.timing,
                // @ts-ignore
                recurrenceRecipe: recurrenceInstance.recipeForm,
              },
              details: this.data.form.details,
            },
            recurrenceId: newRecurrence.id,
            exdatesToMerge: this.data.existingActivity.recurrence.exdates,
            timezone: this.data.existingActivity.site.timezone,
          });
        // // update events with new details
        await this.data.saveBatch(announcementBatch);

        this.isLoading.next(false);
        if (this.data.onSuccess) {
          this.data.onSuccess();
        }
      } catch (error) {
        console.error('error:', error);
      }
    }
  }

  ngOnInit() {
    this.eventEditForm = this.formBuilder.group({
      edit: ['single', Validators.required],
    });
  }
}
