import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

import { Room } from '../../../../../core/models/room';
import { Calendar } from '../../../../../core/models/calendar';
import { Event } from '../../../../../core/interfaces/event';
import { SiteService } from './site.service';
import { HolidayService, HolidayInfo } from './holiday.service';
import { EventType } from '../../../../../core/enums/event-type';

import { CALENDAR_COLORS } from '../../core/constants/calendar-colors';
import { CalendarConfig } from '../interfaces/calendarConfiguration';
import {
  CalendarEvent,
  CreateCalendarDto,
} from '../../../../../core/interfaces/calendar';

import {
  CalendarDayConfig,
  CalendarDayEventsConfig,
} from '../interfaces/dayConfiguration';
import {
  pdfTableBody,
  pdfTableContent,
  pdfTableCell,
  pdfLayout,
  pdfStack,
} from '../interfaces/pdfMake/pdfTables';

import { Announcement } from '@models/announcement'; // to support CMS event calendar UI warnings
import { lastValueFrom } from 'rxjs';
import {
  endOfMonth,
  endOfWeek,
  getDaysInMonth,
  parseISO,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
export interface CalendarFormat {
  rowsRequired: number;
  legendAtEndOfMonth: boolean;
  disclaimerAtEndOfMonth: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  private calendarEvents: { [day: number]: Array<Event> } = {};
  public maxEventsPerDayIn5RowMonth = 9;
  public maxEventsPerDayIn6RowMonth = 7;
  public legendDaysNeeded = 3;

  // PDFmake adds undisclosed padding to table cells. We often reset top/bottom, left/right can be configured following this pattern.
  public tableLayoutPaddingReset: Partial<pdfLayout> = {
    paddingTop: function (i, node) {
      return 0;
    },
    paddingBottom: function (i, node) {
      return 0;
    },
  };

  public marginForVerticalCentering = {
    1: 'oneLineMargin',
    2: 'twoLineMargin',
    3: 'threeLineMargin',
    4: 'fourLineMargin',
    5: 'fiveLineMargin',
  };

  public smallMarginForVerticalCentering = {
    1: 'oneLineMarginSM',
    2: 'twoLineMarginSM',
    3: 'threeLineMarginSM',
    4: 'fourLineMarginSM',
    5: 'fiveLineMarginSM',
  };

  constructor(
    private http: HttpClient,
    private holidayService: HolidayService,
    private siteService: SiteService,
  ) {}

  /**
   * Returns the max number of events per day in that month,
   * as determined by what will fit in the print calendar
   *
   * @param dayInMonth dateTime representing any day in the month
   */
  public async getMaxEventsPerDayInMonth(dayInMonth: Date): Promise<number> {
    const rowsThisMonth = await this.evaluateCalendarFormatForMonth(dayInMonth);
    return rowsThisMonth.rowsRequired === 5
      ? this.maxEventsPerDayIn5RowMonth
      : this.maxEventsPerDayIn6RowMonth;
  }

  private containsSymbolCharacter(text: string): boolean {
    if (text.includes('®') || text.includes('©') || text.includes('™')) {
      return true;
    }
    return false;
  }

  private styleSymbols(
    event: Event | Announcement,
  ): Array<{ text: string; sup?: boolean }> {
    const label = event.title;
    let splitString = [label];
    if (label.includes('®')) {
      splitString = splitString.flatMap((item) => item.split(/(®)/g));
    }
    if (label.includes('©')) {
      splitString = splitString.flatMap((item) => item.split(/(©)/g));
    }
    if (label.includes('™')) {
      splitString = splitString.flatMap((item) => item.split(/(™)/g));
    }
    const charCollection = splitString
      .filter((label) => label.length !== 0)
      .map((label) => {
        if (this.containsSymbolCharacter(label)) {
          return {
            text: label,
            sup: true,
          };
        } else {
          return {
            text: label,
          };
        }
      });
    // @ts-ignore
    const room = event.room || event.locationShortName;
    if (room) {
      if (room.shortName && room.shortName !== ' - ') {
        charCollection.push({
          text: `, ${room.shortName}`,
        });
      } else if (!room.shortName && room !== ' - ') {
        charCollection.push({
          text: `, ${room}`,
        });
      }
    }
    return charCollection;
  }

  // given an event (or announcement) and a character limit, determine how many rows it should take
  // optionally: return a text collection with superscript styles
  public getCalendarEventListing(
    item: Announcement | Event | CalendarEvent,
    maxNumCharacter: number,
    getCharCollection?: boolean,
  ): {
    label: string;
    numLinesPerItem: number;
    charCollection: Array<{ text: string; sup?: boolean }>;
  } {
    let itemTitle;
    let charCollection = [];

    //@ts-ignore
    if (item.room) {
      const announcement = item as Announcement;

      if (
        getCharCollection &&
        this.containsSymbolCharacter(announcement.title)
      ) {
        charCollection = this.styleSymbols(announcement);
      }
      itemTitle = `${announcement.title}${
        announcement.room.shortName && announcement.room.shortName !== ' - '
          ? ', ' + announcement.room.shortName
          : ''
      }`;
    } else {
      let event;
      //@ts-ignore
      if (item?.original) {
        event = item as CalendarEvent;

        if (getCharCollection && this.containsSymbolCharacter(event.title)) {
          charCollection = this.styleSymbols(event.src);
        }
        if (event.locationShortName) {
          itemTitle = `${event.title}${
            event.locationShortName && event.locationShortName !== ' - '
              ? ', ' + event.locationShortName
              : ''
          }`;
        } else {
          itemTitle = `${event.title}${
            event.original?.room?.short && event.original?.room?.short !== ' - '
              ? ', ' + event.original.room.short
              : ''
          }`;
        }

        //@ts-ignore
      } else if (item?.fullEvent) {
        event = item as Event;

        if (getCharCollection && this.containsSymbolCharacter(event.title)) {
          charCollection = this.styleSymbols(event.fullEvent);
        }

        if (event.locationShortName) {
          itemTitle = `${event.title}${
            event.locationShortName && event.locationShortName !== ' - '
              ? ', ' + event.locationShortName
              : ''
          }`;
        } else {
          itemTitle = `${event.title}${
            event.fullEvent?.room?.shortName &&
            event.fullEvent?.room?.shortName !== ' - '
              ? ', ' + event.fullEvent.room.shortName
              : ''
          }`;
        }
      }
    }

    let numLinesPerItem = this.evaluateTextWrapOverflow(
      itemTitle.length / maxNumCharacter,
    );

    if (itemTitle.length > maxNumCharacter) {
      const titleChunk = itemTitle.match(
        new RegExp(
          `\\b[\\w+\\s]{${
            maxNumCharacter - 4
          },${maxNumCharacter}}.?(?=\\s)|.+$`,
          'g',
        ),
      );
      const titleChunkNoWhitespace = titleChunk.map((line) => line.trim());
      itemTitle = titleChunkNoWhitespace.join('\n');
    }

    return { label: itemTitle, numLinesPerItem, charCollection };
  }

  /**
   * Given an array of events (or announcements), character and row restrictions, determine
   * which items will make it to the calendar or not.
   */
  public getCalendarEventsIncludedExcluded(
    events: Array<Announcement | Event>,
    maxNumCharacter,
    maxNumRows,
  ) {
    let eventLists = {
      included: [],
      excluded: [],
    };
    let linesPerItem = [];
    let tempCount = maxNumRows;
    events.forEach((event) => {
      const details = this.getCalendarEventListing(event, maxNumCharacter);
      let counter = tempCount - details.numLinesPerItem;

      if (counter >= 0) {
        eventLists.included.push(event);
        tempCount = counter;
      } else {
        eventLists.excluded.push(event);
        tempCount = counter;
      }
      linesPerItem.push(details.numLinesPerItem);
    });

    return { sorted: eventLists, linesPerItem };
  }

  public evaluateTextWrapOverflow(numLinesPerEvent): number {
    if (numLinesPerEvent < 1) {
      // all items must take at least one line
      numLinesPerEvent = 1;
    } else {
      numLinesPerEvent = Math.ceil(numLinesPerEvent);
    }

    return numLinesPerEvent;
  }

  /**
   * Returns a CalendarFormat interface object, describing
   * the number of rows this month's calendar will have
   * and whether the legend and disclaimer text will be
   * displayed at the beginning or end of the calendar
   *
   * @param month - number
   * @param year - number
   */
  public async evaluateCalendarFormatForMonth(
    date: Date,
  ): Promise<CalendarFormat> {
    const daysInMonth = getDaysInMonth(date);
    const startDow = startOfWeek(date).getDay();
    const endDow = endOfWeek(date).getDay();
    const daysBefore = startDow;
    const daysAfter = 7 - endDow - 1;
    let trailingDaysNeeded = 4;
    this.legendDaysNeeded = 3;
    const disclaimerDaysNeeded = 1;
    const totalDays = daysBefore + daysInMonth + daysAfter;
    let rowsRequired: number;
    let legendAtEndOfMonth: boolean;
    let disclaimerAtEndOfMonth: boolean;
    if (totalDays > 7 * 5) {
      rowsRequired = 6;
    } else {
      rowsRequired = 5;
    }

    /**
     * VERY specific scenario: 31 day months that start on a Tuesday have only 2 free squares at the start and end.
     * If there are not many room names in use, we can reduce the size of the legend to a width of 2 cols to avoid
     * adding an additional row to the month (which would mean fewer events fitting on the calendar)
     * [SC-3685](https://cloudburst.atlassian.net/browse/SC-3685)
     **/
    if (
      trailingDaysNeeded > daysAfter &&
      trailingDaysNeeded > daysBefore &&
      this.legendDaysNeeded > daysBefore &&
      this.legendDaysNeeded > daysAfter
    ) {
      const firstOfMonth: Date = startOfMonth(date);
      const lastOfMonth: Date = endOfMonth(date);

      this.calendarEvents = await this.getEventsForCalendar(
        this.siteService.currentSiteId,
        firstOfMonth.toISOString(),
        lastOfMonth.toISOString(),
      );

      let filteredRoomIds = [];
      let activeRooms = [];

      // Determine which rooms are in active use for this month of events
      Object.keys(this.calendarEvents).forEach((day) => {
        this.calendarEvents[day].filter((events) => {
          if (filteredRoomIds.includes(events?.fullEvent?.roomId)) {
            return;
          } else if (events?.fullEvent?.roomId) {
            filteredRoomIds.push(events?.fullEvent?.roomId);
            activeRooms.push(events?.fullEvent?.room);
          }
        });
      });
      /**
       * TODO: MAGIC NUMBER SEVEN.
       * Seven rooms fit in one column of the legend in a 5 row month at this font size and paper size.
       * At the time of writing, we never want less than two columns or more than three.
       * May be necessary to determine how many columns are needed more dynamically later on.
       * **/
      this.legendDaysNeeded = Math.ceil(activeRooms.length / 7) <= 2 ? 2 : 3;

      trailingDaysNeeded = this.legendDaysNeeded + disclaimerDaysNeeded;
    }

    if (trailingDaysNeeded <= daysAfter) {
      legendAtEndOfMonth = true;
      disclaimerAtEndOfMonth = true;
    } else if (trailingDaysNeeded <= daysBefore) {
      legendAtEndOfMonth = false;
      disclaimerAtEndOfMonth = false;
    } else if (
      this.legendDaysNeeded <= daysAfter &&
      disclaimerDaysNeeded <= daysBefore
    ) {
      legendAtEndOfMonth = true;
      disclaimerAtEndOfMonth = false;
    } else if (
      this.legendDaysNeeded <= daysBefore &&
      disclaimerDaysNeeded <= daysAfter
    ) {
      legendAtEndOfMonth = false;
      disclaimerAtEndOfMonth = true;
    } else {
      /**
       * this condition should ONLY happen when:
       * there are less than 3 squares of available space at the start and end of the month
       * AND there are 3 columns worth of active room names to display in the legend
       **/
      rowsRequired = 6;
      legendAtEndOfMonth = true;
      disclaimerAtEndOfMonth = true;
    }
    return { rowsRequired, legendAtEndOfMonth, disclaimerAtEndOfMonth };
  }

  private getEventsForCalendar(
    siteId: number,
    startDate: string,
    endDate: string,
  ): Promise<{ [day: number]: Array<Event> }> {
    const queryParams = new URLSearchParams({
      startDate: new Date(startDate).getTime().toString(),
      endDate: new Date(endDate).getTime().toString(),
    });

    const url = environment.apiv3Url.concat(
      '/calendars/print/',
      siteId.toString(),
      '?',
      queryParams.toString(),
    );

    try {
      return lastValueFrom(this.http.get<{ [day: number]: Array<Event> }>(url));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  private buildCalendarDay(
    calendarDayConfig: CalendarDayConfig,
  ): Array<pdfTableContent> | pdfTableContent {
    const {
      day,
      holidays,
      calRows,
      timezone,
      numberOfEventsPerDay,
      numLinesPerDay,
      maxNumCharacter,
      templateMode,
      fontOverride,
      eventGridHeight,
      verticallyCentered,
    } = calendarDayConfig;

    if (this.calendarEvents[calendarDayConfig.day]) {
      // If there are events, list them on the day
      return this.buildDayWithEvents({
        day,
        events: this.calendarEvents[day],
        holidays,
        calRows,
        timezone,
        numberOfEventsPerDay,
        numLinesPerDay,
        maxNumCharacter,
        templateMode,
        fontOverride,
        verticallyCentered,
        eventGridHeight,
      });
    } else if (holidays) {
      return this.buildDayWithHolidays({
        day,
        holidays,
        eventBody: null,
        verticallyCentered,
        eventGridHeight,
        templateMode,
      });
    } else {
      // Else just the number
      return this.buildDayWithoutHoliday({
        day,
        eventBody: null,
        verticallyCentered,
        eventGridHeight,
        templateMode,
      });
    }
  }

  private buildDayWithoutHoliday(config: {
    day: number;
    eventBody;
    verticallyCentered?: boolean;
    eventGridHeight?: number;
    templateMode?: boolean;
  }): pdfTableContent {
    const {
      day,
      eventBody,
      verticallyCentered,
      eventGridHeight,
      templateMode,
    } = config;
    const nestedTableBody = [
      {
        text: day,
        style: 'day',
      },
    ];
    const nestedHeadingTable = {
      table: {
        headerows: 0,
        body: [nestedTableBody],
        widths: ['*'],
      },
      layout: {
        hLineWidth: (i, node) => {
          return 0;
        },
        vLineWidth: (i, node) => {
          return 0;
        },
        defaultBorder: templateMode ? true : false,
      },
      style: 'nestedTable',
    };

    return {
      table: {
        body: [[nestedHeadingTable], [eventBody]],
        widths: ['*'],
      },
      layout: {
        ...this.tableLayoutPaddingReset,
        paddingLeft: function (i, node) {
          return 0;
        },
        paddingRight: function (i, node) {
          return 0;
        },
        hLineWidth: (i, node) => {
          return 0;
        },
        vLineWidth: (i, node) => {
          return 0;
        },
        defaultBorder: templateMode ? true : false,
      },
    };
  }

  private buildDayWithHolidays(config: {
    day: number;
    eventBody;
    holidays: Array<HolidayInfo>;
    verticallyCentered?: boolean;
    eventGridHeight?: number;
    templateMode?: boolean;
  }): pdfTableContent | Array<pdfTableContent> {
    const {
      day,
      holidays,
      eventBody,
      verticallyCentered,
      eventGridHeight,
      templateMode,
    } = config;
    if (holidays.length === 0) {
      return this.buildDayWithoutHoliday({ day, eventBody, templateMode });
    }
    const firstHoliday = holidays[0]; // Ignores all but the first holiday
    const nestedTableBody = [
      {
        text: firstHoliday.name.toUpperCase(),
        style: 'holiday',
        noWrap: false,
      },
      {
        text: day,
        style: 'day',
        noWrap: true,
        layout: {
          ...this.tableLayoutPaddingReset,
          paddingBottom: function (i, node) {
            return 0;
          },
          paddingLeft: function (i, node) {
            return 0;
          },
          paddingTop: function (i, node) {
            return 0;
          },
          paddingRight: function (i, node) {
            return 0;
          },
          hLineWidth: (i, node) => {
            return 0;
          },
          vLineWidth: (i, node) => {
            return 0;
          },
        },
      },
    ];

    const nestedHeadingTable = {
      table: {
        headerows: 0,
        body: [nestedTableBody],
        widths: ['*', 'auto'],
      },
      layout: {
        ...this.tableLayoutPaddingReset,
        paddingBottom: function (i, node) {
          return 0;
        },
        paddingLeft: function (i, node) {
          return 2 * 0.75;
        }, // padding left of holiday
        paddingTop: function (i, node) {
          return 2 * 0.75;
        }, // padding top of holiday
        paddingRight: function (i, node) {
          return 0;
        }, // padding right of day
        hLineWidth: (i, node) => {
          return 0;
        },
        vLineWidth: (i, node) => {
          return 0;
        },
        defaultBorder: templateMode ? true : false,
      },
      style: 'nestedTable',
    };

    return {
      table: {
        body: [[nestedHeadingTable], [eventBody]],
        widths: ['*'],
      },
      layout: {
        ...this.tableLayoutPaddingReset,
        paddingLeft: function (i, node) {
          return 0;
        },
        paddingRight: function (i, node) {
          return 0;
        },
        hLineWidth: (i, node) => {
          return 0;
        },
        vLineWidth: (i, node) => {
          return 0;
        },
        defaultBorder: templateMode ? true : false,
      },
    };
  }

  private buildDayWithEvents(
    calendarDayEventsConfig: CalendarDayEventsConfig,
  ): Array<pdfTableContent> | pdfTableContent {
    const {
      day,
      events,
      holidays,
      calRows,
      timezone,
      numLinesPerDay,
      maxNumCharacter,
      templateMode,
      fontOverride,
      verticallyCentered,
      eventGridHeight,
    } = calendarDayEventsConfig;

    return this.buildDayWithHolidays({
      day,
      holidays,
      eventBody: this.formatDay({
        day,
        events,
        calRows,
        maxNumCharacter,
        numLinesPerDay: numLinesPerDay,
        timezone,
        numberOfEventsPerDay: numLinesPerDay?.regular,
        templateMode,
        verticallyCentered,
        eventGridHeight,
      }),
      templateMode,
    });
  }

  /**
   * Returns nested table body containing events for the day and their times
   * Limits number of events to monthly max per day, depending on number of rows in month
   * Truncates event names to fit on one line and does not allow wrapping
   * @param day
   * @param events
   * @param calRows
   */
  private formatDay(calendarDayEventsConfig: CalendarDayEventsConfig) {
    const {
      events,
      calRows,
      timezone,
      numLinesPerDay,
      templateMode,
      fontSizeStyle,
      maxNumCharacter,
      verticallyCentered,
    } = calendarDayEventsConfig;
    let maxNumLines: number;
    let maxNumChars: number;
    let eventsToShow: number;
    let useSmallFont = false;
    if (numLinesPerDay) {
      maxNumLines = numLinesPerDay.regular;
      maxNumChars = maxNumCharacter.regular;
    } else if (calRows === 5) {
      maxNumLines = this.maxEventsPerDayIn5RowMonth;
    } else {
      maxNumLines = this.maxEventsPerDayIn6RowMonth;
    }
    const nestedTableBody = [];

    // start by assuming we show one event per line
    eventsToShow = maxNumLines;

    // test if all events (with text-wrapping) can be shown at the regular font size or not
    events.forEach((event, index) => {
      const listing = this.getCalendarEventListing(event, maxNumChars);

      const numLinesPerEvent = this.evaluateTextWrapOverflow(
        listing.numLinesPerItem,
      );

      eventsToShow = eventsToShow - numLinesPerEvent;

      if (eventsToShow < 0) {
        useSmallFont = true;
        maxNumLines = numLinesPerDay.small;
        maxNumChars = maxNumCharacter.small;
      }
    });

    events.sort((a, b) => {
      const dateA = new Date(a.eventStart);
      const dateB = new Date(b.eventStart);
      return dateA.getTime() - dateB.getTime();
    });

    let linesRemaining = maxNumLines;

    for (let i = 0; i < maxNumLines; i++) {
      if (events[i] && linesRemaining !== 0) {
        const style = ['eventListing'];
        const eventTypeStyle = [];
        const eventTimeStyle = ['time'];

        // Event Type Styling

        if (EventType[events[i].eventType] === EventType.SpecialEvent) {
          eventTypeStyle.push('bold');
        }

        // all day events display in bold with no time listed
        if (events[i].allDay && !eventTypeStyle.includes('bold')) {
          eventTypeStyle.push('bold');
        }

        if (fontSizeStyle) {
          eventTypeStyle.push(fontSizeStyle);
          eventTimeStyle.push(fontSizeStyle);
        }

        // check for superscript formatting
        const listing = this.getCalendarEventListing(
          events[i],
          maxNumChars,
          true,
        );

        const numLinesPerEvent = this.evaluateTextWrapOverflow(
          listing.numLinesPerItem,
        );

        linesRemaining = linesRemaining - numLinesPerEvent;

        // if this event would run over, don't include it
        // allows us to check for another event later that could fit
        if (linesRemaining >= 0) {
          nestedTableBody.push([
            {
              text: events[i].allDay
                ? ''
                : (() => {
                    // Properly convert UTC time to site's timezone
                    const eventStartDate = parseISO(
                      events[i].eventStart.toString(),
                    );
                    const startInSiteTimezone = utcToZonedTime(
                      eventStartDate,
                      timezone,
                    );
                    return format(startInSiteTimezone, 'h:mm', {
                      timeZone: timezone,
                    });
                  })(),
              style: [
                ...style,
                ...eventTimeStyle,
                useSmallFont ? 'smallFont' : '',
              ],
              noWrap: true,
            },
            {
              text:
                listing?.charCollection.length > 0
                  ? listing.charCollection
                  : listing.label,
              style: [
                ...style,
                ...eventTypeStyle,
                useSmallFont ? 'smallFont' : '',
              ],
              layout: {
                paddingBottom: function (i, node) {
                  return 0;
                },
                paddingLeft: function (i, node) {
                  return 0;
                }, // padding left of holiday
                paddingTop: function (i, node) {
                  return 0;
                }, // padding top of holiday
                paddingRight: function (i, node) {
                  return 0;
                },
                hLineWidth: (i, node) => {
                  return 0;
                },
                vLineWidth: (i, node) => {
                  return 0;
                },
              },
              noWrap: false,
            },
          ]);
        }
      }
    }
    let eventListStyle: string | Array<string> = 'nestedTable';
    // if enabled and there is room for additional lines, vertically center events
    if (verticallyCentered && events.length > 0 && linesRemaining > 0) {
      eventListStyle = useSmallFont
        ? [this.smallMarginForVerticalCentering[linesRemaining], 'dayRowOffset']
        : [this.marginForVerticalCentering[linesRemaining], 'dayRowOffset'];
    }
    // Table styling for event time and name

    // Time column width varies based on font size, but must be a consistent width regardless of content to support alignment for all-day events
    const timeColumnWidth = useSmallFont ? 28 : 35;
    return {
      table: {
        headerows: 0,
        body: nestedTableBody,
        widths: [timeColumnWidth, '*'],
      },
      layout: {
        ...this.tableLayoutPaddingReset,
        paddingLeft: function (i, node) {
          return 2 * 0.75;
        }, // padding left of time and event title
        hLineWidth: (i, node) => {
          return 0;
        },
        vLineWidth: (i, node) => {
          return 0;
        },
        defaultBorder: templateMode ? true : false,
      },
      style: eventListStyle,
    };
  }

  private formatLegendShortName(room: Room): string {
    return room.shortName;
  }

  // TODO: calculate name length dynamically based on width
  private formatLegendName(room: Room): string {
    let longName: string;
    if (room.name.length >= 22) {
      longName = room.name.substring(0, 21).trim();
    } else {
      longName = room.name;
    }

    return longName;
  }

  private formatLegend(legendConfig: {
    roomData: Room[];
    maxRooms: number;
    templateMode?: boolean;
  }): pdfStack {
    const { roomData, maxRooms, templateMode } = legendConfig;
    if (!roomData || roomData.length === 0) {
      return [{ text: '', style: 'locationHeader' }];
    }
    const nPerColumn = this.legendDaysNeeded ? this.legendDaysNeeded : 3;
    const rows = [];

    // Filter out mobile app name
    const filteredRoomData = roomData.filter((value) => {
      return value.name !== 'GiGi Mobile App';
    });

    if (filteredRoomData.length === 0) {
      return [{ text: '', style: 'locationHeader' }, {}];
    }

    // Max number of rooms we have room to display is 18 for 6 row months and 21 for 5 row
    const roomDataLength =
      filteredRoomData.length <= maxRooms ? filteredRoomData.length : maxRooms;
    for (let i = 0; i < Math.ceil(roomDataLength / nPerColumn); i++) {
      rows[i] = filteredRoomData.slice(nPerColumn * i, nPerColumn * (i + 1));
    }

    for (let i = 0; i < rows.length; i++) {
      const shorts = rows[i].map((row) => this.formatLegendShortName(row));
      const fulls = rows[i].map((row) => this.formatLegendName(row));

      rows[i] = [];
      for (let j = 0; j < shorts.length; j++) {
        if (fulls[j]) {
          rows[i].push({
            style: ['legendEntry'],
            text: `${fulls[j]}${shorts[j] ? `, ${shorts[j]}` : ''}`,
          });
        }
      }

      // if number of rooms is not divisible by n, add empty cells for proper cell formatting
      if (rows[i].length < nPerColumn) {
        for (let k = rows[i].length; k < nPerColumn; k++) {
          rows[i].push({});
        }
      }
    }

    let cols = [];
    for (let x = nPerColumn; x > 0; x--) {
      cols.push('*');
    }
    return [
      { text: 'LOCATIONS', style: 'locationHeader' },
      {
        table: {
          body: rows,
          widths: cols,
        },
        layout: {
          ...this.tableLayoutPaddingReset,
          paddingLeft: function (i, node) {
            return 0;
          }, // padding left of location columns
          hLineWidth: (i, node) => {
            return 0;
          },
          vLineWidth: (i, node) => {
            return 0;
          },
          defaultBorder: templateMode ? true : false,
        },
        style: 'nestedTable',
      },
    ];
  }

  private async buildLegendSection(legendConfig: {
    siteId: number;
    maxRooms: number;
    activeRooms?: Array<Room>;
    templateMode?: boolean;
  }): Promise<pdfTableCell> {
    const { siteId, maxRooms, activeRooms, templateMode } = legendConfig;
    const rooms = activeRooms?.length > 0 ? activeRooms : [];
    const legendRooms = rooms.filter((room) => room.name !== 'Custom Room');
    const sortedLegendRooms = legendRooms.sort((a, b) =>
      a.name > b.name ? 1 : -1,
    ); // sorts  objects in legendRooms array on name property
    const roomLegend = this.formatLegend({
      roomData: sortedLegendRooms,
      maxRooms,
      templateMode,
    });
    return {
      colSpan: this.legendDaysNeeded,
      stack: roomLegend,
      style: 'roomLegend',
    };
  }

  private buildSupplementalInfo(customText: string): pdfTableCell {
    return {
      stack: [
        { text: 'Due to calendar space, ', style: 'disclaimer' },
        { text: 'all programs may not be', style: 'disclaimer3' },
        { text: 'reflected.', style: 'disclaimer2' },
        { text: 'To stay up to date with', style: 'disclaimer' },
        { text: 'all events, please visit', style: 'disclaimer3' },
        {
          text: ['the GiGi Assistant', { text: '®', sup: true }, 'app.'],
          style: 'disclaimer3',
        },
      ],
      style: 'supplementalBlock',
    };
  }

  public async getCalendar(
    calendarConfig: CalendarConfig,
  ): Promise<Array<pdfTableBody>> {
    const {
      siteId,
      timezone,
      monthIndex,
      year,
      monthDate,
      customText,
      rows,
      displayLegendAtEnd,
      displayDisclaimerAtEnd,
      state,
      maxNumCharacter,
      numberOfEventsPerDay,
      numLinesPerDay,
      templateMode,
      maxRooms,
      fontOverride,
      eventGridHeight,
      verticallyCentered,
      subcategoryIds,
    } = calendarConfig;

    const firstOfMonth: Date = startOfMonth(monthDate);

    const lastOfMonth: Date = endOfMonth(monthDate);

    this.calendarEvents = await this.getEventsForCalendar(
      siteId,
      firstOfMonth.toISOString(),
      lastOfMonth.toISOString(),
    );

    let filteredRoomIds = [];
    let activeRooms = [];

    // Determine which rooms are in active use for this month of events
    Object.keys(this.calendarEvents).forEach((day) => {
      let calendarDay = this.calendarEvents[day];
      // if we are passing category ids, filter them out here
      if (subcategoryIds) {
        calendarDay = calendarDay.filter((event) => {
          return subcategoryIds.includes(event?.fullEvent?.subcategory?.id);
        });
        if (calendarDay.length === 0) {
          delete this.calendarEvents[day];
        } else {
          this.calendarEvents[day] = calendarDay;
        }
      }
      calendarDay.forEach((events) => {
        if (filteredRoomIds.includes(events?.fullEvent?.roomId)) {
          // do nothing
        } else if (events?.fullEvent?.roomId) {
          filteredRoomIds.push(events?.fullEvent?.roomId);
          const currentRoom = events.fullEvent.room;
          if (currentRoom) activeRooms.push(currentRoom);
        }
      });
    });

    const firstDayOfMonth: number = firstOfMonth.getDay();
    const lastDateOfMonth: number = lastOfMonth.getDate();
    // change for Legends on Lake Lorraine
    const mainFillColor =
      siteId &&
      (siteId === 137 ||
        siteId === 138 ||
        siteId === 147 ||
        siteId === 148 ||
        siteId === 149)
        ? '#404041'
        : CALENDAR_COLORS.blue;
    const emptyCellFillColor = CALENDAR_COLORS.white;

    const calendar: Array<pdfTableBody> = [
      [
        { text: 'SUNDAY', style: 'tableHeader', fillColor: mainFillColor },
        { text: 'MONDAY', style: 'tableHeader', fillColor: mainFillColor },
        { text: 'TUESDAY', style: 'tableHeader', fillColor: mainFillColor },
        { text: 'WEDNESDAY', style: 'tableHeader', fillColor: mainFillColor },
        { text: 'THURSDAY', style: 'tableHeader', fillColor: mainFillColor },
        { text: 'FRIDAY', style: 'tableHeader', fillColor: mainFillColor },
        { text: 'SATURDAY', style: 'tableHeader', fillColor: mainFillColor },
      ],
      [],
    ];

    let week = 0;
    calendar[1] = [];

    // Build pre-month cells
    if (firstDayOfMonth > 0 && !displayLegendAtEnd && displayDisclaimerAtEnd) {
      // Add legend to top
      calendar[1].push(
        await this.buildLegendSection({
          siteId,
          maxRooms,
          activeRooms,
          templateMode,
        }),
      );
      calendar[1].push({});
      calendar[1].push({});
    } else if (
      firstDayOfMonth > 0 &&
      displayLegendAtEnd &&
      !displayDisclaimerAtEnd
    ) {
      // Add disclaimer to top
      calendar[1].push(this.buildSupplementalInfo(customText));
    } else if (
      firstDayOfMonth > 0 &&
      !displayLegendAtEnd &&
      !displayDisclaimerAtEnd
    ) {
      // Add legend and disclaimer to top
      calendar[1].push(
        await this.buildLegendSection({
          siteId,
          maxRooms,
          activeRooms,
          templateMode,
        }),
      );
      calendar[1].push({});
      calendar[1].push({});
      calendar[1].push(this.buildSupplementalInfo(customText));
    }

    // Add colspan cell to fill out the week
    if (calendar[1].length < firstDayOfMonth - 1) {
      const extraCellsNeeded = firstDayOfMonth - calendar[1].length;
      const tableCell: pdfTableCell = {
        colSpan: extraCellsNeeded,
        text: '',
        style: 'day',
        fillColor: emptyCellFillColor,
      };
      calendar[1].push(tableCell);
      // Add cells that won't display because of colspan above
      for (let i = 1; i < extraCellsNeeded; i++) {
        calendar[1].push({});
      }
    } else if (calendar[1].length < firstDayOfMonth) {
      const tableCell: pdfTableCell = {
        text: '',
        style: 'day',
        fillColor: emptyCellFillColor,
      };
      calendar[1].push(tableCell);
    }

    // Build intermediate cells
    for (let day = 1; day <= lastDateOfMonth; day++) {
      const date: Date = new Date(year, monthIndex - 1, day);
      const holidays = this.holidayService.getHolidaysForDate(
        date,
        state === 'AZ' ? 'Daylight Saving' : null,
      ); // Arizona doesn't observe daylight savings, will be filtered out
      const dayOfWeek = Math.floor((firstDayOfMonth + day - 1) % 7) + 1;

      week = calendar.length - 1;
      const isEndOfWeek = dayOfWeek % 7 === 0;
      calendar[week].push(
        this.buildCalendarDay({
          day,
          holidays,
          calRows: rows,
          timezone,
          numberOfEventsPerDay,
          numLinesPerDay,
          maxNumCharacter,
          templateMode,
          fontOverride,
          eventGridHeight,
          verticallyCentered,
        }),
      );
      if (isEndOfWeek && day !== lastDateOfMonth) {
        calendar.push([]);
      }
    }

    // Build post-month cells
    let lastRow = calendar[calendar.length - 1];
    if (displayLegendAtEnd && displayDisclaimerAtEnd && lastRow.length > 3) {
      // If there isn't room for end content, add empty cells and another row
      for (let i = lastRow.length; i < 7; i++) {
        calendar[calendar.length - 1].push({
          text: '',
          fillColor: emptyCellFillColor,
        });
      }
      calendar.push([]);
    }

    lastRow = calendar[calendar.length - 1];

    if (displayLegendAtEnd) {
      const legendSection: any = await this.buildLegendSection({
        siteId,
        maxRooms,
        activeRooms,
        templateMode,
      });
      calendar[calendar.length - 1].push(legendSection);
      lastRow = calendar[calendar.length - 1];
      for (let i = 0; i < legendSection.colSpan - 1; i++) {
        calendar[calendar.length - 1].push({});
      }
    }

    if (displayDisclaimerAtEnd) {
      calendar[calendar.length - 1].push(
        this.buildSupplementalInfo(customText),
      );
    }

    // Add colspan cell to fill out the week
    if (lastRow.length < 6) {
      const extraCellsNeeded = 7 - lastRow.length;
      const tableCell: pdfTableCell = {
        colSpan: extraCellsNeeded,
        text: '',
        style: 'day',
        fillColor: emptyCellFillColor,
      };
      calendar[calendar.length - 1].push(tableCell);
      // Add cells that won't display because of colspan above
      for (let i = 1; i < extraCellsNeeded; i++) {
        calendar[calendar.length - 1].push({});
      }
    } else if (lastRow.length < 7) {
      const tableCell: pdfTableCell = {
        text: '',
        style: 'day',
        fillColor: emptyCellFillColor,
      };
      calendar[calendar.length - 1].push(tableCell);
    }

    return calendar;
  }

  /**
   * Send email alert for completed calendar.
   * @param calendar
   */
  public emailCalendar(calendar: CreateCalendarDto): Promise<Calendar> {
    const url = `${environment.apiv3Url}/calendars`;

    try {
      return <Promise<any>>lastValueFrom(this.http.post(url, calendar));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }
}
