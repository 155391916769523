import React from 'react';
import { Stepper as MUIStepper, Step, StepLabel } from '@mui/material';

interface StepperProps {
  steps: {
    id: string;
    title: string;
    label: string;
  }[];
  activeStep: number;
  onStepChange: (step: number) => void;
  disabled: boolean;
}

export function Stepper({
  steps,
  activeStep,
  onStepChange,
  disabled,
}: StepperProps) {
  return (
    <MUIStepper
      activeStep={activeStep}
      alternativeLabel
      sx={{
        width: '100%',
        '& .MuiStepLabel-label': {
          mt: 1,
          fontSize: '1rem',
        },
      }}
    >
      {steps.map((step, index) => (
        <Step
          key={step.id}
          onClick={() => !disabled && onStepChange(index)}
          sx={{
            cursor: disabled ? 'default' : 'pointer',
            '& .MuiStepLabel-root': {
              color: 'text.primary',
            },
          }}
        >
          <StepLabel>{step.label}</StepLabel>
        </Step>
      ))}
    </MUIStepper>
  );
}
