import { NotificationDto } from '../interfaces/notification';
import { SiteDto } from '../interfaces/site';
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export class Notification implements NotificationDto {
  public id: number;
  public createdOn: Date;
  public updatedOn: Date;
  public deleted: boolean;
  public title: string;
  public text: string;
  public expiration: Date;
  public site: SiteDto | number; // SiteDto when coming from server; number sending new notification to server
  public moreInfoUrl?: string; // Link to find more information on the alert

  constructor(data: NotificationDto) {
    Object.assign(this, data);
  }

  /**
   * Set time to property's timezone
   */
  public getSentAt(siteTimezone: string): string {
    const date =
      typeof this.createdOn === 'string'
        ? parseISO(this.createdOn)
        : this.createdOn;

    if (!siteTimezone) {
      return formatInTimeZone(date, 'UTC', 'yyyy-MM-dd HH:mm:ss');
    } else {
      return formatInTimeZone(date, siteTimezone, 'yyyy-MM-dd HH:mm:ss');
    }
  }
}
