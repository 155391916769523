import React from 'react';
import { FormLabel as MuiFormLabel, FormLabelProps } from '@mui/material';

export function FormLabel({ children, ...props }: FormLabelProps) {
  return (
    <MuiFormLabel
      component="legend"
      sx={{
        mt: 2,
        mb: 1,
        color: 'text.primary',
        fontSize: '24px',
        ...props.sx,
      }}
      {...props}
    >
      {children}
    </MuiFormLabel>
  );
}
