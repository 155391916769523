import React from 'react';
import {
  Eventcalendar as MbscEventCalendar,
  CalendarPrev,
  CalendarNext,
  MbscEventcalendarView,
  createCustomTheme,
} from '@mobiscroll/react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import CalendarToday from '@mui/icons-material/CalendarToday';
import TV from '@mui/icons-material/Tv';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { EventType } from '../../../../../../../../../core/enums/event-type';
import { CalendarOverflowWarning } from './calendar-overflow-warning';
import { format, isSameMonth, isSameYear } from 'date-fns';

createCustomTheme('goodman', 'material');

type EventCalendarProps = {
  events: any;
  selectedDate: any;
  handlePageLoading: any;
  jumpToThisMonth: any;
  loading: boolean;
  activeMonth: Date;
  printCalendarRestrictions: any;
  months: Array<Date>;
  deleteActivity: (activity: any) => void;
  showEditDialog: (activity: any) => void;
};

export function EventCalendar({
  events,
  selectedDate,
  handlePageLoading,
  jumpToThisMonth,
  loading = true,
  activeMonth,
  printCalendarRestrictions,
  months,
  deleteActivity,
  showEditDialog,
}: EventCalendarProps) {
  const view: MbscEventcalendarView = {
    agenda: { type: 'month', size: 1 },
  };

  /**
   * We sort our events correctly on the BE. We need to override mobiscroll's default behavior of
   * sorting by all day events, then time, then alphabetically by title.
   **/
  function sortEvents() {
    return 1;
  }

  function renderHeaderTemplate() {
    const showPrev = !(
      isSameMonth(activeMonth, months[0]) && isSameYear(activeMonth, months[0])
    );

    const showNext = !(
      isSameMonth(activeMonth, months[24]) &&
      isSameYear(activeMonth, months[24])
    );
    return (
      <>
        {showPrev && <CalendarPrev />}
        <h2 className="mobiscroll-header-month">
          {format(activeMonth, 'MMMM yyyy')}
        </h2>

        {showNext && <CalendarNext />}
        <div className="ml-4">
          <Button onClick={jumpToThisMonth} variant="outlined" color="primary">
            Jump To This Month
          </Button>
        </div>
      </>
    );
  }

  // should be MbscCalendarEventData type...
  function renderAgendaListTemplate(data: any) {
    return (
      <div className="agenda-content-wrapper">
        {loading ? (
          <div className="w-full h-full flex justify-center items-center">
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <>
            {data.map((day) => (
              <ul key={day.timestamp}>
                <li className="date-header">{day.date}</li>
                <li>
                  <CalendarOverflowWarning
                    events={day.events}
                    printCalendarRestrictions={printCalendarRestrictions}
                    selectedMonth={activeMonth}
                  />
                </li>
                {day.events?.map((event: any) => (
                  <li key={event.id}>
                    <div className="mbsc-event">
                      <IconButton
                        className="event-delete"
                        color="secondary"
                        onClick={() => deleteActivity(event)}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <div
                        className="agenda-wrapper cursor-pointer"
                        onClick={() => showEditDialog(event)}
                      >
                        <Tooltip title={EventType[event.original.eventType]}>
                          <div
                            className="mbsc-event-type-indicator"
                            style={{ backgroundColor: event.color }}
                          ></div>
                        </Tooltip>
                        <div className="mbsc-event-time">
                          {event.allDay ? (
                            <div className="mbsc-event-all-day">
                              All Day Event
                            </div>
                          ) : (
                            <>
                              <div className="mbsc-event-start">
                                {event.start}
                              </div>
                              <div className="mbsc-event-end">{event.end}</div>
                            </>
                          )}
                        </div>
                        <div className="event-icon-wrapper">
                          <Tooltip title={event.original?.eventTag?.tag}>
                            <img
                              className="event-icon"
                              src={`assets/icons/event-tag/${
                                event.original.icon
                                  ? event.original.icon
                                  : 'mobileapp'
                              }.svg`}
                            />
                          </Tooltip>
                        </div>

                        <div className="mbsc-event-content">
                          <div className="event-title">
                            {event.original?.recurrence && (
                              <Tooltip
                                title={`Repeats ${event.original.recurrence}`}
                              >
                                <span className="material-icons repeat-icon">
                                  refresh
                                </span>
                              </Tooltip>
                            )}
                            {event.original.title}
                            <span className="event-location">
                              {event.original.room?.long}
                              {event.original.room?.short &&
                                `, ${event.original.room?.short}`}
                              {event.original.location &&
                                `, ${event.original.location}`}
                            </span>
                          </div>
                        </div>
                        <div
                          className="event-description w-20 px-2 gap-2"
                          style={{
                            borderLeft: 'solid 2px black',
                          }}
                        >
                          {event.original.showOnPrintCalendar && (
                            <Tooltip title="On Print Calendar">
                              <CalendarToday />
                            </Tooltip>
                          )}
                          {event.original.showOnDigitalSignage && (
                            <Tooltip title="On Digital Signage">
                              <TV />
                            </Tooltip>
                          )}
                        </div>
                        <div
                          className="event-description"
                          style={{
                            borderLeft: 'solid 2px black',
                            paddingLeft: event.original.description
                              ? '10px'
                              : '0',
                          }}
                        >
                          {event.original.description}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ))}
          </>
        )}
      </div>
    );
  }

  return (
    <MbscEventCalendar
      data={events}
      selectedDate={selectedDate}
      onPageLoading={handlePageLoading}
      renderHeader={renderHeaderTemplate}
      renderAgenda={(events) => renderAgendaListTemplate(events)}
      view={view}
      dateFormatLong="DDDD, MMM D, YYYY"
      noEventsText="No scheduled events"
      eventOrder={sortEvents}
    />
  );
}
