// Create a new hook: web/src/app/features/digital-signage/hooks/useStyles.ts
import { useQuery } from '@tanstack/react-query';
import { createStylesApi } from '@features/styles/api/styles.api';
import { auth } from '@core/utils/firebase/firebase';
import { Auth } from '@angular/fire/auth';

const stylesApi = createStylesApi(auth as Auth);

export function useStyles(siteId: number, siteType?: string) {
  return useQuery({
    queryKey: ['styles', siteId],
    queryFn: () => stylesApi.getStylesForSite(siteId),
    enabled: !!siteId,
  });
}
