import { useMutation } from '@tanstack/react-query';
import { createImageUploadApi } from '../api/image-upload.api';
import {
  getDownloadUrl,
  getSiteBackgroundImageFilePath,
  isAllowedFileType,
  resizeFile,
  uploadToFirestore,
} from '../services/image.service';
import { auth } from '@core/utils/firebase/firebase';
import type { UploadedImage } from '../types';
import { Auth } from '@angular/fire/auth';

const imageUploadApi = createImageUploadApi(auth as Auth);

export const useImageUpload = (siteId: number) => {
  return useMutation({
    mutationFn: async (file: File): Promise<UploadedImage> => {
      // Validate file type
      if (!(await isAllowedFileType(file))) {
        throw new Error(
          'Please choose an allowed file type: jpg, png, gif or tif.',
        );
      }

      // Resize image
      const resizedImage = await resizeFile(file, {
        maxWidthOrHeight: 3840,
        maxSizeMB: 4,
      });

      // Get path and upload
      const path = await uploadToFirestore(
        getSiteBackgroundImageFilePath(siteId),
        resizedImage,
      );

      if (!path) {
        throw new Error('Failed to upload image');
      }

      // Get download URL
      const downloadUrl = await getDownloadUrl(path);

      // Create image record in database
      const image = await imageUploadApi.createImageRecord(
        downloadUrl,
        path,
        siteId,
        4, // StyleId 4 = Background image
      );

      return {
        downloadUrl,
        path,
        id: image.id,
      };
    },
  });
};
