import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Announcement } from '@models/announcement';
import { SiteService } from './site.service';
import { AnnouncementDto } from '@interfaces/announcement';
import { map, tap } from 'rxjs/operators';
import { endOfMonth, startOfMonth } from 'date-fns';

export interface BulkUpdateResponse {
  identifiers: [];
  raw: [];
}

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  private _activities = new BehaviorSubject<Announcement[]>([]);
  public readonly activities = this._activities.asObservable();

  private _isGettingEvents = new BehaviorSubject<boolean>(false);
  public readonly isGettingEvents = this._isGettingEvents.asObservable();

  constructor(private http: HttpClient, private siteService: SiteService) {}

  get currentActivities(): Announcement[] {
    return this._activities.getValue();
  }

  public refreshActivities(
    selectedMonth: Date,
    showLoading = true,
  ): Promise<void> {
    const siteId = this.siteService.currentSiteId;

    if (!siteId) {
      this._activities.next([]);
      return;
    }

    return this.getMonthsEvents(
      this.siteService.currentSiteId,
      selectedMonth,
      showLoading,
    )
      .then((activities: Announcement[]) => {
        this._activities.next([...activities]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**
   * Fetch events for month from API.
   * startDate comes in as property's timezone; endDate is created in property's timezone.
   * Before sending to API, convert both to UTC.
   * @param siteId
   * @param startDate
   */
  public async getMonthsEvents(
    siteId: number,
    selectedMonth: Date,
    showLoading = true,
  ): Promise<Announcement[]> {
    const monthStartDate = new Date(
      selectedMonth.getFullYear(),
      selectedMonth.getMonth(),
    );
    const startDate = startOfMonth(monthStartDate);

    this._isGettingEvents.next(showLoading);
    const endDate = endOfMonth(monthStartDate);

    const url = `${environment.apiv3Url}/announcements/site/${siteId}`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('eventStart', startDate.getTime());
    queryParams = queryParams.append('eventEnd', endDate.getTime());
    // "events" have a styleId of 3 in the announcements table.
    queryParams = queryParams.append('searchBy', '["styleId"]');
    queryParams = queryParams.append('search', '3');
    queryParams = queryParams.append('sortField', 'eventStart');
    queryParams = queryParams.append('sortOrder', 'asc');
    try {
      return lastValueFrom(
        this.http
          .get<{ data: AnnouncementDto[]; totalCount: number }>(url, {
            params: queryParams,
          })
          .pipe(
            map(({ data }) => {
              return data.map((announcement) => new Announcement(announcement));
            }),
            tap(() => this._isGettingEvents.next(false)),
          ),
      );
    } catch (err) {
      this._isGettingEvents.next(false);
      console.error('ERROR', err);
      return;
    }
  }

  /**
   * Fetch events in range from API.
   * @param siteId
   * @param startDate
   */
  public getTimeRangeEvents(
    siteId: number,
    startDate: Date,
    endDate: Date,
  ): Promise<Announcement[]> {
    const queryParams = new HttpParams()
      .append('eventStart', startDate.getTime().toString())
      .append('eventEnd', endDate.getTime().toString());
    console.log('queryParams', queryParams);
    const url = `${environment.apiv3Url}/announcements/site/${siteId}`;

    return lastValueFrom(
      this.http
        .get<{ data: AnnouncementDto[]; totalCount: number }>(url, {
          params: queryParams,
        })
        .pipe(map(({ data }) => data.map((a) => new Announcement(a)))),
    ).catch((err) => {
      console.error('ERROR', err);
      throw err; // Re-throw the error so it can be caught by the caller
    });
  }

  public copyPreviousMonthsEvents(
    siteId: number,
    startDate: Date,
  ): Promise<BulkUpdateResponse> {
    const url = `${environment.apiv3Url}/announcements/site/${siteId}/generate`;
    try {
      return lastValueFrom(
        this.http.post<BulkUpdateResponse>(url, {
          startDate: startDate.getTime(),
        }),
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public deleteMonthsEvents(
    siteId: number,
    startDate: Date,
  ): Promise<BulkUpdateResponse> {
    const url = `${environment.apiv3Url}/announcements/site/${siteId}`;
    const queryParams = new HttpParams().append(
      'startDate',
      startDate.getTime().toString(),
    );
    try {
      return lastValueFrom(
        this.http.delete<BulkUpdateResponse>(url, { params: queryParams }),
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public resetEvents() {
    this._activities.next([]);
  }
}
