import { Auth } from '@angular/fire/auth';
import { createApi } from '@core/api/base-api';
import type { Style } from '@core/interfaces/api';
import { Injectable } from '@angular/core';

export const createStylesApi = (auth: Auth) => {
  const api = createApi(auth);

  return {
    getStylesForSite: (siteId: number) => api.get<Style[]>(`/style/${siteId}`),
  };
};

// Angular compatibility layer (temporary)
@Injectable({ providedIn: 'root' })
export class StylesApi {
  private readonly api = createStylesApi(this.auth);

  constructor(private auth: Auth) {}

  getStylesForSite = this.api.getStylesForSite;
}
