<div class="form" [formGroup]="eventTimingForm">
  <h2 class="subheading">Event Timing</h2>
  <div class="timezone-notice">
    <mat-icon color="accent">info</mat-icon>
    <span
      >All times should be entered in
      <strong>{{ siteTimezone }}</strong> (site's time zone)</span
    >
  </div>
  <div class="timezone-helper">
    <div>
      Current time in your local time zone: <strong>{{ localTime }}</strong>
    </div>
    <div>
      Current time in site's time zone: <strong>{{ siteTime }}</strong>
    </div>
  </div>
  <mat-error *ngIf="eventTimingForm.errors && eventTimingForm.touched">
    Please fill out all required fields.
  </mat-error>
  <div class="questions">
    <div class="error-wrapper question">
      <mat-form-field
        appearance="outline"
        color="accent"
        class="small-input"
        *ngIf="monthRestriction"
      >
        <mat-label color="accent">Date</mat-label>
        <input
          data-qa="event-input-date"
          [min]="monthRestriction.min"
          matInput
          autofocus
          type="date"
          formControlName="date"
          [matDatepicker]="picker"
        />
        <mat-hint
          ><span
            *ngIf="
              eventTimingForm?.controls['date']?.hasError('required') &&
              eventTimingForm?.controls['date']?.touched
            "
            class="error"
          >
            Please select an event date
          </span>
          <span
            *ngIf="
              eventTimingForm?.controls['date']?.hasError(
                'dateAfterValidator'
              ) && eventTimingForm?.controls['date']?.value
            "
            class="error"
          >
            Date must be this month
          </span>
          <span
            *ngIf="
              eventTimingForm?.controls['date']?.hasError(
                'dateBeforeValidator'
              ) && eventTimingForm?.controls['date']?.value
            "
            class="error"
          >
            Date must be this month
          </span>
        </mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="error-wrapper question">
      <mat-form-field class="small-input" appearance="outline" color="accent">
        <mat-label color="accent">Start time</mat-label>
        <input
          matInput
          formControlName="eventStart"
          placeholder="00:00 PM"
          data-qa="event-input-start-time"
          type="time"
          (blur)="startTimeBlur()"
          (focus)="startTimeFocus()"
        />
        <mat-hint
          ><span
            *ngIf="
              eventTimingForm.controls['eventStart'].errors &&
              eventTimingForm.controls['eventStart'].touched
            "
            class="error"
          >
            Start time is required.
          </span></mat-hint
        >
      </mat-form-field>
      <div class="all-day-wrapper">
        <mat-checkbox
          formControlName="allDay"
          color="accent"
          data-qa="event-checkbox-all-day"
        >
          <label data-qa="event-checkbox-all-day-label">
            <span>All Day Event</span>
          </label></mat-checkbox
        >
      </div>
    </div>
    <div class="error-wrapper question">
      <mat-form-field class="small-input" appearance="outline" color="accent">
        <mat-label color="accent">End time</mat-label>
        <input
          matInput
          data-qa="event-input-end-time"
          formControlName="eventEnd"
          placeholder="00:00 PM"
          type="time"
        />
        <mat-hint
          ><span
            *ngIf="
              eventTimingForm?.controls['eventEnd']?.hasError('required') &&
              eventTimingForm?.controls['eventEnd']?.touched
            "
            class="error"
          >
            End time is required.
          </span>
          <span
            *ngIf="
              eventTimingForm?.controls['eventEnd']?.hasError(
                'timeAfterValidator'
              ) && eventTimingForm?.controls['eventEnd']?.touched
            "
            class="error"
          >
            End time must be after start time.
          </span>
        </mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div class="recurrence-wrapper">
    <mat-checkbox
      formControlName="repeating"
      color="accent"
      data-qa="event-checkbox-repeat"
    >
      <label data-qa="event-checkbox-repeat-label">
        <span
          *ngIf="
            eventTimingForm.controls['repeating']?.value;
            else defaultLabel
          "
          >Repeating Event: this event repeats {{ prettyRecurrence }}</span
        >
        <ng-template #defaultLabel>
          <span
            *ngIf="
              eventTimingForm.controls['repeating'].disabled;
              else repeatPrompt
            "
            >This event does not repeat.</span
          >
          <ng-template #repeatPrompt>
            Repeating Event?<span
              *ngIf="!eventTimingForm.controls['eventStart'].value"
              class="disclaimer"
              >(Select a start time to enable recurrence)</span
            ></ng-template
          ></ng-template
        >
      </label></mat-checkbox
    >
    <ng-template #doesNotRepeat
      ><div class="disclaimer">This event does not repeat.</div></ng-template
    >
    <app-calendar-recurrence
      formControlName="recurrenceRecipe"
      [editing]="editing"
      [deactivate]="!eventTimingForm.controls['repeating']?.value"
      [start]="{
        date: eventTimingForm.controls.date.value,
        time: eventTimingForm.controls.eventStart.value
      }"
      [timezone]="siteService.currentSite.timezone"
      [activeMonth]="months[activeMonthIndex]"
    ></app-calendar-recurrence>
  </div>
  <div class="form-row">
    <div class="calendar">
      <app-event-preview-calendar
        *ngIf="months && eventTimingForm.controls.date.value"
        [activeMonthIndex]="activeMonthIndex"
        [months]="months"
        [eventPreview]="eventPreview$?.value"
        [activeDay]="eventTimingForm.controls.date.value"
        (changeSelectedDay)="updateEventTiming($event)"
        (changeMonth)="handleMonthStep($event)"
        (jumpToMonth)="jumpToMonth($event)"
      ></app-event-preview-calendar>
    </div>
    <div class="agenda">
      <app-daily-agenda
        [activeDay]="eventTimingForm.controls.date.value"
        [activeEvent]="eventToEdit ? eventToEdit : null"
      >
      </app-daily-agenda>
    </div>
  </div>
</div>
