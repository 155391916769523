import { Auth } from '@angular/fire/auth';
import { createApi } from '@core/api/base-api';
import type { Image } from '@core/interfaces/api';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { PaginationInformation } from '@shared/pagination-server-side/datasources/genericBE.datasource';

export const createBackgroundImagesApi = (auth: Auth) => {
  const api = createApi(auth);

  return {
    getBackgroundImages: (
      siteId: number,
      queryParams: HttpParams,
      pagination?: PaginationInformation,
    ) => {
      const { search, styleId } = pagination?.filters || {};
      const { active, direction } = pagination?.sort || {};

      let params = {
        sortField: active || 'updatedOn',
        sortOrder: (direction || 'desc').toLowerCase(),
        style: '4', // StyleId 4 = Announcement/background image
      };

      // Add search params if provided
      if (search?.trim().length > 0) {
        params['searchBy'] = '["subcategory", "category", "site"]';
        params['search'] = search;
      }

      // Add any additional query params
      queryParams?.keys().forEach((key) => {
        params[key] = queryParams.get(key);
      });

      return api.get<{ data: Image[]; totalCount: number }>(
        `/image/site/${siteId}`,
        { params },
      );
    },

    createImageRecord: (
      downloadUrl: string,
      path: string,
      siteId: number,
      styleId: number,
      announcementId?: number,
    ) => {
      const body = {
        image: {
          path,
          downloadUrl,
          site: siteId,
          style: styleId,
          announcement: announcementId,
        },
      };

      return api.post<Image>('/image', body);
    },

    getBackgroundForSubcategory: async (subcategoryId: number) => {
      try {
        const response = await api.get<Image>(
          `/image/background-images/subcategory/${subcategoryId}`,
        );
        return response[0];
      } catch (error) {
        console.error('Error fetching background image:', error);
        return null;
      }
    },

    getOrgBackgrounds: (orgId: number) => {
      return api.get<Image[]>(`/image/organization/${orgId}/all`);
    },
  };
};

// Angular compatibility layer (temporary)
@Injectable({ providedIn: 'root' })
export class BackgroundImagesApi {
  private readonly api = createBackgroundImagesApi(this.auth);

  constructor(private auth: Auth) {}

  getBackgroundImages = this.api.getBackgroundImages;
  createImageRecord = this.api.createImageRecord;
  getBackgroundForSubcategory = this.api.getBackgroundForSubcategory;
  getOrgBackgrounds = this.api.getOrgBackgrounds;
}
