import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

// import { MbscEventcalendarOptions } from '@mobiscroll/angular';
import { ActivityService } from 'src/app/core/services/activity.service';
import {
  EventColors,
  EventType,
} from '../../../../../../../../core/enums/event-type';
import { Announcement } from '@models/announcement';
import { SiteService } from 'src/app/core/services/site.service';
import { RecurrenceService } from 'src/app/core/services/recurrence.service';
import { parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
@Component({
  selector: 'app-daily-agenda',
  templateUrl: './daily-agenda.component.html',
  styleUrls: ['./daily-agenda.component.scss'],
})

// Read-only daily agenda list
export class DailyAgendaComponent implements OnInit, OnDestroy {
  destroyed$: Subject<boolean> = new Subject();
  public events: BehaviorSubject<any[]> = new BehaviorSubject([]);
  @Input() timezone;
  @Input() activeDay;
  @Input() activeEvent: Announcement;
  eventTypes = EventType;
  // options: MbscEventcalendarOptions = {
  //   view: {
  //     agenda: { type: 'day' },
  //   },
  //   theme: 'goodman',
  //   dateFormatLong: 'DDDD, MMM D, YYYY',
  //   noEventsText: 'No scheduled events',
  // };
  selectedDate = new Date();
  public loading = true;
  constructor(
    public activityService: ActivityService,
    private recurrenceService: RecurrenceService,
    private siteService: SiteService,
  ) {}

  ngOnInit(): void {
    this.activityService.activities
      .pipe(
        map((activities) => {
          let mappedToCalEvents = activities.map((activity, index) => {
            // Properly convert UTC times to site's timezone
            const eventStartDate = parseISO(activity.eventStart.toString());
            const eventEndDate = parseISO(activity.eventEnd.toString());

            // Use utcToZonedTime to correctly convert from UTC to site's timezone
            const startInSiteTimezone = utcToZonedTime(
              eventStartDate,
              this.siteService.currentSite.timezone,
            );
            const endInSiteTimezone = utcToZonedTime(
              eventEndDate,
              this.siteService.currentSite.timezone,
            );

            const formattedActivity = {
              start: startInSiteTimezone,
              end: endInSiteTimezone,
              title: activity.title,
              allDay: activity.allDay,
              eventTag: activity?.eventTags[0],
              color: EventColors[activity.eventType],
              eventType: activity.eventType,
              room: {
                long: activity.room?.name,
                short:
                  activity.room?.shortName && activity.room?.shortName !== ' - '
                    ? activity.room.shortName
                    : null,
              },
              src: activity,
              activeEvent:
                this.activeEvent && activity.id === this.activeEvent.id,
              recurrence: activity.recurrence
                ? this.recurrenceService.prettyPrintRecurrenceRecipe({
                    ...activity.recurrence,
                  })
                : null,
            };
            return formattedActivity;
          });
          return mappedToCalEvents;
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe((activities) => {
        this.events.next(activities);
        this.loading = false;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }
}
