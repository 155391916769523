/**
 * DIGITAL SIGNAGE SLIDE TYPE FILTERING
 * ====================================
 *
 * This file contains the logic for filtering slide types (styles) based on site type.
 * It serves as the single source of truth for which slide types should be available
 * for each site type in the digital signage system.
 *
 * SITE TYPES:
 * -----------
 * 1. Corporate
 * 2. Residential
 * 3. Senior Living
 * 4. Senior Living/Residential
 *
 * SLIDE TYPES (STYLES) OBSERVED IN PRODUCTION:
 * -------------------------------------------
 * - Individual Spotlight - Highlight an individual with a photo, name, and personal facts
 * - Activities & Events - Share an event with date, time, and location (filtered out for ALL site types)
 * - Announcement - Share an announcement with a title and description
 * - Special Announcement - An announcement shown exclusively during its date range
 * - Name in Lights - Focus on background image with title and longer body copy
 * - Image Centric - Focus on background image with title and short subtitle
 * - Collage - Multiple photos with descriptive text
 * - GIF Centric - Displays an animated GIF
 *
 * FILTERING RULES (BASED ON PRODUCTION OBSERVATIONS):
 * --------------------------------------------------
 * 1. "Activities & Events" is filtered out for ALL site types
 *
 * 2. Corporate sites should have:
 *    - Announcement
 *    - Collage
 *    - Image Centric
 *    - Individual Spotlight
 *    - Name in Lights
 *    - Special Announcement
 *
 * 3. Residential sites should have:
 *    - Announcement
 *    - Collage
 *    - Image Centric
 *    - Individual Spotlight
 *    - Name in Lights
 *    - Special Announcement
 *
 * 4. Senior Living sites should have:
 *    - Announcement
 *    - Collage
 *    - Image Centric
 *    - Individual Spotlight
 *    - Special Announcement
 *
 * 5. Senior Living/Residential sites should have:
 *    - Matches exactly with production (no discrepancies noted)
 */

import { Style } from '@core/interfaces/api';

/**
 * All known site types in the system
 */
export type SiteType =
  | 'Corporate'
  | 'Residential'
  | 'Senior Living'
  | 'Senior Living/Residential'
  | '';

/**
 * Filters the provided styles based on the site type according to business rules
 * observed in the production environment.
 *
 * @param styles - Array of Style objects to filter
 * @param siteType - The type of site to filter styles for
 * @returns Filtered array of Style objects appropriate for the site type
 *
 * @example
 * // Get styles for a Corporate site
 * const corporateStyles = filterStylesBySiteType(allStyles, 'Corporate');
 */
export function filterStylesBySiteType(
  styles: Style[],
  siteType: SiteType,
): Style[] {
  // Handle empty styles array or undefined
  if (!styles || styles.length === 0) {
    console.warn('No styles provided to filterStylesBySiteType');
    return [];
  }

  // Step 1: Always filter out "Activities & Events" for all site types
  let filtered = styles.filter((style) => style.name !== 'Activities & Events');

  // Step 2: Apply site-type specific filtering based on production observations
  switch (siteType) {
    case 'Senior Living':
      // Senior Living sites should NOT have Name in Lights or GIF Centric
      filtered = filtered.filter(
        (style) => !['Name in Lights', 'GIF Centric'].includes(style.name),
      );
      break;

    case 'Residential':
      // Residential sites should NOT have GIF Centric
      filtered = filtered.filter((style) => style.name !== 'GIF Centric');
      break;

    case 'Corporate':
      // Corporate sites should NOT have GIF Centric
      filtered = filtered.filter((style) => style.name !== 'GIF Centric');
      break;

    case 'Senior Living/Residential':
      // Senior Living/Residential sites should NOT have GIF Centric or Name in Lights
      filtered = filtered.filter(
        (style) => !['Name in Lights', 'GIF Centric'].includes(style.name),
      );
      break;

    default:
      // For unknown site types, don't apply additional filtering
      console.warn(`Unknown site type: ${siteType}. Using default filtering.`);
      break;
  }

  return filtered;
}

/**
 * Returns a human-readable list of allowed slide types for a given site type,
 * based on observations from the production environment.
 *
 * @param siteType - The type of site
 * @returns String describing allowed slide types
 */
export function getAllowedSlideTypesDescription(siteType: SiteType): string {
  switch (siteType) {
    case 'Corporate':
      return 'Announcement, Collage, Image Centric, Individual Spotlight, Name in Lights, Special Announcement';

    case 'Residential':
      return 'Announcement, Collage, Image Centric, Individual Spotlight, Name in Lights, Special Announcement';

    case 'Senior Living':
      return 'Announcement, Collage, Image Centric, Individual Spotlight, Special Announcement';

    case 'Senior Living/Residential':
      return 'Announcement, Collage, Image Centric, Individual Spotlight, Special Announcement';

    default:
      return 'Unknown site type';
  }
}
