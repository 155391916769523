import { Auth } from '@angular/fire/auth';
import { createApi } from '@core/api/base-api';
import type { MenuSlide, MenuSlidePayload } from '@core/interfaces/api';

export const createMenuSlideApi = (auth: Auth) => {
  const api = createApi(auth);

  return {
    getMenuSlideBySiteId: (siteId: number) =>
      api.get<MenuSlide>(`/menu-slide/site/${siteId}`),

    updateMenuSlide: (id: number, data: Partial<MenuSlide>) =>
      api.put<MenuSlide>(`/menu-slide/${id}`, data),

    createMenuSlide: (data: MenuSlidePayload) =>
      api.post<MenuSlide>('/menu-slide', data),
  };
};

// Angular compatibility layer (temporary)
export class MenuSlideApi {
  private readonly api = createMenuSlideApi(this.auth);

  constructor(private auth: Auth) {}

  getMenuSlideBySiteId = this.api.getMenuSlideBySiteId;
  updateMenuSlide = this.api.updateMenuSlide;
  createMenuSlide = this.api.createMenuSlide;
}
