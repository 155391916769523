import { useQuery } from '@tanstack/react-query';
import { createEventSlideApi } from '../api/event-slide.api';
import { createSitesApi } from '@features/sites/api/sites.api';
import { Auth } from '@angular/fire/auth';
import { auth } from '@core/utils/firebase/firebase';

const eventSlideApi = createEventSlideApi(auth as Auth);
const sitesApi = createSitesApi(auth as Auth);

export function useEventSlideSettings(siteId: number) {
  return useQuery({
    queryKey: ['eventSlide', 'settings', siteId],
    queryFn: async () => {
      const [site, eventSlide] = await Promise.all([
        sitesApi.getSite(siteId),
        eventSlideApi.getEventSlideBySiteId(siteId),
      ]);
      return {
        showEventSlide: site.showEventSlide,
        displayDuration: eventSlide?.displayDuration ?? 15,
      };
    },
    enabled: !!siteId,
  });
}
