import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Typography } from '@mui/material';
import { Stepper } from './stepper';
import { FormatStep } from './steps/format-step';
import { DescriptionStep } from './steps/description-step';
import { PreferencesStep } from './steps/preferences-step';
import { SlidePreview } from './slide-preview';
import { startOfDay, endOfDay } from 'date-fns';
import {
  descriptionToFacts,
  factsToDescription,
} from '../utils/spotlight-facts';
import { Site } from '@core/interfaces/api';

interface SlideFormProps {
  onSave: (data: any) => Promise<void>;
  onCancel: () => void;
  site: Site;
  organizationId: number;
  existingAnnouncement?: any;
  steps: Array<{ id: string; title: string; label: string }>;
  isSubmitting: boolean;
}

export function SlideForm({
  onSave,
  onCancel,
  site,
  organizationId,
  existingAnnouncement,
  steps,
  isSubmitting,
}: SlideFormProps) {
  const [activeStep, setActiveStep] = React.useState(0);

  const methods = useForm({
    mode: 'onChange', // Change to onChange mode to validate on each change
    defaultValues: existingAnnouncement
      ? {
          format: {
            styleId: existingAnnouncement.styleId,
            style: existingAnnouncement.style,
            category: existingAnnouncement.category,
            subcategory: existingAnnouncement.subcategory,
            background: existingAnnouncement.background,
            positionImageAboveWeatherBar:
              existingAnnouncement.positionImageAboveWeatherBar,
            useDefaultBackground: !existingAnnouncement.backgroundId,
          },
          description: {
            title: existingAnnouncement?.title || '',
            content: existingAnnouncement?.content || '',
            facts: existingAnnouncement?.content
              ? descriptionToFacts(existingAnnouncement.content)
              : [],
            image: existingAnnouncement?.image || null,
            numberOfImages: existingAnnouncement?.secondaryImages?.length || 1,
            secondaryImages: existingAnnouncement?.secondaryImages || [],
          },
          preferences: {
            displayDuration: existingAnnouncement?.displayDuration || 15,
            publishNow: !existingAnnouncement,
            startShowing: existingAnnouncement
              ? new Date(existingAnnouncement.startShowing)
              : startOfDay(new Date()),
            stopShowing: existingAnnouncement
              ? new Date(existingAnnouncement.stopShowing)
              : endOfDay(new Date(Date.now() + 14 * 24 * 60 * 60 * 1000)),
            startShowingTime: existingAnnouncement
              ? new Date(existingAnnouncement.startShowing)
              : startOfDay(new Date()),
            stopShowingTime: existingAnnouncement
              ? new Date(existingAnnouncement.stopShowing)
              : endOfDay(new Date()),
          },
        }
      : {
          format: {
            styleId: null,
            style: null,
            category: null,
            subcategory: null,
            background: null,
            positionImageAboveWeatherBar: false,
            useDefaultBackground: true,
          },
          description: {
            title: '',
            content: '',
            facts: [],
            image: null,
            numberOfImages: 1,
            secondaryImages: [],
          },
          preferences: {
            displayDuration: 15,
            publishNow: true,
            startShowing: startOfDay(new Date()),
            stopShowing: endOfDay(
              new Date(Date.now() + 14 * 24 * 60 * 60 * 1000),
            ),
            startShowingTime: startOfDay(new Date()),
            stopShowingTime: endOfDay(new Date()),
          },
        },
  });

  // Add a useEffect to ensure the form is properly initialized when editing
  React.useEffect(() => {
    if (existingAnnouncement) {
      // Force a re-render of the form to ensure validation picks up all values
      if (existingAnnouncement.background) {
        methods.setValue('format.background', {
          ...existingAnnouncement.background,
        });
        methods.setValue('format.useDefaultBackground', false);
      }

      // Trigger form validation
      methods.trigger();
    }
  }, [existingAnnouncement, methods]);

  const isStepValid = () => {
    const { format, description, preferences } = methods.getValues();

    switch (activeStep) {
      case 0:
        // For corporate sites, we need to check if a background image is selected
        if (site?.siteType?.description === 'Corporate') {
          // For corporate sites, background is always required
          const hasBackground =
            !!format.background &&
            typeof format.background === 'object' &&
            'id' in format.background;
          const hasCategory =
            !!format.category &&
            (typeof format.category === 'object'
              ? 'id' in format.category
              : true);

          const isValid = !!format.styleId && hasCategory && hasBackground;
          return isValid;
        }
        // For other site types, just check style and category
        const isValid =
          !!format.styleId && (format.category?.id || format.category);
        return isValid;
      case 1:
        if (format.style?.name?.toLowerCase() === 'collage') {
          return (
            description.numberOfImages >= 1 &&
            description.numberOfImages <= 4 &&
            description.secondaryImages?.length === description.numberOfImages
          );
        }
        if (format.style?.name?.toLowerCase() === 'individual spotlight') {
          return description.title;
        }
        return true;
      case 2:
        return preferences.displayDuration > 0;
      default:
        return false;
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      onCancel();
    } else {
      setActiveStep((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleSubmit = async (data: any) => {
    const formData = { ...data };

    // Convert facts to content for Individual Spotlight slides
    if (formData.format.style?.name?.toLowerCase() === 'individual spotlight') {
      formData.description.content = factsToDescription(
        formData.description.facts,
      );
    }

    await onSave(formData);
  };

  return (
    <FormProvider {...methods}>
      <div className="flex flex-col h-[calc(100vh-120px)] w-full gap-4">
        <div className="flex justify-between items-center">
          <Button
            onClick={handleBack}
            data-qa="slide-back-button"
            variant="contained"
          >
            Back
          </Button>
          <Typography variant="h4" component="h1">
            {existingAnnouncement ? 'Edit Slide - ' : ''}
            {steps[activeStep].title}
          </Typography>
          <div />
        </div>

        <div className="flex flex-1 gap-4 overflow-hidden">
          <div className="min-w-[400px] max-w-[400px] flex flex-col shadow-md">
            <div className="rounded-lg p-4 h-full overflow-y-auto bg-[#f0f0f0]">
              {activeStep === 0 && (
                <React.Fragment>
                  {site?.id && site?.siteType?.description ? (
                    <FormatStep
                      onNext={handleNext}
                      disabled={!isStepValid()}
                      siteId={site.id}
                      organizationId={organizationId}
                      siteType={site.siteType.description}
                    />
                  ) : (
                    <div className="flex flex-col items-center justify-center p-4">
                      <Typography variant="body1" color="error">
                        Error loading site data. Please try again.
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onCancel}
                        className="mt-4"
                      >
                        Go Back
                      </Button>
                    </div>
                  )}
                </React.Fragment>
              )}
              {activeStep === 1 && (
                <DescriptionStep
                  onNext={handleNext}
                  disabled={!isStepValid()}
                  siteId={site.id}
                />
              )}
              {activeStep === 2 && (
                <PreferencesStep
                  onSubmit={methods.handleSubmit(handleSubmit)}
                  disabled={!isStepValid() || isSubmitting}
                  isSubmitting={isSubmitting}
                />
              )}
            </div>
          </div>

          <div className="flex-1 rounded-lg overflow-hidden">
            <SlidePreview />
          </div>
        </div>

        <div className="p-4">
          <Stepper
            steps={steps}
            activeStep={activeStep}
            onStepChange={setActiveStep}
            disabled={!isStepValid() || isSubmitting}
          />
        </div>
      </div>
    </FormProvider>
  );
}
