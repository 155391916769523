import clsx from 'clsx';
import React from 'react';
import { CommonSlideProps } from '../slide-preview';

export function ImageCentricSlide({
  title,
  content,
  backgroundImage,
  preview = false,
}: CommonSlideProps & { preview?: boolean }) {
  return (
    <div className="absolute inset-0 bg-black">
      <div
        className={clsx('absolute inset-0 bg-no-repeat bg-cover bg-center')}
        style={{
          backgroundImage: backgroundImage
            ? `url(${backgroundImage})`
            : undefined,
        }}
      />
      {title && title.trim().length > 0 && (
        <div className="absolute bottom-0 left-0 w-full">
          <div className="bg-black/60 w-[60%] m-0 p-[3vmin] absolute bottom-0 left-0">
            <h1
              className={clsx(
                'font-black text-white font-sans mb-[1vmin] leading-tight uppercase whitespace-pre-line',
                preview ? 'text-[2.8vmin]' : 'text-[5.6vmin]',
              )}
            >
              {title}
            </h1>
            <h2
              className={clsx(
                'text-white font-serif leading-tight',
                preview ? 'text-[2vmin]' : 'text-[3.9vmin]',
              )}
            >
              {content}
            </h2>
          </div>
        </div>
      )}
    </div>
  );
}
