import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField, FormControl, FormLabel, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { getMaxTitleChars } from '@features/digital-signage/add-slide/utils/character-lengths';
import { getMaxDescriptionChars } from '@features/digital-signage/add-slide/utils/character-lengths';

interface DefaultDescriptionProps {
  styleId: number;
}

export function DefaultDescription({ styleId }: DefaultDescriptionProps) {
  const { control, watch } = useFormContext();

  // Get max lengths based on slide type
  const maxTitleLength = getMaxTitleChars(styleId);
  const maxDescriptionLength = getMaxDescriptionChars(styleId);

  // Watch these for character count displays
  const title = watch('description.title') ?? '';
  const description = watch('description.content') ?? '';

  return (
    <>
      <FormControl fullWidth>
        <div className="flex items-start gap-2 mb-2">
          <FormLabel
            component="legend"
            sx={{ color: 'text.primary', fontSize: '24px' }}
          >
            Title
          </FormLabel>
          <Tooltip
            title={`This is the headline on your slide. Limit ${maxTitleLength} characters.`}
          >
            <HelpIcon sx={{ fontSize: '16px', color: 'text.secondary' }} />
          </Tooltip>
        </div>
        <Controller
          name="description.title"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              placeholder="Enter title (optional)"
              inputProps={{ maxLength: maxTitleLength }}
              helperText={`${title.length}/${maxTitleLength} characters`}
              data-qa="slide-description-input-title"
            />
          )}
        />
      </FormControl>

      <FormControl fullWidth>
        <div className="flex items-start gap-2 mb-2">
          <FormLabel
            component="legend"
            sx={{ color: 'text.primary', fontSize: '24px' }}
          >
            Description
          </FormLabel>
          <Tooltip
            title={`This is the descriptor text under your headline. Limit ${maxDescriptionLength} characters.`}
          >
            <HelpIcon sx={{ fontSize: '16px', color: 'text.secondary' }} />
          </Tooltip>
        </div>
        <Controller
          name="description.content"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Enter description (optional)"
              inputProps={{ maxLength: maxDescriptionLength }}
              helperText={`${description.length}/${maxDescriptionLength} characters`}
              data-qa="slide-description-input-description"
            />
          )}
        />
      </FormControl>
    </>
  );
}
