import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, lastValueFrom } from 'rxjs';
import { Organization } from '@models/organization';
import { environment } from 'src/environments/environment';
import { OrganizationDto } from '@interfaces/organization';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { User } from '@models/user';
import { SiteType } from '@models/siteType';

@Injectable({
  providedIn: 'root',
})
export class OrgService {
  private _org = new ReplaySubject<Organization>();
  public readonly org = this._org.asObservable();

  private _siteTypes = new ReplaySubject<SiteType[]>();
  public readonly siteTypes = this._siteTypes.asObservable();

  constructor(private authService: AuthService, private http: HttpClient) {
    this.authService.user.subscribe((user: User) => {
      if (user && user.orgId) {
        this.refreshOrg(String(user.orgId));
      } else {
        this._org.next(null);
      }
    });
  }

  public refreshOrg(id: string): void {
    this.getOrg(id).then((org: Organization) => {
      this._org.next(org);
      this.getSiteTypes(Number.parseInt(id, 10)).subscribe((types) => {
        this._siteTypes.next(types);
      });
    });
  }

  private getOrg(id: string): Promise<Organization> {
    const url = `${environment.apiv3Url}/organization/${id}`;

    try {
      return <Promise<Organization>>(
        lastValueFrom(
          this.http
            .get(url)
            .pipe(map((org: OrganizationDto) => new Organization(org))),
        )
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  private getSiteTypes(orgId: number): Observable<SiteType[]> {
    const url = `${environment.apiv3Url}/site-types/${orgId}`;

    try {
      return this.http
        .get(url)
        .pipe(map((types: SiteType[]) => types.map((t) => new SiteType(t))));
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }
}
