import React from 'react';
import { useAnnouncementsMutation } from '@features/announcements/queries/announcements.mutations';
import { useAnnouncementByIdQuery } from '@features/announcements/queries/announcements.queries';
import { SlideForm } from './components/slide-form';
import { CircularProgress } from '@mui/material';
import { setHours, setMinutes, getHours, getMinutes } from 'date-fns';
import { useSite } from '@features/sites/queries/use-site.queries';

const steps = [
  {
    id: 'format',
    title: 'Choose the Slide Format',
    label: 'Format',
  },
  {
    id: 'description',
    title: 'Write the Slide Content',
    label: 'Description',
  },
  {
    id: 'preferences',
    title: 'Set the Display Preferences',
    label: 'Finish',
  },
];

interface AddSlideReactProps {
  onSave: () => void;
  onCancel: () => void;
  siteId: number;
  organizationId: number;
  announcementId?: number;
}

export function AddSlideReact({
  onSave,
  onCancel,
  siteId,
  organizationId,
  announcementId,
}: AddSlideReactProps) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { createAnnouncement, updateAnnouncement } = useAnnouncementsMutation({
    siteId,
  });

  const { data: site, isLoading: isSiteLoading } = useSite(siteId);

  const {
    announcement: existingAnnouncement,
    isLoading: isAnnouncementLoading,
  } = useAnnouncementByIdQuery(announcementId);

  const handleSubmit = async (data) => {
    try {
      setIsSubmitting(true);

      // Get current time for immediate publishing
      const now = new Date();

      // Determine start time based on publishNow preference
      let startShowingTime;
      if (data.preferences.publishNow) {
        // If publishNow is true, use current time
        startShowingTime = now;
      } else {
        // Otherwise use the selected time
        startShowingTime = setMinutes(
          setHours(
            new Date(data.preferences.startShowing),
            getHours(new Date(data.preferences.startShowingTime)),
          ),
          getMinutes(new Date(data.preferences.startShowingTime)),
        );
      }

      const announcement = {
        title: data.description.title,
        content: data.description.content,
        startShowing: startShowingTime.toISOString(),
        stopShowing: setMinutes(
          setHours(
            new Date(data.preferences.stopShowing),
            getHours(new Date(data.preferences.stopShowingTime)),
          ),
          getMinutes(new Date(data.preferences.stopShowingTime)),
        ).toISOString(),
        location: null,
        displayDuration: data.preferences.displayDuration,
        eventStart: null,
        eventEnd: null,
        isSpecialEvent: false,
        videoUrl: null,
        eventType: 'Generic' as
          | 'Generic'
          | 'SpecialEvent'
          | 'Outing'
          | 'NewProgram',
        allDay: false,
        siteId,
        styleId: data.format.styleId,
        imageId: data.description.image?.id || null,
        backgroundId: data.format.background?.id,
        roomId: null,
        categoryId: data.format.category?.id || null,
        subcategoryId: data.format.subcategory?.id,
        showOnPrintCalendar: false,
        showOnDigitalSignage: true,
        eventTags: [],
        recurrenceId: null,
        secondaryImages:
          data.description.secondaryImages?.map((img) => img.id) || [],
        positionImageAboveWeatherBar: data.format.positionImageAboveWeatherBar,
      };

      if (announcementId) {
        await updateAnnouncement({ id: announcementId, ...announcement });
      } else {
        await createAnnouncement(announcement);
      }
      onSave();
    } catch (error) {
      console.error('Error saving slide:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if ((announcementId && isAnnouncementLoading) || isSiteLoading || !site) {
    return (
      <div className="flex justify-center items-center h-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <SlideForm
      onSave={handleSubmit}
      onCancel={onCancel}
      organizationId={organizationId}
      existingAnnouncement={existingAnnouncement}
      steps={steps}
      isSubmitting={isSubmitting}
      site={site}
    />
  );
}
