import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Switch,
  TextField,
  IconButton,
  CircularProgress,
  Typography,
  Box,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';
import { useEventSlideSettings } from '../../event-slide/queries/use-event-slide.queries';
import { useUpdateEventSlideSettings } from '../../event-slide/queries/use-event-slide.mutations';

interface EventSlideModalProps {
  open: boolean;
  onClose: () => void;
  siteId: number;
}

interface EventSlideForm {
  showEventSlide: boolean;
  displayDuration: number;
}

export const EventSlideModal: React.FC<EventSlideModalProps> = ({
  open,
  onClose,
  siteId,
}) => {
  const { control, setValue, handleSubmit } = useForm<EventSlideForm>({
    defaultValues: {
      showEventSlide: false,
      displayDuration: 15,
    },
  });

  const {
    data: settings,
    isLoading,
    error: queryError,
  } = useEventSlideSettings(siteId);

  const {
    mutate: updateSettings,
    isPending: isSaving,
    error: mutationError,
  } = useUpdateEventSlideSettings();

  useEffect(() => {
    if (settings) {
      setValue('showEventSlide', settings.showEventSlide);
      setValue('displayDuration', settings.displayDuration);
    }
  }, [settings, setValue]);

  const onSubmit = (data: EventSlideForm) => {
    updateSettings(
      {
        siteId,
        showEventSlide: data.showEventSlide,
        displayDuration: Number(data.displayDuration),
      },
      {
        onSuccess: onClose,
      },
    );
  };

  const error = queryError || mutationError;
  const loading = isLoading || isSaving;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      className="rounded-lg"
    >
      <DialogTitle className="flex justify-between items-center">
        <Typography variant="h5">Manage Event Slide</Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {isLoading ? (
          <Box className="flex justify-center items-center py-8">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box className="py-4">
              <Typography>
                Displays a slide for today's and a slide for tomorrows events in
                the TV app.
              </Typography>
            </Box>

            <Box className="flex items-center">
              <Box className="w-1/2">
                <Controller
                  name="showEventSlide"
                  control={control}
                  render={({ field }) => (
                    <FormControl>
                      <Box className="flex items-center">
                        <Switch
                          {...field}
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                        <Typography>Display Event Slides</Typography>
                      </Box>
                    </FormControl>
                  )}
                />
              </Box>

              <Box className="w-1/2">
                <Controller
                  name="displayDuration"
                  control={control}
                  rules={{ required: true, min: 1 }}
                  render={({ field, fieldState }) => (
                    <FormControl fullWidth>
                      <Box className="flex items-center mb-2">
                        <Typography>Display Duration</Typography>
                        <Box
                          className={clsx(
                            'text-gray-300 ml-[10px] inline-block cursor-help',
                          )}
                          title="How many seconds should the slide show each time it displays?"
                        >
                          <Typography variant="body2">?</Typography>
                        </Box>
                      </Box>
                      <TextField
                        {...field}
                        type="number"
                        size="small"
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error
                            ? 'Display duration is required and must be greater than 0'
                            : ''
                        }
                      />
                    </FormControl>
                  )}
                />
              </Box>
            </Box>

            {error && (
              <Typography color="error" className="mt-4">
                {error.message}
              </Typography>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions className="p-4">
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          disabled={loading}
          className="bg-black hover:bg-gray-800"
        >
          {loading ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
