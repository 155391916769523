import { format, isBefore } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Announcement } from '@core/interfaces/api';

export const getTimezone = (announcement: Announcement): string => {
  return announcement.site?.timezone ?? null;
};

export const getTimeRange = (announcement: Announcement): string => {
  if (!announcement.eventStart || !announcement.eventEnd) {
    return null;
  }
  return `${getTime(
    new Date(announcement.eventStart),
    getTimezone(announcement),
  )} - ${getTime(new Date(announcement.eventEnd), getTimezone(announcement))}`;
};

export const getEventDate = (announcement: Announcement): string => {
  if (!announcement.eventStart) {
    return null;
  }
  const date = new Date(announcement.eventStart);
  return format(date, 'EEE, MMM d');
};

export const getStyleType = (announcement: Announcement): string => {
  return announcement.style?.name ?? '';
};

export const isIndividualSpotlight = (announcement: Announcement): boolean =>
  announcement.styleId === 1;
export const isEvent = (announcement: Announcement): boolean =>
  announcement.styleId === 3;
export const isAnnouncement = (announcement: Announcement): boolean =>
  announcement.styleId === 4;
export const isSpecialAnnouncement = (announcement: Announcement): boolean =>
  announcement.styleId === 5;
export const isNameInLights = (announcement: Announcement): boolean =>
  announcement.styleId === 7;
export const isTitleSlide = (announcement: Announcement): boolean =>
  announcement.styleId === 12;
export const isCollage = (announcement: Announcement): boolean =>
  announcement.styleId === 13;
export const isGif = (announcement: Announcement): boolean =>
  announcement.styleId === 30;

export const isPublished = (announcement: Announcement): boolean => {
  if (!announcement.startShowing || !announcement.stopShowing) {
    return false;
  }

  const now = new Date();
  const startDate = new Date(announcement.startShowing);
  const stopDate = new Date(announcement.stopShowing);

  if (isBefore(startDate, now)) {
    return false;
  }

  if (isBefore(stopDate, now)) {
    return false;
  }

  return true;
};

export const isPastEvent = (announcement: Announcement): boolean => {
  if (!isEvent(announcement) || !announcement.eventEnd) {
    return false;
  }

  const endDate = new Date(announcement.eventEnd);
  const now = new Date();
  return isBefore(endDate, now);
};

export const isCustomRoom = (announcement: Announcement): boolean =>
  announcement.room?.id === 1;

export const isVideoRoom = (announcement: Announcement): boolean =>
  announcement.room?.id === 2;

export const isDefinedRoom = (announcement: Announcement): boolean =>
  !isCustomRoom(announcement) && !isVideoRoom(announcement);

export const getPlace = (announcement: Announcement): string => {
  if (!isEvent(announcement)) {
    return null;
  }
  if (isCustomRoom(announcement)) {
    return announcement.location;
  }
  return announcement.room?.name;
};

export const clearUnusedSlideData = (announcement: Announcement): void => {
  if (!isEvent(announcement)) {
    announcement.eventEnd = null;
    announcement.eventStart = null;
    announcement.location = null;
    announcement.videoUrl = null;
    announcement.room = null;
  }
};

export const getTime = (date: Date, timezone: string): string => {
  if (!timezone) return format(date, 'h:mma').toLowerCase();

  const zonedDate = zonedTimeToUtc(date, timezone);
  return format(zonedDate, 'h:mma').toLowerCase();
};
