import { Image } from '@core/interfaces/api';
import React from 'react';
import clsx from 'clsx';
import { CommonSlideProps } from '../slide-preview';
interface CollageSlideProps extends CommonSlideProps {
  images: Image[];
  title: string;
  content: string;
  numberOfImages?: number;
  preview?: boolean;
}

export function CollageSlide({
  images,
  title,
  content,
  numberOfImages = 1,
  backgroundImage,
  preview = false,
}: CollageSlideProps) {
  // Sort images by position and create array of length numberOfImages
  const sortedImages = [...images].sort((a, b) => a.position - b.position);

  return (
    <div
      className="absolute inset-0"
      style={
        backgroundImage
          ? {
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }
          : undefined
      }
    >
      {/* Title section */}
      <div
        className={clsx(
          'bg-black/60 w-[34%] h-full p-[5%] absolute top-0 left-0 flex items-center',
          preview && 'h-full',
        )}
      >
        <div className="w-full">
          <h1
            className={clsx(
              'font-black font-sans text-white text-left mb-[1vmin] leading-tight uppercase',
              preview ? 'text-[2.8vmin]' : 'text-[5.6vmin]',
            )}
          >
            {title}
          </h1>
          <h2
            className={clsx(
              'text-white text-left font-serif leading-tight break-words',
              preview ? 'text-[1.95vmin]' : 'text-[3.9vmin]',
            )}
          >
            {content}
          </h2>
        </div>
      </div>

      {/* Image grid */}
      <div
        className={clsx(
          'w-[66%] h-full absolute top-0 right-0 p-[2%] flex flex-row',
          preview ? 'space-x-4' : 'space-x-11',
        )}
      >
        {/* First column */}
        <div className="grow h-full w-1/2 flex flex-col space-y-7 overflow-hidden">
          {sortedImages[0] ? (
            <img
              src={sortedImages[0].downloadUrl}
              className={`grow w-full border-[9px] border-white object-cover object-center ${
                numberOfImages <= 2 ? 'h-full' : 'h-[46%]'
              }`}
              alt=""
            />
          ) : (
            <div
              className={`grow w-full border-[9px] border-white bg-black/25 flex items-center justify-center text-white text-[130px] ${
                numberOfImages <= 2 ? 'h-full' : 'h-[46%]'
              }`}
            >
              1
            </div>
          )}
          {numberOfImages === 4 &&
            (sortedImages[3] ? (
              <img
                src={sortedImages[3].downloadUrl}
                className="grow w-full h-[46%] border-[9px] border-white object-cover object-center"
                alt=""
              />
            ) : (
              <div className="grow w-full h-[46%] border-[9px] border-white bg-black/25 flex items-center justify-center text-white text-[130px]">
                4
              </div>
            ))}
        </div>

        {/* Second column */}
        {numberOfImages > 1 && (
          <div className="grow h-full w-1/2 flex flex-col space-y-7 overflow-hidden">
            {sortedImages[1] ? (
              <img
                src={sortedImages[1].downloadUrl}
                className="grow w-full h-[46%] border-[9px] border-white object-cover object-center"
                alt=""
              />
            ) : (
              <div className="grow w-full h-[46%] border-[9px] border-white bg-black/25 flex items-center justify-center text-white text-[130px]">
                2
              </div>
            )}
            {numberOfImages > 2 &&
              (sortedImages[2] ? (
                <img
                  src={sortedImages[2].downloadUrl}
                  className="grow w-full h-[46%] border-[9px] border-white object-cover object-center"
                  alt=""
                />
              ) : (
                <div className="grow w-full h-[46%] border-[9px] border-white bg-black/25 flex items-center justify-center text-white text-[130px]">
                  3
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}
