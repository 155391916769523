import { Auth } from '@angular/fire/auth';
import { createApi } from '@core/api/base-api';
import { HttpParams } from '@angular/common/http';
import { Announcement, CreateAnnouncementPayload } from '@core/interfaces/api';

export interface PaginationParams {
  page: number;
  pageSize: number;
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
  search?: string;
  status?: 'all' | 'active' | 'past' | 'future';
}

export interface AnnouncementsResponse {
  data: Announcement[];
  totalCount: number;
}

export const createAnnouncementsApi = (auth: Auth) => {
  const api = createApi(auth);

  return {
    getAnnouncements: async (
      siteId: number,
      params: PaginationParams,
    ): Promise<AnnouncementsResponse> => {
      let queryParams = new HttpParams()
        .set('offset', params.page.toString())
        .set('limit', params.pageSize.toString());

      if (params.sortField) {
        queryParams = queryParams
          .set('sortField', params.sortField)
          .set('sortOrder', params.sortOrder || 'asc');
      }

      if (params.search?.trim()) {
        queryParams = queryParams
          .set('search', params.search)
          .set('searchBy', JSON.stringify(['title', 'content']));
      }

      if (params.status) {
        queryParams = queryParams.set('status', params.status);
        const now = new Date().getTime();

        if (params.status === 'active') {
          queryParams = queryParams
            .set('startShowing', now.toString())
            .set('stopShowing', now.toString());
        } else if (params.status === 'past') {
          queryParams = queryParams.set('stopShowing', now.toString());
        } else if (params.status === 'future') {
          queryParams = queryParams.set('startShowing', now.toString());
        }
      }

      return api.get<AnnouncementsResponse>(`/announcements/site/${siteId}`, {
        params: queryParams,
      });
    },
    updateAnnouncementOrder: (siteId: number, announcementIds: number[]) =>
      api.put<void>(`/announcements/site/${siteId}/order`, { announcementIds }),
    createAnnouncement: (
      payload: CreateAnnouncementPayload,
    ): Promise<Announcement> =>
      api.post<Announcement>('/announcements', payload),

    updateAnnouncement: (
      id: number,
      payload: CreateAnnouncementPayload,
    ): Promise<Announcement> =>
      api.put<Announcement>(`/announcements/update/${id}`, payload),

    getAnnouncementById: (id: number): Promise<Announcement> =>
      api.get<Announcement>(`/announcements/${id}`),

    deleteAnnouncement: (id: number): Promise<void> =>
      api.put<void>(`/announcements/update/${id}`, { deleted: true }),
  };
};

// Angular compatibility layer
export class AnnouncementsApi {
  private readonly api: ReturnType<typeof createAnnouncementsApi>;

  constructor(private auth: Auth) {
    this.api = createAnnouncementsApi(auth);
  }

  getAnnouncements(
    siteId: number,
    params: PaginationParams,
  ): Promise<AnnouncementsResponse> {
    return this.api.getAnnouncements(siteId, params);
  }

  createAnnouncement(
    payload: CreateAnnouncementPayload,
  ): Promise<Announcement> {
    return this.api.createAnnouncement(payload);
  }

  updateAnnouncement(
    id: number,
    payload: CreateAnnouncementPayload,
  ): Promise<Announcement> {
    return this.api.updateAnnouncement(id, payload);
  }

  getAnnouncementById(id: number): Promise<Announcement> {
    return this.api.getAnnouncementById(id);
  }

  deleteAnnouncement(id: number): Promise<void> {
    return this.api.deleteAnnouncement(id);
  }
}
