import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { createApi } from '@core/api/base-api';
import type { Image } from '@core/interfaces/api';

export const createImageUploadApi = (auth: Auth) => {
  const api = createApi(auth);

  return {
    createImageRecord: (
      downloadUrl: string,
      path: string,
      siteId: number,
      styleId: number,
      announcementId?: number,
    ) => {
      const body = {
        image: {
          path,
          downloadUrl,
          site: siteId,
          style: styleId,
          announcement: announcementId,
        },
      };

      return api.post<Image>('/image', body);
    },
  };
};

// Angular compatibility layer (temporary)
@Injectable({ providedIn: 'root' })
export class ImageUploadApi {
  private readonly api = createImageUploadApi(this.auth);

  constructor(private auth: Auth) {}

  createImageRecord = this.api.createImageRecord;
}
