import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { parseISO } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

// import { MbscEventcalendarOptions } from '@mobiscroll/angular'; TURN ON TO VALIDATE OPTIONS AS NEEDED. Too large to justify keeping all the time
import { ActivityService } from 'src/app/core/services/activity.service';
import { MatDialog } from '@angular/material/dialog';
import { EventFormModalComponent } from 'src/app/manage/activities/event-form-modal-component/event-form-modal.component';
import {
  EventColors,
  EventType,
} from '../../../../../../../../core/enums/event-type';
import { AlertService } from 'src/app/core/services/alert.service';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogValues,
} from 'src/app/shared/modals/confirmation-dialog/confirmation-dialog.component';
import { ManageService } from 'src/app/core/services/manage.service';
import {
  DeleteRecurrenceDialogComponent,
  EventDeleteDialogValues,
} from 'src/app/shared/modals/delete-recurrence-modal/delete-recurrence-dialog.component';
import { RecurrenceService } from 'src/app/core/services/recurrence.service';
import { EventTag } from '@models/eventTag';

@Component({
  selector: 'app-event-page-agenda',
  templateUrl: './event-page-agenda.component.html',
  styleUrls: ['./event-page-agenda.component.scss'],
})

// Event calendar view. Includes controls for changing the active month, adding events, deleting events
export class EventPageAgendaComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject();
  public events: BehaviorSubject<any[]> = new BehaviorSubject([]);
  @Output() changeMonth = new EventEmitter();
  @Output() save = new EventEmitter();
  @Input() months: Array<Date>;
  @Input() activeMonth: Date;
  @Input() printCalendarRestrictions: {
    monthData;
    maxEventRows;
    eventRowLimits;
  };
  @Input() timezone;
  eventTypes = EventType;
  options = {
    view: {
      // calendar: { type: 'month', labels: true },
      agenda: { type: 'month', size: 1 },
    },
    theme: 'goodman',
    dateFormatLong: 'DDDD, MMM D, YYYY',
    noEventsText: 'No scheduled events',
    eventOrder: this.sortEvents,
  };
  selectedDate = new Date();
  monthIndex = 12;
  public loading = true;
  constructor(
    public activityService: ActivityService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private manageService: ManageService,
    private recurrenceService: RecurrenceService,
  ) {}

  /**
   * We sort our events correctly on the BE. We need to override mobiscroll's default behavior of
   * sorting by all day events, then time, then alphabetically by title.
   **/
  sortEvents() {
    return 1;
  }

  ngOnInit(): void {
    this.activityService.activities
      .pipe(
        map((activities) => {
          let mappedToCalEvents = activities.map((activity, index) => {
            // Properly convert UTC times to site's timezone
            const eventStartDate = parseISO(activity.eventStart.toString());
            const eventEndDate = parseISO(activity.eventEnd.toString());

            // Use utcToZonedTime to correctly convert from UTC to site's timezone
            const startInSiteTimezone = utcToZonedTime(
              eventStartDate,
              this.timezone,
            );
            const endInSiteTimezone = utcToZonedTime(
              eventEndDate,
              this.timezone,
            );

            const formattedActivity = {
              start: startInSiteTimezone,
              end: endInSiteTimezone,
              title: activity.title,
              allDay: activity.allDay,
              icon: (activity?.eventTags[0] as EventTag)?.icon,
              eventTag: activity?.eventTags[0],
              color: EventColors[activity.eventType],
              eventType: activity.eventType,
              location: activity.location,
              room: {
                long: activity.room?.name,
                short:
                  activity.room?.shortName && activity.room?.shortName !== ' - '
                    ? activity.room.shortName
                    : null,
              },
              description: activity.content,
              showOnPrintCalendar: activity.showOnPrintCalendar,
              showOnDigitalSignage: activity.showOnDigitalSignage,
              src: activity,
              recurrence: activity?.recurrence
                ? this.recurrenceService.prettyPrintRecurrenceRecipe({
                    ...activity.recurrence,
                  })
                : null,
            };

            return formattedActivity;
          });
          return mappedToCalEvents;
        }),
        distinctUntilChanged((prev, curr) => {
          if (prev.length !== curr.length) return false;

          for (let i = 0; i < prev.length; i++) {
            if (prev[i].src.id !== curr[i].src.id) return false;
          }

          return true;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((activities) => {
        this.events.next(activities);
        this.loading = false;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  jumpToThisMonth() {
    this.changeMonth.emit(12);
  }

  handlePageLoading(event): void {
    this.loading = true;
    const activeMonth = this.activeMonth;
    if (!activeMonth) {
      this.alertService.error('Sorry! That date is out of range.');
    }

    const chosenMonth = zonedTimeToUtc(event.month, this.timezone);
    const month = chosenMonth.getMonth() + 1; // date-fns months are zero-based
    const year = chosenMonth.getFullYear();

    if (activeMonth.getMonth() + 1 === month) {
      this.loading = false;
      return;
    }

    const monthIndex = this.months.findIndex((value) => {
      return value.getMonth() + 1 === month && value.getFullYear() === year;
    });

    if (monthIndex < 0) {
      this.alertService.error('An error has occurred.');
      return;
    }

    this.changeMonth.emit(monthIndex);
  }

  public showEditDialog(event): void {
    this.dialog.open(EventFormModalComponent, {
      width: '80vw',
      height: '660px',
      data: {
        event: event.original.src,
        selectedMonth: this.activeMonth,
        months: this.months,
        editing: true,
      },
    });
  }

  public addEvent(): void {
    this.dialog.open(EventFormModalComponent, {
      width: '80vw',
      height: '660px',
      data: {
        selectedMonth: this.activeMonth,
        months: this.months,
      },
    });
  }

  public deleteActivity(event): void {
    const activity = event.original.src;

    if (!activity.recurrence) {
      const confirmData: ConfirmationDialogValues = {
        title: `Delete "${activity.title}"?`,
        message: 'This action can not be undone.',
        yesText: 'Delete',
        onConfirm: async () => {
          await this.manageService.deleteAnnouncement(activity);
        },
        onError: (error) => {
          this.alertService.error(
            `Error deleting "${activity.title}". Please try again.`,
          );
        },
      };
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: confirmData,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.activityService.refreshActivities(this.activeMonth, false);
          this.alertService.success(`"${activity.title}" deleted.`);
        }
      });
    } else {
      const confirmData: EventDeleteDialogValues = {
        title: `Delete "${activity.title}"?`,
        message: 'This is a recurring event.',
        yesText: 'Delete',
        onError: (error) => {
          this.alertService.error(
            `Error deleting "${activity.title}". Please try again.`,
          );
        },
        onSuccess: async () => {
          await this.activityService.refreshActivities(this.activeMonth, false);
        },
        activity,
      };
      const dialogRef = this.dialog.open(DeleteRecurrenceDialogComponent, {
        data: confirmData,
      });
    }
  }
}
