import { Auth } from '@angular/fire/auth';
import { createApi } from '@core/api/base-api';
import type { Site, CreateSitePayload } from '@core/interfaces/api';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { PaginationInformation } from '@shared/pagination-server-side/datasources/genericBE.datasource';

export const createSitesApi = (auth: Auth) => {
  const api = createApi(auth);

  return {
    getSitesByOrgId: (orgId: string) =>
      api.get<Site[]>(`/site/organization/${orgId}/all`),

    getSite: async (id: number) => {
      try {
        if (!id) {
          console.error('Invalid site ID:', id);
          throw new Error('Invalid site ID');
        }
        const response = await api.get<Site>(`/site/${id}`);
        return response;
      } catch (error) {
        console.error(`Error fetching site with ID ${id}:`, error);
        throw error;
      }
    },

    updateSite: (id: number, site: Partial<Site>) =>
      api.put<Site>(`/site/${id}`, site),

    createSite: (site: CreateSitePayload) => api.post<Site>('/site', site),

    deleteSite: (id: string) => api.put<null>(`/api/v1/site/${id}`, {}),

    getSitesPaginated: (
      organizationId: number,
      queryParams: HttpParams,
      pagination: PaginationInformation,
    ) => {
      const { search } = pagination.filters;
      const { active, direction } = pagination.sort || {};

      let params = {};
      queryParams.keys().forEach((key) => {
        params[key] = queryParams.get(key);
      });

      if (search?.trim().length > 0) {
        params['searchBy'] = '["name", "state"]';
      }

      params['sortField'] = active || 'name';
      params['sortOrder'] = (direction || 'asc').toLowerCase();

      return api.get<{ data: Site[]; totalCount: number }>(
        `/site/organization/${organizationId}`,
        { params },
      );
    },
  };
};

// Angular compatibility layer (temporary)
@Injectable({ providedIn: 'root' })
export class SitesApi {
  private readonly api = createSitesApi(this.auth);

  constructor(private auth: Auth) {}

  getSitesByOrgId = this.api.getSitesByOrgId;
  getSite = this.api.getSite;
  updateSite = this.api.updateSite;
  createSite = this.api.createSite;
  deleteSite = this.api.deleteSite;
  getSitesPaginated = this.api.getSitesPaginated;
}
