import React from 'react';
import { useDropzone } from 'react-dropzone';
import { CircularProgress, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

interface ImageUploadAreaProps {
  onUpload: (file: File) => Promise<void>;
  isUploading: boolean;
  progress?: number;
  accept?: string[];
  maxFiles?: number;
  currentFiles?: number;
}

export function ImageUploadArea({
  onUpload,
  isUploading,
  progress = 0,
  accept = ['.jpg', '.jpeg', '.png', '.gif', '.tif'],
  maxFiles = 1,
  currentFiles = 0,
}: ImageUploadAreaProps) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: accept.reduce((acc, curr) => ({ ...acc, [curr]: [] }), {}),
    multiple: false,
    disabled: isUploading,
    onDrop: async (files) => {
      if (files?.[0]) {
        await onUpload(files[0]);
      }
    },
  });

  return (
    <div
      {...getRootProps()}
      className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer hover:border-primary-500 transition-colors"
    >
      <input {...getInputProps()} />
      {isUploading ? (
        <div className="flex flex-col items-center gap-2">
          <CircularProgress size={24} value={progress} />
          <Typography>Uploading...</Typography>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-2">
          <CloudUploadIcon fontSize="large" />
          <Typography>
            Drag and drop an image here, or click to select
          </Typography>
        </div>
      )}
    </div>
  );
}
