import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { createRoot } from 'react-dom/client';
import { AddSlideReact } from './add-slide.page';
import * as React from 'react';
import { SiteService } from '@core/services/site.service';
import { Site } from '@core/interfaces/api';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ReactProvider } from '@shared/react-components/react-provider';

@Component({
  selector: 'app-add-announcement-react',
  template: '<div class="h-full w-full" #reactRoot></div>',
})
export class AddAnnouncementReactWrapperComponent
  implements OnInit, OnDestroy, OnChanges
{
  @ViewChild('reactRoot', { static: true }) containerRef!: ElementRef;

  private root: any;
  private currentSite: Site | null = null;
  private siteSubscription: Subscription;
  private announcementId: number | null = null;
  constructor(
    private siteService: SiteService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.root = createRoot(this.containerRef.nativeElement);

    // Subscribe to site changes
    this.siteSubscription = this.siteService.site.subscribe((site) => {
      this.currentSite = site;
      this.render();
    });

    this.route.params.subscribe((params) => {
      this.announcementId = params['announcementId'];
      this.render();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.root) return;
    this.render();
  }

  ngOnDestroy() {
    if (this.root) {
      this.root.unmount();
    }
    if (this.siteSubscription) {
      this.siteSubscription.unsubscribe();
    }
  }

  private handleSave = () => {
    this.router.navigate([
      'manage/content',
      this.currentSite?.id,
      'announcements',
    ]);
  };

  private handleCancel = () => {
    this.router.navigate([
      'manage/content',
      this.currentSite?.id,
      'announcements',
    ]);
  };

  private render() {
    this.root.render(
      React.createElement(
        ReactProvider,
        null,
        React.createElement(AddSlideReact, {
          siteId: this.currentSite?.id,
          organizationId: this.currentSite?.organizationId,
          onSave: this.handleSave,
          onCancel: this.handleCancel,
          announcementId: this.announcementId,
        }),
      ),
    );
  }
}
