import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AlertService } from 'src/app/core/services/alert.service';
import { SiteService } from 'src/app/core/services/site.service';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { SiteDto } from '@interfaces/site';

@Component({
  selector: 'app-calendar-dashboard',
  templateUrl: './calendar-dashboard.component.html',
  styleUrls: ['./calendar-dashboard.component.scss'],
})
export class CalendarDashboardComponent implements OnInit {
  @Input() activeMonth;
  @Input() set events(events) {
    // Will be adding more parsing here as the dashboard is built out
    this.eventTotal = events.length;
  }
  public eventTypes = new BehaviorSubject({});
  public eventTotal;
  public calendarForm: UntypedFormGroup;

  public saving;
  public notifying;

  constructor(
    public siteService: SiteService,
    public alertService: AlertService,
    public formBuilder: UntypedFormBuilder,
    public calendarService: CalendarService,
  ) {
    this.calendarForm = this.formBuilder.group(
      {
        printCalendarMessage: '',
      },
      {
        validators: [Validators.maxLength(500)],
      },
    );
  }

  ngOnInit() {
    if (this.siteService.currentSite?.printCalendarMessage) {
      this.calendarForm.setValue({
        printCalendarMessage: this.siteService.currentSite.printCalendarMessage,
      });
    }
  }

  async saveNote() {
    try {
      await this.siteService.updateSite(this.siteService.currentSite.id, {
        ...this.siteService.currentSite,
        ...this.calendarForm.value,
      });

      await this.siteService.setSite(this.siteService.currentSite.id);
    } catch (error) {
      throw error;
    }
  }

  async save() {
    this.saving = true;
    try {
      await this.saveNote();

      this.alertService.success('Successfully saved note!');
    } catch (error) {
      this.alertService.error('There was a problem saving your message.');
    } finally {
      this.saving = false;
    }
  }

  async saveAndNotify() {
    this.notifying = true;
    try {
      await this.saveNote();
      await this.calendarService.emailCalendar({
        notes: this.calendarForm.value.printCalendarMessage,
        site: this.siteService.currentSite as unknown as SiteDto,
        calendarStart: new Date(this.activeMonth).getTime().toString(),
      });
      this.siteService.setSite(this.siteService.currentSite.id);
      this.alertService.success('Successfully saved note and notified editor!');
    } catch (error) {
      this.alertService.error('There was a problem notifying the Editor.');
    } finally {
      this.notifying = false;
    }
  }
}
