import { Auth, getIdToken } from '@angular/fire/auth';
import { environment } from '@env/environment';
import { HttpParams } from '@angular/common/http';

export type ApiError = {
  status: number;
  message: string;
};

export type FetchOptions = RequestInit & {
  params?: Record<string, string> | HttpParams;
  requiresAuth?: boolean;
};

const createApiError = (status: number, message: string): ApiError => ({
  status,
  message,
});

const getAuthHeaders = async (auth: Auth): Promise<HeadersInit> => {
  const user = auth.currentUser;
  if (!user) return {};

  const token = await getIdToken(user, true);
  return {
    Authorization: `Bearer ${token}`,
  };
};

const handleResponse = async <T>(response: Response): Promise<T> => {
  if (!response.ok) {
    const error = await response.json().catch(() => ({}));
    throw createApiError(
      response.status,
      error.message || `HTTP error! status: ${response.status}`,
    );
  }

  if (response.status === 204) {
    return null as T;
  }

  const contentType = response.headers.get('content-type');
  if (contentType?.includes('text/plain')) {
    return response.text() as Promise<T>;
  }

  return response.json();
};

const createApiFetch = (auth: Auth) => {
  return async <T>(
    endpoint: string,
    options: FetchOptions = {},
  ): Promise<T> => {
    const { params, requiresAuth = true, ...init } = options;

    const url = new URL(`${environment.apiv3Url}${endpoint}`);
    if (params) {
      if (params instanceof HttpParams) {
        params.keys().forEach((key) => {
          url.searchParams.append(key, params.get(key)!);
        });
      } else {
        Object.entries(params).forEach(([key, value]) => {
          url.searchParams.append(key, value);
        });
      }
    }

    const headers: HeadersInit = {
      'Content-Type': 'application/json',
      ...(requiresAuth ? await getAuthHeaders(auth) : {}),
      ...init.headers,
    };

    const response = await fetch(url.toString(), {
      ...init,
      headers,
    });
    return handleResponse<T>(response);
  };
};

export const createApi = (auth: Auth) => {
  const apiFetch = createApiFetch(auth);

  return {
    get: <T>(
      endpoint: string,
      options?: Omit<FetchOptions, 'body' | 'method'>,
    ) => apiFetch<T>(endpoint, { ...options, method: 'GET' }),

    post: <T>(
      endpoint: string,
      data?: unknown,
      options?: Omit<FetchOptions, 'body' | 'method'>,
    ) =>
      apiFetch<T>(endpoint, {
        ...options,
        method: 'POST',
        body: data ? JSON.stringify(data) : undefined,
      }),

    put: <T>(
      endpoint: string,
      data?: unknown,
      options?: Omit<FetchOptions, 'body' | 'method'>,
    ) =>
      apiFetch<T>(endpoint, {
        ...options,
        method: 'PUT',
        body: data ? JSON.stringify(data) : undefined,
      }),

    delete: <T>(
      endpoint: string,
      options?: Omit<FetchOptions, 'body' | 'method'>,
    ) => apiFetch<T>(endpoint, { ...options, method: 'DELETE' }),
  };
};
