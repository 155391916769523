import {
  Box,
  Paper,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { DigitalSignageHeader } from './components/digital-signage-header';
import { Announcement } from '@core/interfaces/api';
import React, { useState } from 'react';
import { useAnnouncementsQuery } from '@features/announcements/queries/announcements.queries';
import { SlideTable } from './slide-table/slide-table';
import { SlideGrid } from './slide-table/components/slide-grid/slide-grid';
import { useAnnouncementsMutation } from '@features/announcements/queries/announcements.mutations';
import { isSpecialAnnouncement } from '@features/announcements/utils';

interface DigitalSignagePageProps {
  onEdit: (announcement: Announcement) => void;
  onDelete: (announcement: Announcement) => void;
  siteId: number;
  onAddSlide: () => void;
}

export function DigitalSignagePage({
  onEdit,
  onDelete,
  siteId,
  onAddSlide,
}: DigitalSignagePageProps) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortField, setSortField] = useState<string>('startShowing');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState<'all' | 'active' | 'past' | 'future'>(
    'active',
  );
  const { data, isLoading, error, refetch } = useAnnouncementsQuery({
    siteId,
    page,
    rowsPerPage,
    sortField,
    sortOrder,
    search,
    status,
  });
  const [toggleTable, setToggleTable] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [announcementToDelete, setAnnouncementToDelete] =
    useState<Announcement | null>(null);
  const { deleteAnnouncement } = useAnnouncementsMutation({ siteId });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value.toLowerCase());
    setPage(0);
  };

  const handleStatusChange = (
    event: React.SyntheticEvent,
    newValue: 'all' | 'active' | 'past' | 'future',
  ) => {
    setStatus(newValue);
    setPage(0);
  };

  async function handleDelete(announcement: Announcement) {
    setAnnouncementToDelete(announcement);
    setDeleteDialogOpen(true);
  }

  async function confirmDelete() {
    if (!announcementToDelete) return;

    try {
      await deleteAnnouncement(announcementToDelete.id);
      onDelete(announcementToDelete);
      setDeleteDialogOpen(false);
      setAnnouncementToDelete(null);
    } catch (error) {
      console.error('Error deleting announcement:', error);
    }
  }

  // Filter data for special announcements when status is "active"
  const getFilteredData = () => {
    if (!data?.data) return [];

    // If status is active and there's a special announcement, only show that one
    if (status === 'active') {
      const specialAnnouncement = data.data.find((announcement) =>
        isSpecialAnnouncement(announcement),
      );
      if (specialAnnouncement) {
        return [specialAnnouncement];
      }
    }

    return data.data;
  };

  const filteredData = getFilteredData();

  return (
    <Box className="w-full">
      <Paper className="rounded-none bg-[#0a0a0a]">
        <DigitalSignageHeader
          status={status}
          handleStatusChange={handleStatusChange}
          data={data}
          onAddSlide={onAddSlide}
          search={search}
          handleSearch={handleSearch}
          siteId={siteId}
          toggleTable={toggleTable}
          setToggleTable={setToggleTable}
        />
        {toggleTable ? (
          <SlideTable
            data={filteredData}
            sortField={sortField}
            sortOrder={sortOrder}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            status={status}
            isLoading={isLoading}
            error={error}
            onEdit={onEdit}
            onDelete={handleDelete}
            siteId={siteId}
          />
        ) : (
          <SlideGrid
            data={filteredData}
            onEdit={onEdit}
            onDelete={handleDelete}
            siteId={siteId}
            isLoading={isLoading}
            error={error}
          />
        )}
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 50]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="sticky bottom-0"
          count={
            status === 'active' && filteredData.length === 1
              ? 1
              : data?.totalCount || 0
          }
          onPageChange={handleChangePage}
          page={page}
          rowsPerPage={rowsPerPage}
          component="div"
        />
      </Paper>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>
          Delete "{announcementToDelete?.title || 'Slide'}"?
        </DialogTitle>
        <DialogContent>
          <Typography>
            The announcement will no longer be displayed or accessible by
            administrators.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
