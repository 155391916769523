import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { Participant } from '@models/participant';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { SiteService } from './site.service';
import { AuthService } from './auth.service';
import { PaginationInformation } from 'src/app/shared/pagination-server-side/datasources/genericBE.datasource';
import { ParticipantDto } from '@interfaces/participant';

@Injectable({
  providedIn: 'root',
})
export class ParticipantService {
  private _siteParticipants = new BehaviorSubject<Participant[]>([]);
  public readonly siteParticipants = this._siteParticipants.asObservable();

  private _orgParticipants = new BehaviorSubject<Participant[]>([]);
  public readonly orgParticipants = this._orgParticipants.asObservable();

  constructor(
    private http: HttpClient,
    private siteService: SiteService,
    private authService: AuthService,
  ) {
    // Our current system doesn't allow us to change organizations without exiting the app.
    // As such, we only need to subscribe to site-level changes.
    this._listenForSiteUpdates();
  }

  private _listenForSiteUpdates(): void {
    this.siteService.siteId.subscribe((siteId: number) => {
      if (siteId) {
        this._refreshParticipants(siteId);
      }
    });
  }

  // repeated auth check
  private _checkIfOrgAdmin(): Boolean {
    if (
      this.authService.currentUser &&
      this.authService.currentUser.isOrgAdmin &&
      this.authService.currentOrgId
    ) {
      return true;
    } else {
      return false;
    }
  }

  /** Used to respond to site changes (including initial site selection)
   *  Depending on auth status, different endpoints will be used.
   */
  private _refreshParticipants(
    siteId?,
    hardOrgRefresh?: boolean,
  ): Promise<void> {
    if (this._checkIfOrgAdmin()) {
      const participants = this._orgParticipants.getValue();

      // if org admin but no participants found yet, refresh to get starting values
      if (participants.length === 0 || hardOrgRefresh) {
        // use org endpoint for list
        return this._refreshOrgParticipants(this.authService.currentOrgId);
      } else {
        // already have all org values, filter for site-specific updates
        return this._participantsUpdate(
          this._orgParticipants.getValue(),
          siteId,
        );
      }
    } else if (siteId) {
      // use site endpoint for list
      return this._refreshSiteParticipants(siteId);
    }
  }

  private _refreshSiteParticipants(siteId: number): Promise<void> {
    if (siteId && this.authService.currentUser) {
      return this._getSiteParticipants(siteId)
        .then((participants: Participant[]) => {
          this._participantsUpdate(participants, siteId);
        })
        .catch((error) => console.error(error));
    }
  }

  private _refreshOrgParticipants(orgId: number): Promise<void> {
    // Ensure user is Org Admin and org id matches auth service's
    if (
      orgId &&
      this._checkIfOrgAdmin() &&
      this.authService.currentOrgId === orgId
    ) {
      return this._getOrgParticipants(orgId)
        .then((participants: Participant[]) => {
          this._participantsUpdate(participants);
        })
        .catch((error) => console.error(error));
    }
  }

  private _participantsUpdate(
    participants: Participant[],
    siteId?,
  ): Promise<void> {
    if (participants.length === 0) {
      this._siteParticipants.next([]);
      return;
    }

    const siteParticipants = participants.filter((participant) => {
      return (
        participant.siteId ===
        (siteId ? siteId : this.siteService.currentSiteId)
      );
    });

    // if org admin, we expect the results from the endpoint to be org-wide
    if (this._checkIfOrgAdmin()) {
      this._orgParticipants.next(participants);
    }

    // regardless of auth status, everyone should get site-relevant participants
    this._siteParticipants.next(siteParticipants);

    return Promise.resolve();
  }

  private _getSiteParticipants(siteId: number): Promise<Participant[]> {
    const url = `${environment.apiv3Url}/participants/site/${siteId}`;
    return this._getParticipants(url);
  }

  private _getOrgParticipants(orgId: number): Promise<Participant[]> {
    const url = `${environment.apiv3Url}/participants/org/${orgId}`;
    return this._getParticipants(url);
  }

  private _getParticipants(url): Promise<Participant[]> {
    try {
      return <Promise<Participant[]>>(
        lastValueFrom(
          this.http
            .get(url)
            .pipe(
              map((participants: Participant[]) =>
                participants
                  .map((participant) => new Participant(participant))
                  .filter((participant) => participant),
              ),
            ),
        )
      );
    } catch (err) {
      console.error('ERROR', err);
      return;
    }
  }

  public refreshParticipants(): Promise<void> {
    // refeshes normally if site-level, or triggers a hard-refresh of org values
    return this._refreshParticipants(this.siteService.currentSiteId, true);
  }

  public getParticipantsPaginated(
    queryParams: HttpParams,
    pagination?: PaginationInformation,
  ): Observable<{
    data: Array<Participant>;
    totalCount: number;
  }> {
    const { search, scope } = pagination.filters;
    let active = null;
    let direction = null;

    if (pagination?.sort) {
      active = pagination.sort.active;
      direction = pagination.sort.direction;
    }

    if (search.trim().length > 0) {
      queryParams = queryParams.append(
        'searchBy',
        '["firstName", "lastName", "propertyName", "email"]',
      );
    }

    queryParams = queryParams.append('sortField', active as string);
    queryParams = queryParams.append('sortOrder', direction.toUpperCase());

    const url =
      scope === 'org'
        ? `${environment.apiv3Url}/participants/organization/${this.siteService.currentSite.organization.id}`
        : `${environment.apiv3Url}/participants/site/${this.siteService.currentSite.id}`;

    try {
      return this.http.get(url, { params: queryParams }).pipe(
        tap((response: { data: Array<Participant>; totalCount }) => {
          response.data = response.data.map((participant: ParticipantDto) => {
            return new Participant(participant);
          });
        }),
      );
    } catch (err) {
      console.error('ERROR', err);

      return;
    }
  }

  public getAllMobileParticipants(
    queryParams: HttpParams,
    pagination?: PaginationInformation,
  ): Observable<Participant[]> {
    const { search, scope } = pagination.filters;

    if (search.trim().length > 0) {
      queryParams = queryParams.append(
        'searchBy',
        '["firstName", "lastName", "propertyName", "email"]',
      );
    }

    const url =
      scope === 'org'
        ? `${environment.apiv3Url}/participants/organization/${this.siteService.currentSite.organization.id}`
        : `${environment.apiv3Url}/participants/site/${this.siteService.currentSite.id}`;

    try {
      return this.http.get(url, { params: queryParams }).pipe(
        map((response: { data: Participant[]; totalCount: number }) => {
          const participants = response.data.map(
            (participant: ParticipantDto) => {
              return new Participant(participant);
            },
          );
          return participants;
        }),
      );
    } catch (err) {
      console.error('ERROR', err);

      return;
    }
  }
}
