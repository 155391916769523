import { Auth } from '@angular/fire/auth';
import { createApi } from 'src/app/core/api/base-api';
import type {
  EventSlide,
  EventSlidePayload,
} from 'src/app/core/interfaces/api';

export const createEventSlideApi = (auth: Auth) => {
  const api = createApi(auth);

  return {
    getEventSlideBySiteId: (siteId: number) => {
      return api.get<EventSlide>(`/event-slide/site/${siteId}`);
    },

    updateEventSlide: (id: number, data: Partial<EventSlide>) =>
      api.put<EventSlide>(`/event-slide/${id}`, data),

    createEventSlide: (data: EventSlidePayload) =>
      api.post<EventSlide>('/event-slide', data),
  };
};

// Angular compatibility layer (temporary)
export class EventSlideApi {
  private readonly api = createEventSlideApi(this.auth);

  constructor(private auth: Auth) {}

  getEventSlideBySiteId = this.api.getEventSlideBySiteId;
  updateEventSlide = this.api.updateEventSlide;
  createEventSlide = this.api.createEventSlide;
}
