import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { Image } from '@core/interfaces/api';
import { createBackgroundImagesApi } from '@features/background-images/api/background-images.api';
import { Auth } from '@angular/fire/auth';
import { auth } from '@core/utils/firebase/firebase';

const backgroundImagesApi = createBackgroundImagesApi(auth as Auth);

export function useSetBackgroundForSubcategory() {
  const queryClient = useQueryClient();

  return useMutation<Image | null, Error, number | undefined>({
    mutationFn: (subcategoryId) => {
      if (!subcategoryId) return Promise.resolve(null);
      return backgroundImagesApi.getBackgroundForSubcategory(subcategoryId);
    },
    onSuccess: (data, subcategoryId) => {
      // Invalidate and refetch relevant queries
      queryClient.invalidateQueries({
        queryKey: ['backgroundImage', 'subcategory', subcategoryId],
      });
    },
  });
}
