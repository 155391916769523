import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import * as React from 'react';
import { Root, createRoot } from 'react-dom/client';

import { Subject } from 'rxjs';

import { EventCalendar } from './components/event-calendar';
import { ReactProvider } from 'src/app/shared/react-components/react-provider';

const containerElementRef = 'eventPageAgendaContainer';

@Component({
  selector: 'app-event-page-agenda-wrapper',
  template: `<span #${containerElementRef}></span>`,
  styleUrls: ['./event-page-agenda.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EventPageAgendaComponentWrapper
  implements OnChanges, OnDestroy, AfterViewInit, OnInit
{
  @ViewChild(containerElementRef, { static: true }) containerRef!: ElementRef;

  @Output() changeMonth = new EventEmitter();
  @Output() deleteActivity = new EventEmitter();
  @Output() showEditDialog = new EventEmitter();
  @Output() pageLoading = new EventEmitter();
  @Input() months: Array<Date>;
  @Input() activeMonth: Date;
  @Input() loading: boolean;
  @Input() activities: any[] = [];
  @Input() printCalendarRestrictions: {
    monthData;
    maxEventRows;
    eventRowLimits;
  };
  @Input() timezone;

  private root: Root;

  destroy$: Subject<boolean> = new Subject();

  constructor() {}

  ngOnInit(): void {}

  jumpToThisMonth = () => {
    this.changeMonth.emit(12);
  };

  handleDeleteActivity = (event): void => {
    this.deleteActivity.emit(event);
  };

  handleShowEditDialog = (event) => {
    this.showEditDialog.emit(event);
  };

  handlePageLoading = (event): void => {
    this.pageLoading.emit(event);
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.root) return;
    this.render();
  }

  ngAfterViewInit() {
    this.root = createRoot(this.containerRef.nativeElement);
    this.render();
  }

  ngOnDestroy() {
    this.root.unmount();
  }

  private render() {
    this.root.render(
      <React.StrictMode>
        <ReactProvider>
          <div>
            <EventCalendar
              events={this.activities}
              selectedDate={this.activeMonth}
              handlePageLoading={this.handlePageLoading}
              loading={this.loading}
              jumpToThisMonth={this.jumpToThisMonth}
              activeMonth={this.activeMonth}
              printCalendarRestrictions={this.printCalendarRestrictions}
              months={this.months}
              deleteActivity={this.handleDeleteActivity}
              showEditDialog={this.handleShowEditDialog}
            />
          </div>
        </ReactProvider>
      </React.StrictMode>,
    );
  }
}
