import { Style } from '@core/interfaces/api';

export const createStyle = (data: Partial<Style>): Style => ({
  id: 0,
  deleted: false,
  createdOn: new Date().toISOString(),
  updatedOn: new Date().toISOString(),
  name: '',
  description: null,
  ...data,
});
