import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  DefaultSlide,
  CollageSlide,
  ActivitiesAndEventsSlide,
  ImageCentricSlide,
  IndividualSpotlightSlide,
  NameInLightsSlide,
  GifCentricSlide,
} from './slides';
import { SlideWrapper } from './slide-wrapper';
import { factsToDescription } from '../utils/spotlight-facts';

export interface CommonSlideProps {
  title: string;
  content: string;
  backgroundImage: string;
  id: string;
  siteId: number;
  timezone: string;
  positionImageAboveWeatherBar: boolean;
  preview?: boolean;
}

export function SlidePreview() {
  const { watch } = useFormContext();

  // Watch for form changes
  const format = watch('format');
  const description = watch('description');
  const preferences = watch('preferences');

  // Common props for all slides
  const commonProps: CommonSlideProps = {
    title: description?.title || '',
    content: description?.content || '',
    backgroundImage: format?.background?.downloadUrl,
    id: 'preview',
    siteId: 1, // This should come from props
    timezone: 'America/Chicago', // This should come from props
    positionImageAboveWeatherBar: format?.positionImageAboveWeatherBar || false,
    preview: true,
  };

  // Render appropriate slide based on style
  const renderSlide = () => {
    switch (format?.style?.name?.toLowerCase()) {
      case 'collage':
        return (
          <CollageSlide
            {...commonProps}
            images={description?.secondaryImages || []}
            numberOfImages={description?.numberOfImages || 1}
          />
        );

      case 'activities & events':
        return (
          <ActivitiesAndEventsSlide
            {...commonProps}
            allDay={description?.allDay || false}
            eventStart={description?.eventStart || null}
            eventEnd={description?.eventEnd || null}
            roomName={description?.room?.name || null}
            location={description?.location || null}
            iconName={description?.icon || null}
          />
        );

      case 'image centric':
        return <ImageCentricSlide {...commonProps} />;

      case 'individual spotlight':
        return (
          <IndividualSpotlightSlide
            {...commonProps}
            content={factsToDescription(description?.facts || [])}
            imageUrl={description?.image?.downloadUrl}
          />
        );

      case 'name in lights':
        return <NameInLightsSlide {...commonProps} />;

      case 'gif centric':
        return (
          <GifCentricSlide
            {...commonProps}
            image={description?.secondaryImages?.[0]?.downloadUrl}
          />
        );

      default:
        return <DefaultSlide {...commonProps} />;
    }
  };

  return (
    <div className="w-full h-full relative overflow-hidden">
      <div className="absolute inset-0 flex items-center justify-center">
        <SlideWrapper
          backgroundImage={commonProps.backgroundImage}
          positionImageAboveWeatherBar={
            commonProps.positionImageAboveWeatherBar
          }
          preview={true}
        >
          {renderSlide()}
        </SlideWrapper>
      </div>
    </div>
  );
}
