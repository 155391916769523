import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createEventSlideApi } from '../api/event-slide.api';
import { createSitesApi } from '@features/sites/api/sites.api';
import { Auth } from '@angular/fire/auth';
import { auth } from '@core/utils/firebase/firebase';

const eventSlideApi = createEventSlideApi(auth as Auth);
const sitesApi = createSitesApi(auth as Auth);

interface UpdateEventSlideSettingsParams {
  siteId: number;
  showEventSlide: boolean;
  displayDuration: number;
}

export function useUpdateEventSlideSettings() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      siteId,
      showEventSlide,
      displayDuration,
    }: UpdateEventSlideSettingsParams) => {
      await sitesApi.updateSite(siteId, { showEventSlide });

      const eventSlide = await eventSlideApi.getEventSlideBySiteId(siteId);
      if (eventSlide) {
        await eventSlideApi.updateEventSlide(eventSlide.id, {
          displayDuration,
        });
      } else {
        await eventSlideApi.createEventSlide({
          siteId,
          displayDuration,
        });
      }
    },
    onSuccess: (_, { siteId }) => {
      queryClient.invalidateQueries({
        queryKey: ['eventSlide', 'settings', siteId],
      });
    },
  });
}
