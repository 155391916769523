import React from 'react';
import { IconButton, CircularProgress, Box, Tooltip } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import clsx from 'clsx';
import { Announcement } from '@core/interfaces/api';
import { isEvent } from '@features/announcements/utils';
import { SlideDisplay } from '../slide-display/slide-display';
import { SlideTypeIcon } from '../slide-type-icon';

const columns = [
  { id: 'type', label: 'Type' },
  { id: 'title', label: 'Title' },
  { id: 'startShowing', label: 'Start Showing' },
  { id: 'stopShowing', label: 'Stop Showing' },
  { id: 'description', label: 'Description' },
];

export interface SlideGridProps {
  onEdit: (announcement: Announcement) => void;
  onDelete: (announcement: Announcement) => void;
  siteId: number;
  data: Announcement[];
  isLoading: boolean;
  error: Error;
}

export const SlideGrid: React.FC<SlideGridProps> = ({
  onEdit,
  onDelete,
  siteId,
  data,
  isLoading,
  error,
}) => {
  const renderActionButtons = (announcement: Announcement) => {
    if (announcement.isGenerated) {
      return (
        <span className="inline-block pt-2.5 text-gray-600 italic">
          System Generated
        </span>
      );
    }

    if (isEvent(announcement)) {
      return (
        <div className="flex items-center justify-start w-full bg-[#d4d4d4] text-[#585858]">
          <IconButton>
            <SlideTypeIcon announcement={announcement} />
          </IconButton>
        </div>
      );
    }

    return (
      <div className="flex items-center bg-[#d4d4d4] w-full justify-between text-[#585858]">
        <IconButton
          onClick={() => onEdit(announcement)}
          className="hover:bg-gray-100"
        >
          <EditIcon />
        </IconButton>
        <IconButton>
          <SlideTypeIcon announcement={announcement} />
        </IconButton>
        <IconButton
          onClick={() => onDelete(announcement)}
          className="hover:bg-gray-100"
        >
          <DeleteIcon />
        </IconButton>
      </div>
    );
  };

  return (
    <Box className="p-4">
      {isLoading ? (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      ) : error ? (
        <div className="text-center">Error loading announcements</div>
      ) : !data?.length ? (
        <div className="text-center">No slides available</div>
      ) : (
        <div className="flex flex-wrap gap-4">
          {data.map((announcement) => (
            <div className={clsx('h-[300px] w-[350px]', 'hover:bg-gray-50')}>
              <div className="relative h-[250px] w-[350px]">
                <SlideDisplay announcement={announcement} />
              </div>
              <Tooltip
                title={
                  isEvent(announcement)
                    ? 'Events can be edited in the Event Calendar'
                    : ''
                }
              >
                <div className="flex justify-end h-[50px]">
                  {renderActionButtons(announcement)}
                </div>
              </Tooltip>
            </div>
          ))}
        </div>
      )}
    </Box>
  );
};
