import { Injectable } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake.min.js';
import * as pdfFonts from '../../../../local-pdfmake/build/vfs_fonts';
import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

import { CalendarFormat, CalendarService } from './calendar.service';
import { SiteService } from './site.service';
import { ImageService } from './image.service';

import { CALENDAR_COLORS } from '../../core/constants/calendar-colors';
import {
  pdfPageSize,
  pdfStyleDefinition,
  pdfText,
} from '../interfaces/pdfMake/pdfDocument';
import { pdfColumnWidths, pdfTableBody } from '../interfaces/pdfMake/pdfTables';
import { pdfImage } from '../interfaces/pdfMake/pdfImage';
import { Announcement } from '@models/announcement';
import { EventType } from '../../../../../core/enums/event-type';
import { Site } from '@core/interfaces/api';
import { PdfDocumentDefinition } from '../interfaces/pdfMake/pdfDocDefinition';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  /**
   * DEFAULTS
   */

  // PDFMake allows setting page size numerically, but uses 'points' not inches. Define default page size options here...
  public pageSizes: Array<{
    name: string;
    value: pdfPageSize;
  }> = [
    { name: 'Letter', value: 'LETTER' },
    { name: 'Tabloid', value: 'TABLOID' },
    {
      name: 'Tabloid(numeric)',
      value: {
        height: this.calculatePointSizeFromInches(11),
        width: this.calculatePointSizeFromInches(17),
      },
    },
    {
      name: 'Calendar',
      value: {
        height: this.calculatePointSizeFromInches(13),
        width: this.calculatePointSizeFromInches(19),
      },
    },
  ];

  /**
   * ... and select which value will be the default. Selecting an option where 'height' and 'width' are numerically defined
   * allows for dynamically choosing how many events will fit on a calendar day.
   */
  private defaultPageSize: pdfPageSize = this.pageSizes[3].value;
  public selectedPageSize: pdfPageSize = this.defaultPageSize;
  // Legacy manual styling based on number of rows in months
  private defaultEventNumRestrictions = {
    5: {
      maxEvents: 9,
      styles: {
        fontSize: 10,
        lineHeight: 1.1,
      },
    },
    6: {
      maxEvents: 7,
      styles: {
        fontSize: 8.7,
        lineHeight: 1,
      },
    },
  };

  // labels lookup depends on this order, do not change
  public months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // these values factors into both styling and calculations
  private defaultTableLineWidth = 1;
  private logoHeightInInches = 1;
  private headingHeightInInches = 1.25;
  private estimatedUndisclosedCellPadding = 2;
  private smallCharacterToPageRatio = 54; // assumes font size 10.7
  private regularCharacterToPageRatio = 78; // assume font size 14
  private defaultLineHeight = 1;
  private numberOfColumnsInLegend = 3;

  /**
   * TOGGLES
   * (Default values that can be changed for Goodman mockups)
   */

  private hasPageTitle = true;
  private templateMode = false;

  /**
   * STYLE DEFINITIONS FOR PDFMAKE
   * NOTE: Fonts are weird: .otf behaves a little different than .ttf with pdfMake.
   * In stacks, .otf behaves as expected (each item is a line positioned below the previous),
   * but .ttf overlaps the lines, so each subsequent line requires padding at the top.
   * (See 'disclaimer' section for example)
   */

  private fonts = {
    // CALENDAR
    FranklinGothic: {
      normal: 'FranklinGothic-Book.otf',
      italics: 'FranklinGothic-Italic.otf',
      bold: 'FranklinGothic-Heavy.otf',
    },
    FranklinGothicMedium: {
      normal: 'FranklinGothicMedium.otf',
    },
    Gotham: {
      normal: 'GothamBook.ttf',
      bold: 'GothamBold.ttf',
      italics: 'Gotham-Black.otf', // italics and bolditalics were unused, no extra properties can be added, so new fonts added here
      bolditalics: 'Gotham-Black.otf', // this property doesn't seem to work
    },
    GothamBlack: {
      normal: 'Gotham-Black.otf',
    },
    GothamLight: {
      normal: 'Gotham-Light.otf',
      bold: 'GothamBook.ttf',
      italics: 'GothamLightItalic.ttf',
    },
    GothamMedium: {
      normal: 'GothamMedium.ttf',
    },
    // EVENT-LIST
    GothicList: {
      normal: 'FranklinGothic-Book.otf',
      italics: 'FranklinGothic-italic.otf',
      bold: 'FranklinGothicMedium.otf',
    },
  };

  private fontStyles = {
    headings: {
      monthAndYear: {
        font: 'GothamLight',
        color: CALENDAR_COLORS.black90,
        fontSize: 80,
        margin: [0, this.calculatePointSizeFromInches(-0.06), 0, 0],
        padding: 0,
        characterSpacing: -4, // kerning
      },
      daysOfWeek: {
        font: 'Gotham',
        bold: true,
        fontSize: 17,
        color: CALENDAR_COLORS.black90,
        characterSpacing: 0.55,
      },
    },
    days: {
      number: {
        font: 'GothamBlack',
        color: CALENDAR_COLORS.black90,
        fontSize: 18,
      },
      holiday: {
        fontSize: 10,
        bold: true,
      },
    },
  };

  private pageMargins = {
    left: this.calculatePointSizeFromInches(0.25),
    top: this.calculatePointSizeFromInches(0.25),
    right: this.calculatePointSizeFromInches(0.25),
    bottom: this.calculatePointSizeFromInches(0.25),
  };

  // Days of the week
  private calendarHeading = {
    height: this.calculatePointSizeFromInches(0.31),
  };

  /**
   * These top-level keys are effectively used as class names when constructing the document.
   * See https://pdfmake.github.io/docs/0.1/document-definition-object/styling/ for list of limited available style properties
   */
  private styles: pdfStyleDefinition = {
    mainHeadingText: {
      ...this.fontStyles.headings.monthAndYear,
      alignment: 'right',
    },
    mainHeading: {
      margin: [0, 0, 0, 0],
    },
    // calendar grid
    mainTable: {
      margin: [0, 0, 0, 0],
    },
    // inner table for holiday text/day number, and event time/title
    nestedTable: {
      margin: [0, 0, 0, 0],
    },
    // days of the week
    tableHeader: {
      ...this.fontStyles.headings.daysOfWeek,
      alignment: 'center',
      color: CALENDAR_COLORS.white,
      margin: [0, 17, 0, 0], // margin-top offsets font size larger than height of the containing element
    },
    // date number
    day: {
      ...this.fontStyles.days.number,
      alignment: 'right',
      margin: [0, 5, 0, 5],
      color: CALENDAR_COLORS.black90,
      lineHeight: 0.7,
    },
    locationHeader: {
      bold: true,
      fontSize: 15,
      font: 'Gotham',
      alignment: 'left',
      margin: [0, 10, 0, 5],
      lineHeight: 1.1,
    },
    legendEntry: {
      bold: false,
      alignment: 'left',
      margin: [0, 3, 0, 0],
      characterSpacing: -0.18,
      font: 'FranklinGothic',
      fontSize: 12,
      lineHeight: 1,
      color: CALENDAR_COLORS.black90,
    },
    roomLegend: {
      margin: [
        this.pageMargins.left,
        this.pageMargins.top - 5,
        this.pageMargins.right,
        0,
      ],
    }, // includes 'locations' heading and columns
    eventListing: {
      margin: [0, 0, 0, 0],
      padding: [0, 0, 0, 0],
      font: 'FranklinGothic',
      color: CALENDAR_COLORS.black90,
      fontSize: 14,
      lineHeight: 1.2,
    },
    holiday: {
      ...this.fontStyles.days.holiday,
      lineHeight: this.defaultLineHeight,
      margin: [0, 10, 0, 0],
    },
    bold: {
      bold: true,
    },
    underline: {
      decoration: 'underline',
    },
    time: {
      alignment: 'right',
    },
    recurringEvent: {
      fontSize: 9,
      lineHeight: this.defaultLineHeight,
    },
    customtext: {
      bold: true,
      fontSize: 8.7,
      lineHeight: 1.2,
    },
    disclaimer: {
      fontSize: 13,
      color: CALENDAR_COLORS.black90,
      lineHeight: 1.1,
      font: 'GothamMedium',
      margin: [0, 0, 0, 0],
    },
    disclaimer2: {
      fontSize: 13,
      color: CALENDAR_COLORS.black90,
      lineHeight: 1.1,
      font: 'GothamMedium',
      margin: [0, 15, 0, 30],
    },
    disclaimer3: {
      fontSize: 13,
      color: CALENDAR_COLORS.black90,
      lineHeight: 1.1,
      font: 'GothamMedium',
      margin: [0, 15, 0, 0],
    },
    supplementalBlock: {
      alignment: 'center',
      margin: [0, 0, 0, 20],
    },
    fontSizeEight: {
      fontSize: 8,
      lineHeight: this.defaultLineHeight,
    },
    smallFont: {
      fontSize: 10.69,
    },
    dayRowOffset: {},
    // These styles are calculated later
    oneLineMargin: {},
    twoLineMargin: {},
    threeLineMargin: {},
    fourLineMargin: {},
    fiveLineMargin: {},
    oneLineMarginSM: {},
    twoLineMarginSM: {},
    threeLineMarginSM: {},
    fourLineMarginSM: {},
    fiveLineMarginSM: {},
  };

  private eventListStyles: pdfStyleDefinition = {
    pageHeader: {
      fontSize: 16,
      bold: true,
    },
    date: {
      fontSize: 14,
      bold: true,
      margin: [0, 5, 0, 10],
      lineHeight: 1.3,
      decoration: 'underline',
    },
    h1: {
      fontSize: 12,
    },
    tags: {
      fontSize: 10,
      margin: [0, 0, 0, 5],
    },
    main: {
      fontSize: 12,
      margin: [0, 0, 0, 15],
      italics: true,
    },
    bold: {
      bold: true,
    },
    underline: {
      decoration: 'underline',
    },
    center: {
      alignment: 'center',
    },
  };

  public menuWagStyles: pdfStyleDefinition = {
    mainTable: {
      margin: [0, 0, 0, 0],
    },
    pageHeader: {
      font: 'Gotham',
      bold: true,
      fontSize: 18,
      alignment: 'left',
      color: CALENDAR_COLORS.black,
      margin: [0, 19, 0, 12],
    },
    daysOfWeek: {
      font: 'Gotham',
      bold: true,
      fontSize: 13,
      characterSpacing: 0.45,
      alignment: 'center',
      color: CALENDAR_COLORS.white,
      margin: [0, 19, 0, 0], // margin-top offsets font size larger than height of the containing element
    },
    mealHeader: {
      font: 'Gotham',
      fontSize: 14,
      bold: true,
      margin: [12, 20, 0, 0],
    },
    menuItem: {
      font: 'FranklinGothic',
      fontSize: 9,
      lineHeight: 1,
      margin: [2, 4, 2, 2],
      alignment: 'center',
    },
  };

  // Logos are a set height and whatever width
  private logoFormat: pdfImage = {
    fit: ['auto', this.calculatePointSizeFromInches(this.logoHeightInInches)],
  };

  /**
   * default column widths (for 7 days of main calendar table) as string percentages,
   * can be calculated as fixed numeric numbers for dynamic content
   **/
  private columnWidths: Array<pdfColumnWidths> = [
    `${100 / 7}%` as `${number}%`,
    `${100 / 7}%` as `${number}%`,
    `${100 / 7}%` as `${number}%`,
    `${100 / 7}%` as `${number}%`,
    `${100 / 7}%` as `${number}%`,
    `${100 / 7}%` as `${number}%`,
    `${100 / 7}%` as `${number}%`,
  ];

  /**
   * TRANSLATIONS
   */

  private calculatePointSizeFromInches(inches): number {
    return inches * 72;
  }

  private calculatePointSizeFromPixels(pixels): number {
    return pixels * 0.96;
  }

  constructor(
    private calendarService: CalendarService,
    private siteService: SiteService,
    public imageService: ImageService,
  ) {}

  /**
   * The calendar doesn't support theming yet, but the client has started asking for different colors
   * for different properties, so it's only a matter of time before this becomes a thing.  Right now it's
   * just a different set of colors fot the Legends on Lake Lorraine property (ids 137, 138, 147, 148, and 149), but
   * I'm expecting that to change.  These styles aren't the only things that are styled in the pdf calendar
   * but it's a good place to start.  You can find "fillColor" property of the "tableHeader" (days of the week)
   * in the calendar.service.ts file.
   *
   * @param siteId (optional)
   * @returns
   */
  private generateTheme(siteId?: number): pdfStyleDefinition {
    // setting highlight color for Legends on lake lorraine.  Every other site, for now, is the default blue color
    const highlightColor =
      siteId &&
      (siteId === 137 ||
        siteId === 138 ||
        siteId === 147 ||
        siteId === 148 ||
        siteId === 149)
        ? '#b69a52'
        : CALENDAR_COLORS.blue;
    return {
      ...this.styles,
      holiday: {
        ...this.styles.holiday,
        color: highlightColor,
      },
      locationHeader: {
        ...this.styles.locationHeader,
        color: highlightColor,
      },
    };
  }
  /**
   * Evaluation for best-guess assumption related to small character cutoff
   * for event titles on the calendar
   */
  public evaluateSmallFontInRow(rowHeightMinusHeader: number, small: number) {
    let smallInRow = rowHeightMinusHeader / small;
    if (smallInRow % 1 < 0.38) {
      smallInRow = Math.floor(rowHeightMinusHeader / small);
    } else {
      smallInRow = Math.ceil(rowHeightMinusHeader / small);
    }

    return smallInRow;
  }

  /**  Number of lines that will fit in day row based on font height.
  Used later to determine when to use the smaller font size */
  calculateNumLinesByFontSizes(
    rowHeight,
    fontOverride?,
  ): { small: number; regular: number } {
    const dayItemLineHeight =
      this.calculatePointSizeFromPixels(this.styles.eventListing.margin[1]) +
      this.calculatePointSizeFromPixels(this.styles.eventListing.margin[3]);
    const rowHeightMinusHeader =
      rowHeight -
      this.styles.day.fontSize * this.styles.day.lineHeight -
      this.styles.day.margin[1] -
      this.styles.day.margin[3];

    const small =
      dayItemLineHeight +
      this.styles.smallFont.fontSize * this.styles.eventListing.lineHeight;
    const regular =
      dayItemLineHeight +
      this.styles.eventListing.fontSize * this.styles.eventListing.lineHeight;
    this.setVerticalCenteringMargins('LineMargin', regular);
    this.setVerticalCenteringMargins('LineMarginSM', small);

    if (fontOverride) {
      return {
        small: Math.round(rowHeightMinusHeader / regular), // small and regular have to be the same for font override
        regular: Math.round(rowHeightMinusHeader / regular),
      };
    }
    return {
      small: this.evaluateSmallFontInRow(rowHeightMinusHeader, small), // max number of event title lines at smaller size
      regular: Math.floor(rowHeightMinusHeader / regular), // max number of event title lines at larger size
    };
  }

  calculateRowHeight(rowsRequired): number {
    return (
      (this.calculateCalendarTableHeight() -
        (rowsRequired + 4) * this.defaultTableLineWidth) /
      rowsRequired
    );
  }

  public calculateRowsInCalRow(rowsRequired): {
    rowHeight: number;
    numLinesPerDay: { small: number; regular: number };
  } {
    const defaultCalRowHeight = this.calculateRowHeight(rowsRequired);
    const numLinesPerDay =
      this.calculateNumLinesByFontSizes(defaultCalRowHeight);

    return {
      rowHeight: defaultCalRowHeight,
      numLinesPerDay: numLinesPerDay,
    };
  }

  setVerticalCenteringMargins(style: 'LineMargin' | 'LineMarginSM', size) {
    // regular
    this.styles[`one${style}`] = {
      margin: [0, size / 2, 0, 0],
    };
    this.styles[`two${style}`] = {
      margin: [0, (size * 2) / 2, 0, 0],
    };
    this.styles[`three${style}`] = {
      margin: [0, (size * 3) / 2, 0, 0],
    };
    this.styles[`four${style}`] = {
      margin: [0, (size * 4) / 2, 0, 0],
    };
    this.styles[`five${style}`] = {
      margin: [0, (size * 5) / 2, 0, 0],
    };
  }

  // Calendar table height (pts) is page height, minus page vertical margins, minus the height of the table header,
  // minus the page header and associated margin (if applicable)
  calculateCalendarTableHeight(): number {
    if (typeof this.selectedPageSize === 'object') {
      // days of the week
      const calendarHeading = this.calendarHeading.height;
      // main table
      let mainCalendarHeight =
        this.selectedPageSize.height -
        this.pageMargins.bottom -
        this.pageMargins.top -
        calendarHeading;
      if (this.hasPageTitle && typeof this.pageSizes[3].value === 'object') {
        // TODO: hack to better support switching between two paper sizes. Investigate better
        // solutions for discrepancy
        const is13x19 =
          this.selectedPageSize.height === this.pageSizes[3].value.height;
        // Subtract page title height (based off font size) and spacing
        mainCalendarHeight =
          mainCalendarHeight -
          this.calculatePointSizeFromInches(this.headingHeightInInches); // TODO: calculations off somewhere, solve for discrepancy
      }

      return mainCalendarHeight;
    }
  }

  /**
   * Calculate a character cutoff for event rows based off a ratio of page width.
   * Inexact calculation based off legacy presentation.
   * Should more likely be ratio of (numeric) font size to page width.
   * Subtract 5 from 'small' and 'regular' to get max event title character count.
   **/
  public getMaxNumCharacters(pageWidth): { small: number; regular: number } {
    const pageWidthMinusMargin =
      pageWidth - this.pageMargins.left - this.pageMargins.right;

    return {
      small: Math.ceil(pageWidthMinusMargin / this.smallCharacterToPageRatio),
      regular: Math.ceil(
        pageWidthMinusMargin / this.regularCharacterToPageRatio,
      ),
    };
  }

  /**
   * Get the max number of rooms that will fit in the legend
   **/
  private getMaxRoomCount(calRowHeight): number {
    const rowHeightMinusPadding =
      calRowHeight -
      this.styles.roomLegend?.margin[1] -
      -this.styles.roomLegend?.margin[3] -
      this.styles.locationHeader.fontSize *
        this.styles.locationHeader.lineHeight -
      this.styles.locationHeader.margin[1] -
      this.styles.locationHeader.margin[3];

    const roomRowHeight =
      this.styles.legendEntry.fontSize * this.styles.legendEntry.lineHeight +
      this.styles.legendEntry.margin[1] +
      this.estimatedUndisclosedCellPadding;

    const numberOfLegendRows = Math.ceil(rowHeightMinusPadding / roomRowHeight);

    return numberOfLegendRows * this.numberOfColumnsInLegend;
  }

  public async getLogo(siteId: number): Promise<{
    downloadUrl: string;
    image: unknown;
  }> {
    let image;
    let imageFilePath;
    let downloadUrl;
    try {
      // Try fetching print-specific logo
      imageFilePath = this.imageService.getSiteLogoPrintImageFilePath(siteId);
      downloadUrl = await this.imageService.getDownloadUrl(imageFilePath);
      image = await this.imageService.getImage(downloadUrl);
    } catch (error) {
      // Failing that, use site logo
      if (!image) {
        imageFilePath = this.imageService.getSiteLogoImageFilePath(siteId);
        downloadUrl = await this.imageService.getDownloadUrl(imageFilePath);
        image = await this.imageService.getImage(downloadUrl);
      }
    }
    return {
      downloadUrl,
      image,
    };
  }

  public async generateCalendarDocDefinition(
    selectedMonth: number,
    selectedYear: number,
    monthDate: Date,
    customization?: {
      customText?;
      fontSize?;
      paperSize?;
      verticallyCentered?;
      subcategoryIds?: number[];
    },
  ): Promise<{ definition: PdfDocumentDefinition; fileTitle: string }> {
    const monthName = this.months[selectedMonth]; // months array is zero based
    const calFormat: CalendarFormat =
      await this.calendarService.evaluateCalendarFormatForMonth(monthDate);

    // font size override (currently only for demo purposes)
    if (customization?.fontSize) {
      this.styles.eventListing.fontSize = Number(customization.fontSize);
      this.styles.smallFont.fontSize = Number(customization.fontSize);
    }
    // page size override (currently only for demo purposes)
    if (customization?.paperSize) {
      this.selectedPageSize =
        this.pageSizes[Number(customization.paperSize)].value;
    } else {
      this.selectedPageSize = this.defaultPageSize;
    }
    /**
     * The columnWidths are calculated on the first download. This columnWidths property is mutated (by PDFMake) into the calculated size.
     * To support percentage based column widths that may change without a page reload, we must manually reset
     * the percentages here.
     */
    this.columnWidths = [
      `${100 / 7}%` as `${number}%`,
      `${100 / 7}%` as `${number}%`,
      `${100 / 7}%` as `${number}%`,
      `${100 / 7}%` as `${number}%`,
      `${100 / 7}%` as `${number}%`,
      `${100 / 7}%` as `${number}%`,
      `${100 / 7}%` as `${number}%`,
    ];

    // TODO: reset font sizes for demo mode

    const rowsRequired = calFormat.rowsRequired;
    const displayLegendAtEndOfMonth = calFormat.legendAtEndOfMonth;
    const displayDisclaimerAtEndOfMonth = calFormat.disclaimerAtEndOfMonth;
    const siteId = this.siteService.currentSiteId;
    const siteState = this.siteService.currentSite.state;
    let maxNumCharacter = {
      small: 24,
      regular: 18,
    }; // fall-back character limits for event name and room abbreviation
    let maxRooms = 18; // fall-back max-rooms
    const fileTitle = `${this.siteService.currentSite.name.replace(
      /[^0-9a-zA-Z]+/g,
      '_',
    )}_Event_Calendar-${monthName}_${selectedYear}`;
    const logo = await this.getLogo(siteId).catch(() => {
      // if there's an error getting the logo, provide an empty logo
      return {
        downloadUrl: undefined,
        image: undefined,
      };
    });
    let docHeaderWithLogo = [];
    if (logo.downloadUrl) {
      docHeaderWithLogo = [
        {
          image: logo.image,
          ...this.logoFormat,
        },
        {
          text: `${monthName.toUpperCase()} ${selectedYear}`,
          style: 'mainHeadingText',
        },
      ];
    }
    const docHeaderWithoutLogo = [
      {
        text: `${monthName.toUpperCase()} ${selectedYear}`,
        style: 'mainHeadingText',
      },
    ];

    let defaultCalRowHeight = rowsRequired === 6 ? 111 : 136;
    const numberOfEventsPerDay =
      this.defaultEventNumRestrictions[rowsRequired].maxEvents;
    let numLinesPerDay;

    // if custom dimensions are provided, dynamically calculate row heights
    if (typeof this.selectedPageSize === 'object') {
      defaultCalRowHeight = this.calculateRowHeight(rowsRequired);

      numLinesPerDay = this.calculateNumLinesByFontSizes(
        defaultCalRowHeight,
        customization?.fontSize,
      );

      // keep the supplementalBlock/disclaimer vertically centered based on row height
      this.styles.supplementalBlock.margin[1] = defaultCalRowHeight / 4;

      maxNumCharacter = this.getMaxNumCharacters(this.selectedPageSize.width);
      maxRooms = this.getMaxRoomCount(defaultCalRowHeight);
    }

    const calRowHeights = [this.calendarHeading.height];
    for (let i = 0; i < rowsRequired; i++) {
      calRowHeights.push(defaultCalRowHeight);
    }

    const eventGridHeight =
      defaultCalRowHeight - this.fontStyles.days.number.fontSize;
    const calendarGrid: Array<pdfTableBody> =
      await this.calendarService.getCalendar({
        siteId,
        timezone: this.siteService.currentSite.timezone,
        monthIndex: selectedMonth,
        year: selectedYear,
        monthDate,
        customText: customization?.customText,
        rows: rowsRequired,
        displayLegendAtEnd: displayLegendAtEndOfMonth,
        displayDisclaimerAtEnd: displayDisclaimerAtEndOfMonth,
        state: siteState,
        maxNumCharacter,
        numberOfEventsPerDay,
        numLinesPerDay,
        templateMode: this.templateMode,
        maxRooms,
        fontOverride: Boolean(customization?.fontSize),
        eventGridHeight,
        verticallyCentered: customization?.verticallyCentered,
        subcategoryIds: customization?.subcategoryIds,
      });
    const docDefinition: PdfDocumentDefinition = {
      pageSize: this.selectedPageSize,
      pageOrientation: 'landscape',
      pageMargins: [
        this.pageMargins.left,
        this.pageMargins.top,
        this.pageMargins.right,
        this.pageMargins.bottom,
      ],
      info: {
        title: fileTitle,
      },
      defaultStyle: {
        font: 'Gotham',
      },
      content: [
        {
          style: 'mainHeading',
          table: {
            headerRows: 1,
            widths: docHeaderWithLogo.length > 0 ? ['*', 'auto'] : ['*'],
            heights: [
              this.calculatePointSizeFromInches(this.headingHeightInInches),
            ],
            body:
              docHeaderWithLogo.length > 0
                ? [docHeaderWithLogo]
                : [docHeaderWithoutLogo],
          },
          layout: {
            ...this.calendarService.tableLayoutPaddingReset,
            paddingLeft: function (i, node) {
              return 0;
            },
            paddingRight: function (i, node) {
              return 0;
            },
            defaultBorder: this.templateMode ? true : false,
          },
        },
        {
          style: 'mainTable',
          table: {
            headerRows: 1,
            alignment: 'right',
            widths: this.columnWidths,
            heights: calRowHeights,
            body: calendarGrid,
          },
          layout: {
            ...this.calendarService.tableLayoutPaddingReset,
            // change for lake lorraine
            hLineColor:
              siteId &&
              (siteId === 137 ||
                siteId === 138 ||
                siteId === 147 ||
                siteId === 148 ||
                siteId === 149)
                ? '#404041'
                : CALENDAR_COLORS.blue,
            vLineColor:
              siteId &&
              (siteId === 137 ||
                siteId === 138 ||
                siteId === 147 ||
                siteId === 148 ||
                siteId === 149)
                ? '#404041'
                : CALENDAR_COLORS.blue,
            hLineWidth: (i, node) => {
              return this.defaultTableLineWidth;
            },
            vLineWidth: (i, node) => {
              return this.defaultTableLineWidth;
            },
          },
        },
      ],
      styles: this.generateTheme(siteId),
      maxPagesNumber: 1,
    };

    // toggle to remove page title for quick-generating variations for Goodman
    if (!this.hasPageTitle) {
      docDefinition.content.shift();
    }

    return {
      definition: docDefinition,
      fileTitle,
    };
  }

  async downloadCalendarPdf(
    selectedMonth: number,
    selectedYear: number,
    monthDate: Date,
    customization?: {
      customText?;
      fontSize?;
      paperSize?;
      verticallyCentered?;
      subcategoryIds?: Array<number>;
    },
  ): Promise<void> {
    const docDefinition = await this.generateCalendarDocDefinition(
      selectedMonth,
      selectedYear,
      monthDate,
      customization,
    );

    // parameters: docDefinition, tableLayouts, fonts, vfs (virtual file system)
    pdfMake
      .createPdf(docDefinition.definition, null, this.fonts, pdfMake.vfs)
      .download(docDefinition.fileTitle);
  }

  async openActivityCalendarPdf(
    selectedMonth: number,
    selectedYear: number,
    monthDate: Date,
  ) {
    const docDefinition = await this.generateCalendarDocDefinition(
      selectedMonth,
      selectedYear,
      monthDate,
    );
    pdfMake
      .createPdf(docDefinition.definition, null, this.fonts, pdfMake.vfs)
      .open();
  }

  async downloadPdf(
    downloadFileName: string,
    title: string,
    content: any,
    pageSize: 'LETTER' | 'TABLOID',
    pageOrientation: 'landscape' | 'portrait',
    styles: pdfStyleDefinition,
  ) {
    const docDefinition = {
      content,
      pageOrientation,
      pageSize,
      styles,
      pageMargins: [
        this.pageMargins.left,
        this.pageMargins.top,
        this.pageMargins.right,
        this.pageMargins.bottom,
      ],
      info: {
        title,
      },
      defaultStyle: {
        font: 'Gotham',
      },
      maxPagesNumber: 1,
    };
    pdfMake
      .createPdf(docDefinition, null, this.fonts, pdfMake.vfs)
      .download(downloadFileName);
  }

  /**
   * Document definition for the event list PDF
   * @param data
   * @param startDate
   * @param endDate
   * @param site
   */
  public async generateEventListDocumentDefinition(
    data: Array<any>,
    startDate: Date,
    endDate: Date,
    site: Site,
  ): Promise<{ definition: PdfDocumentDefinition; fileTitle: string }> {
    const start = startDate;
    const end = endDate;
    const dateRange = `${start.getMonth()}_${start.getDate()}_${start.getFullYear()}-${end.getMonth()}_${end.getDate()}_${end.getFullYear()}`;
    const fileTitle = `${this.siteService.currentSite.name.replace(
      /[^0-9a-zA-Z]+/g,
      '_',
    )}_Event_List-${dateRange.replace(/\s/g, '')}.pdf`;

    // tempDate used to identify when we need to insert a text object for a new date
    let tempDate;

    const eventListWithDates: Array<pdfText> = data.reduce(
      (sortedEvents, event: Announcement) => {
        // Convert UTC time to site's timezone for display
        const eventStartDate = parseISO(event.eventStart.toString());
        const eventStartInSiteTimezone = utcToZonedTime(
          eventStartDate,
          this.siteService.currentSite.timezone,
        );

        const date = format(eventStartInSiteTimezone, 'EEEE, MMMM d', {
          timeZone: this.siteService.currentSite.timezone,
        });
        const group = [];

        // Add date header
        if (tempDate !== date) {
          group.push({
            text: date,
            style: 'date',
          });
          tempDate = date;
        }
        const titleStyles = ['h1'];
        const eventStyle = EventType[event.eventType];

        switch (eventStyle) {
          case EventType.Generic:
            break;

          case EventType.SpecialEvent:
            titleStyles.push('bold');
            break;
        }
        // all day events display in bold with no time listed
        if (event.allDay && !titleStyles.includes('bold')) {
          titleStyles.push('bold');
        }
        // Add formatted event
        group.push(
          {
            text: [
              {
                text: `${
                  event.allDay
                    ? ''
                    : `${format(eventStartInSiteTimezone, 'h:mm a', {
                        timeZone: this.siteService.currentSite.timezone,
                      })} - `
                }`,
                style: ['time', 'bold'],
              },
              {
                text: event.title,
                style: titleStyles,
              },
            ],
          },
          // text array allows inline formatting, text manually separated by ' | '
          {
            text: [
              {
                text: 'Room: ',
                style: 'bold',
              },
              {
                text: event.room.name
                  ? `${event.room.name}${
                      event.location ? `, ${event.location}` : ''
                    }`
                  : null,
                style: '',
              },
            ],
            style: 'tags',
          },
          {
            text: [
              {
                text: event.content ? `"${event.content}"` : '',
              },
            ],
            style: 'main',
          },
        );
        return [...sortedEvents, group];
      },
      [],
    );

    const docDefinition: PdfDocumentDefinition = {
      pageOrientation: 'portrait',
      info: {
        title: fileTitle,
      },
      defaultStyle: {
        font: 'GothicList',
      },
      content: [
        {
          text: `${site.name} Events`,
          style: ['pageHeader', 'center'],
        },
        {
          text: dateRange,
          style: 'center',
        },
        ...eventListWithDates,
      ],
      styles: this.eventListStyles,
    };
    return {
      definition: docDefinition,
      fileTitle,
    };
  }

  async downloadPdfEventList(
    data: Array<any>,
    startDate: Date,
    endDate: Date,
    site: Site,
  ): Promise<void> {
    const docDefinition = await this.generateEventListDocumentDefinition(
      data,
      startDate,
      endDate,
      site,
    );
    pdfMake
      .createPdf(docDefinition.definition, null, this.fonts, pdfMake.vfs)
      .download(docDefinition.fileTitle);
  }
}
