import React from 'react';
import { Announcement } from '@core/interfaces/api';
import {
  DefaultSlide,
  CollageSlide,
  ActivitiesAndEventsSlide,
  IndividualSpotlightSlide,
  NameInLightsSlide,
  GifCentricSlide,
} from '@features/digital-signage/add-slide/components/slides';
import {
  isIndividualSpotlight,
  isEvent,
  isNameInLights,
  isTitleSlide,
  isCollage,
  isGif,
} from '@features/announcements/utils';

interface SlideDisplayProps {
  announcement: Announcement;
  showClock?: boolean;
  preview?: boolean;
}

export const SlideDisplay: React.FC<SlideDisplayProps> = ({
  announcement,
  showClock = false,
  preview = true,
}) => {
  const commonProps = {
    title: announcement.title,
    content: announcement.content,
    backgroundImage: announcement.background?.downloadUrl,
    id: announcement.id.toString(),
    siteId: announcement.siteId,
    timezone: announcement.site?.timezone,
    positionImageAboveWeatherBar: announcement.positionImageAboveWeatherBar,
    preview,
  };

  if (isTitleSlide(announcement)) {
    return <DefaultSlide {...commonProps} />;
  }

  if (isEvent(announcement)) {
    const { tags } = announcement;
    const icon = tags && tags.length > 0 ? tags[0]?.eventTag?.icon : null;
    return (
      <ActivitiesAndEventsSlide
        {...commonProps}
        allDay={announcement.allDay}
        eventStart={announcement.eventStart}
        eventEnd={announcement.eventEnd}
        roomName={announcement.room?.name}
        location={announcement.location}
        iconName={icon}
      />
    );
  }

  if (isCollage(announcement)) {
    return (
      <CollageSlide
        {...commonProps}
        images={announcement.secondaryImages}
        numberOfImages={announcement.secondaryImages.length}
      />
    );
  }

  if (isIndividualSpotlight(announcement)) {
    return (
      <IndividualSpotlightSlide
        {...commonProps}
        imageUrl={announcement.image?.downloadUrl}
      />
    );
  }

  if (isNameInLights(announcement)) {
    return <NameInLightsSlide {...commonProps} />;
  }

  if (isGif(announcement)) {
    return (
      <GifCentricSlide
        {...commonProps}
        image={announcement.secondaryImages?.[0]?.downloadUrl}
      />
    );
  }
  // Default case for regular announcements
  return <DefaultSlide {...commonProps} />;
};
