import clsx from 'clsx';
import React from 'react';
import { CommonSlideProps } from '../slide-preview';

export function DefaultSlide({
  title,
  content,
  backgroundImage,
  preview = false,
}: CommonSlideProps) {
  return (
    <div className="absolute inset-0">
      <div
        className={clsx('absolute inset-0 bg-no-repeat bg-cover bg-top')}
        style={{
          backgroundImage: backgroundImage
            ? `url(${backgroundImage})`
            : undefined,
        }}
      />
      <div
        className={clsx(
          'bg-black/60 absolute top-0 left-0 flex items-center',
          preview ? 'w-1/2 h-full p-[3%]' : 'w-1/2 h-full p-[5%]',
        )}
      >
        <div className="w-full">
          <h1
            className={clsx(
              'font-black font-sans text-white text-left mb-[1vmin] leading-tight uppercase whitespace-pre-line',
              preview ? 'text-[2vmin]' : 'text-[5.6vmin]',
            )}
          >
            {title}
          </h1>
          <h2
            className={clsx(
              'text-white text-left font-serif leading-tight break-words',
              preview ? 'text-[1.5vmin]' : 'text-[3.9vmin]',
            )}
          >
            {content}
          </h2>
        </div>
      </div>
    </div>
  );
}
