import { Site } from '@core/interfaces/api';

export const isSiteActivitiesEnabled = (site: Site): boolean => {
  return site.siteType.description.includes('Senior Living');
};

export const isSiteNotificationsEnabled = (site: Site): boolean => {
  return site.activeForMobile;
};

export const isSiteBackgroundImageUploadEnabled = (site: Site): boolean => {
  return (
    site.siteType.description.includes('Corporate') ||
    site.siteType.description.includes('Senior Living/Residential')
  );
};

export const isAnnouncementCategoriesEnabled = (site: Site): boolean => {
  return site.siteType.description.includes('Senior Living');
};

export const getSiteLogoUrl = (
  environmentUrl: string,
  siteId: number,
): string => {
  return `${environmentUrl}/o/site-logos%2F${siteId}.png?alt=media`;
};

export const getSitePrintLogoUrl = (
  environmentUrl: string,
  siteId: number,
): string => {
  return `${environmentUrl}/o/site-logos%2F${siteId}_print.png?alt=media`;
};

export const getSiteImageUrl = (
  environmentUrl: string,
  siteId: number,
): string => {
  return `${environmentUrl}/o/site-images%2F${siteId}.png?alt=media`;
};
