import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { SiteService } from '@core/services/site.service';
import { AuthService } from '@core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Site } from '@core/interfaces/api';
import { NewsletterService } from '@core/services/newsletter.service';
import {
  isSiteActivitiesEnabled,
  isSiteNotificationsEnabled,
} from '@features/sites/utils/site.utils';

@Component({
  selector: 'app-nav-drawer',
  templateUrl: './nav-drawer.component.html',
  styleUrls: ['./nav-drawer.component.scss'],
})
export class NavDrawerComponent implements OnInit, OnDestroy {
  public orgId: number;
  public site: Site;
  public siteId: number;
  public showActivitiesCalendar = false;
  private subscriptions = [];
  @Output() closeDashboard: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private route: ActivatedRoute,
    public siteService: SiteService,
    public authService: AuthService,
    public newsletterService: NewsletterService,
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.siteService.siteId.subscribe((sideId) => (this.siteId = sideId)),
    );
    this.subscriptions.push(
      this.siteService.site.subscribe((site) => (this.site = site)),
    );
    this.subscriptions.push(
      this.authService.user.subscribe((user) => (this.orgId = user?.orgId)),
    );
  }

  /**
   * Checks if activities are enabled for the current site
   * @returns boolean indicating if activities are enabled
   */
  public activitiesEnabled(): boolean {
    return this.site ? isSiteActivitiesEnabled(this.site) : false;
  }

  /**
   * Checks if notifications are enabled for the current site
   * @returns boolean indicating if notifications are enabled
   */
  public notificationsEnabled(): boolean {
    return this.site ? isSiteNotificationsEnabled(this.site) : false;
  }

  /**
   * Checks if mobile is active for the current site
   * @returns boolean indicating if mobile is active
   */
  public mobileActive(): boolean {
    return this.site ? this.site.activeForMobile : false;
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => {
        subscription.unsubscribe();
      });
    }
  }
}
