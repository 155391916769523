import React from 'react';

export const parseContent = (content?: string) => {
  if (!content) return [];
  return content
    .split('-')
    .filter((item) => item.trim())
    .map((item) => item.trim());
};

export const formatContentForDisplay = (content?: string) => {
  return parseContent(content).join(', ');
};

export const renderBullets = (content?: string) => {
  return parseContent(content).map((bullet, index) => (
    <li key={index}>{bullet}</li>
  ));
};
