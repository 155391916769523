import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { createRoot } from 'react-dom/client';
import * as React from 'react';
import { SiteService } from '@core/services/site.service';
import { Site } from '@core/interfaces/api';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ReactProvider } from '@shared/react-components/react-provider';
import { DigitalSignagePage } from '../digital-signage.page';

@Component({
  selector: 'app-slide-table-react',
  template: '<div class="h-full w-full" #reactRoot></div>',
})
export class SlideTableReactWrapperComponent
  implements OnInit, OnDestroy, OnChanges
{
  @ViewChild('reactRoot', { static: true }) containerRef!: ElementRef;

  private root: any;
  private currentSite: Site | null = null;
  private siteSubscription: Subscription;

  constructor(
    private siteService: SiteService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.root = createRoot(this.containerRef.nativeElement);

    this.siteSubscription = this.siteService.site.subscribe((site) => {
      this.currentSite = site;
      this.render();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.root) return;
    this.render();
  }

  ngOnDestroy() {
    if (this.root) {
      this.root.unmount();
    }
    if (this.siteSubscription) {
      this.siteSubscription.unsubscribe();
    }
  }

  private handleEdit = (announcement: any) => {
    this.router.navigate(['edit', announcement.id], {
      relativeTo: this.route,
    });
  };

  private handleAddSlide = () => {
    this.router.navigate([`add`], { relativeTo: this.route });
  };

  private handleDelete = (announcement: any) => {
    // Implement delete logic
  };

  private render() {
    this.root.render(
      React.createElement(
        ReactProvider,
        null,
        React.createElement(DigitalSignagePage, {
          onEdit: this.handleEdit,
          onDelete: this.handleDelete,
          siteId: this.currentSite?.id,
          onAddSlide: this.handleAddSlide,
        }),
      ),
    );
  }
}
