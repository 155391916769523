import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Switch,
  TextField,
  IconButton,
  CircularProgress,
  Typography,
  Box,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';
import { getAuth } from '@angular/fire/auth';
import { MenuSlideApi } from './api/menu-slide.api';
import { SitesApi } from '@features/sites/api/sites.api';

interface MenuSlideModalProps {
  open: boolean;
  onClose: () => void;
  siteId: number;
}

interface MenuSlideForm {
  showMenu: boolean;
  displayDuration: number;
}

export const MenuSlideModal: React.FC<MenuSlideModalProps> = ({
  open,
  onClose,
  siteId,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const auth = getAuth();
  const menuSlideApi = new MenuSlideApi(auth);
  const sitesApi = new SitesApi(auth);

  const { control, setValue, handleSubmit } = useForm<MenuSlideForm>({
    defaultValues: {
      showMenu: false,
      displayDuration: 15,
    },
  });

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        setLoading(true);
        setError(null);

        const [site, menuSlide] = await Promise.all([
          sitesApi.getSite(siteId),
          menuSlideApi.getMenuSlideBySiteId(siteId),
        ]);

        setValue('showMenu', site.showMenu);
        if (menuSlide) {
          setValue('displayDuration', menuSlide.displayDuration);
        }
      } catch (err) {
        setError('Failed to load menu slide settings');
        console.error('Error loading menu slide:', err);
      } finally {
        setLoading(false);
      }
    };

    if (open && siteId) {
      loadInitialData();
    }
  }, [open, siteId, setValue]);

  const onSubmit = async (data: MenuSlideForm) => {
    try {
      setLoading(true);
      setError(null);

      await sitesApi.updateSite(siteId, {
        showMenu: data.showMenu,
      });

      const menuSlide = await menuSlideApi.getMenuSlideBySiteId(siteId);

      if (menuSlide) {
        await menuSlideApi.updateMenuSlide(menuSlide.id, {
          displayDuration: Number(data.displayDuration),
        });
      } else {
        await menuSlideApi.createMenuSlide({
          siteId,
          displayDuration: data.displayDuration,
        });
      }

      onClose();
    } catch (err) {
      setError('Failed to save menu slide settings');
      console.error('Error saving menu slide:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{ '& .MuiDialog-paper': { borderRadius: 2 } }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5">Manage Menu Slide</Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '50%' }}>
            <Controller
              name="showMenu"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Switch
                      {...field}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                    <Typography>Display Menu Slide</Typography>
                  </Box>
                </FormControl>
              )}
            />
          </Box>

          <Box sx={{ width: '50%' }}>
            <Controller
              name="displayDuration"
              control={control}
              rules={{ required: true, min: 1 }}
              render={({ field, fieldState }) => (
                <FormControl fullWidth>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 2,
                    }}
                  >
                    <Typography>Display Duration</Typography>
                    <Box
                      sx={{
                        color: 'text.disabled',
                        marginLeft: '10px',
                        display: 'inline-block',
                        cursor: 'help',
                      }}
                      title="How many seconds should the slide show each time it displays?"
                    >
                      <Typography variant="body2">?</Typography>
                    </Box>
                  </Box>
                  <TextField
                    {...field}
                    type="number"
                    size="small"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error
                        ? 'Display duration is required and must be greater than 0'
                        : ''
                    }
                  />
                </FormControl>
              )}
            />
          </Box>
        </Box>

        {error && (
          <Typography color="error" sx={{ marginTop: 2 }}>
            {error}
          </Typography>
        )}
      </DialogContent>

      <DialogActions sx={{ padding: 2 }}>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          disabled={loading}
          sx={{ bgcolor: 'black', '&:hover': { bgcolor: 'grey.800' } }}
        >
          {loading ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
