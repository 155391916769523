import { createAnnouncementsApi } from '../api/announcements.api';
import { auth } from '@core/utils/firebase/firebase';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Auth } from '@angular/fire/auth';
import { CreateAnnouncementPayload } from '@core/interfaces/api';

const announcementsApi = createAnnouncementsApi(auth as Auth);

export function useAnnouncementsMutation({ siteId }: { siteId: number }) {
  const queryClient = useQueryClient();

  const { mutateAsync: updateAnnouncementOrder, isPending: isUpdatingOrder } =
    useMutation({
      mutationFn: ({ newOrder }: { newOrder: number[] }) =>
        announcementsApi.updateAnnouncementOrder(siteId, newOrder),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['announcements', siteId] });
      },
    });

  const { mutateAsync: createAnnouncement, isPending: isCreating } =
    useMutation({
      mutationFn: (payload: CreateAnnouncementPayload) =>
        announcementsApi.createAnnouncement(payload),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['announcements'] });

        queryClient.invalidateQueries({
          queryKey: ['announcements'],
          predicate: (query) => {
            const queryKey = query.queryKey;
            return (
              Array.isArray(queryKey) &&
              queryKey[0] === 'announcements' &&
              queryKey[1]?.siteId === siteId &&
              queryKey[1]?.status === 'active'
            );
          },
        });
      },
    });

  const { mutateAsync: updateAnnouncement, isPending: isUpdating } =
    useMutation({
      mutationFn: ({
        id,
        ...payload
      }: CreateAnnouncementPayload & { id: number }) =>
        announcementsApi.updateAnnouncement(id, payload),
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries({ queryKey: ['announcements'] });
        queryClient.invalidateQueries({ queryKey: ['announcement', id] });
      },
    });

  const { mutateAsync: deleteAnnouncement, isPending: isDeleting } =
    useMutation({
      mutationFn: (id: number) => announcementsApi.deleteAnnouncement(id),
      onSuccess: (_, id) => {
        queryClient.invalidateQueries({ queryKey: ['announcements'] });
        queryClient.invalidateQueries({ queryKey: ['announcement', id] });
      },
    });

  return {
    updateAnnouncementOrder,
    isUpdatingOrder,
    createAnnouncement,
    isCreating,
    updateAnnouncement,
    isUpdating,
    deleteAnnouncement,
    isDeleting,
  };
}
